import classes from './Pagination.module.scss'
import Button from '../Button/Button'
import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'

const Pagination = ({ pages, page, setPage }) => {
  const previousPage = useCallback(() => {
    setPage(Math.max(1, page - 1))
  }, [page, setPage])

  const nextPage = useCallback(() => {
    setPage(Math.min(pages, page + 1))
  }, [page, pages, setPage])

  const previousPageButtonDisabled = useMemo(() => {
    return page === 1
  }, [page])

  const nextPageButtonDisabled = useMemo(() => {
    return page === pages
  }, [page, pages])

  return <div className={classes.pagination}>
    <Button theme='primary' disabled={previousPageButtonDisabled} onClick={previousPage}>&lt;</Button>
    <span>{page} / {pages}</span>
    <Button theme='primary' disabled={nextPageButtonDisabled} onClick={nextPage}>&gt;</Button>
  </div>
}

Pagination.propTypes = {
  pages: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired
}

export default Pagination
