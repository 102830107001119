const setQueryParams = (paramsOriginal = {}) => {
  const params = Object.assign({}, paramsOriginal)
  for (const key in params) {
    const value = params[key]
    if (!value || (Array.isArray(value) && !value.length)) {
      delete params[key]
    } else if (typeof value === 'object') {
      params[key] = JSON.stringify(value)
    }
  }
  const query = new URLSearchParams(params)
  window.history.replaceState({}, '', `${window.location.pathname}?${query}`)
}

export default setQueryParams
