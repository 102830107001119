import React from 'react'
import classes from './SelectList.module.scss'
import PropTypes from 'prop-types'
import CheckBox from '../CheckBox/CheckBox'
import spoofedEvent from '../../utils/spoofedEvent'
import i18next from 'i18next'

const SelectList = (props) => {
  const {
    value,
    options: optionsUnsorted,
    onChange
  } = props

  const headers = Array.from(optionsUnsorted.reduce((headers, { value }) => {
    for (const key in value) {
      headers.add(key)
    }
    return headers
  }, new Set()))

  const compareOptions = (headerIndex) => (a, b) => {
    const header = headers[headerIndex]
    if (!header) {
      return 0
    }
    const aHeader = a.value[header]
    const bHeader = b.value[header]
    if (aHeader > bHeader) {
      return 1
    }
    if (aHeader < bHeader) {
      return -1
    }
    return compareOptions(headerIndex + 1)(a, b)
  }

  const options = optionsUnsorted.sort(compareOptions(0))

  const allTeamsCheckBoxValue = value.length
    ? value.length < options.length
      ? 'partial'
      : true
    : false

  const toggle = (id) => {
    if (value.some((value) => value === id)) {
      handleChange(value.filter((value) => value !== id))
    } else {
      handleChange([...value, id])
    }
  }

  const toggleAll = () => {
    const newValue = allTeamsCheckBoxValue !== true
      ? options.map(({ id }) => id)
      : []
    handleChange(newValue)
  }

  const handleChange = (value) => {
    const target = { value }
    const event = spoofedEvent(target)
    onChange(event)
  }

  return (
    <div className={classes.root}>
      <table>
        <thead>
        <tr>
          <th>
            <CheckBox
              checked={allTeamsCheckBoxValue}
              onChange={toggleAll}
            />
          </th>
          {
            headers.map((header, index) => {
              return (
                <th key={`SelectList__headers__${index}`}>
                  {header}
                </th>
              )
            })
          }
        </tr>
        </thead>
        <tbody>
        {
          Boolean(!options.length) && (
            <div>
              {i18next.t('common:no_results_found')}
            </div>
          )
        }
        {
          options.map(({ id, value: optionValue }, index) => {
            const rowKey = `SelectList__options__${id}__${index}`
            const isChecked = value.some((checkedValue) => id === checkedValue)
            return (
              <tr key={rowKey}>
                <td>
                  <CheckBox
                    checked={isChecked}
                    onChange={() => toggle(id)}
                  />
                </td>
                {
                  headers.map((header, index) => {
                    const columnKey = `${rowKey}__column__${index}`
                    const cellValue = optionValue[header] || '-'
                    return (
                      <td key={columnKey} onClick={() => toggle(id)}>
                        {cellValue}
                      </td>
                    )
                  })
                }
              </tr>
            )
          })
        }
        </tbody>
      </table>
    </div>
  )
}

SelectList.propTypes = {
  value: PropTypes.arrayOf(
    PropTypes.string.isRequired
  ).isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      value: PropTypes.object.isRequired
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired
}

export default SelectList
