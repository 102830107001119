import React from 'react'

const svg = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="18.364" viewBox="0 0 16 18.364" {...props}>
      <path className="a"
            d="M3.262,18.364v-.6c0-1.63,1.787-3.022,3.961-3.022h.011l-.009-4.226A4.334,4.334,0,0,1,5.049,9.4,6.1,6.1,0,0,1,4.065,8.19,6.672,6.672,0,0,1,.688,5.672a4.424,4.424,0,0,1-.514-3.6L.36,1.534H2.449C2.431.607,2.481,0,2.481,0H13.542s.051.607.033,1.534h2.192l.149.587a4.8,4.8,0,0,1-.669,3.469,6.778,6.778,0,0,1-3.228,2.53A6.148,6.148,0,0,1,11,9.4a4.34,4.34,0,0,1-2.193,1.114l.009,4.224c2.21.02,3.933,1.4,3.933,3.022v.6ZM6.275,7.24l1.752-.89,1.742.889.045-.011-.3-1.912,1.436-1.407L8.935,3.625,8.027,1.877l-.9,1.748-2.077.313L6.538,5.315,6.231,7.229Zm-4.069-2.3a3.789,3.789,0,0,0,.847.915,15.489,15.489,0,0,1-.514-2.776h-.81A3.047,3.047,0,0,0,2.206,4.936ZM13,5.783a4.11,4.11,0,0,0,.894-1,3.423,3.423,0,0,0,.529-1.72h-.933A15.7,15.7,0,0,1,13,5.783Z"
            transform="translate(0)"/>
    </svg>
  )
}

export default svg
