import React from 'react'

const svg = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="11.336" height="6.385" viewBox="0 0 11.336 6.385" {...props} transform='rotate(180)'>
      <g transform="translate(0 0)">
        <g transform="translate(11.336 6.385) rotate(180)">
          <path d="M38.858,28.366V25.383l-6.214,5.509-.169.162h0l0,0,6.385,5.662V33.772l-3.021-2.721Z"
                transform="translate(36.718 -32.473) rotate(90)"/>
        </g>
      </g>
    </svg>
  )
}

export default svg
