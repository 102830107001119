import React from 'react'
import classes from './PopupButtons.module.scss'
import PropTypes from 'prop-types'

const PopupButtons = (props) => {
  const {
    padded = true,
    align = 'right',
    children
  } = props

  const rootClassNames = [classes.root, classes[`align-${align}`]]
  if (padded) {
    rootClassNames.push(classes.padded)
  }

  return (
    <div className={rootClassNames.join(' ')}>
      {children}
    </div>
  )
}

PopupButtons.propTypes = {
  padded: PropTypes.bool,
  align: PropTypes.oneOf(['left', 'center', 'right'])
}

export default PopupButtons
