import React from 'react'
import classes from './Table.module.scss'
import PropTypes from 'prop-types'
import SortAscending from '../svg/SortAscending'
import SortDescending from '../svg/SortDescending'
import spoofedEvent from '../../utils/spoofedEvent'

const Table = (props) => {
  const {
    children
  } = props

  return (
    <div className={classes.root}>
      <table>
        {children}
      </table>
    </div>
  )
}

export const SortableTH = (props) => {
  const {
    name,
    sort,
    className,
    children,
    onChange,
    ...restProps
  } = props

  const classNames = [classes.sortable, className]

  const sorted = sort.field === name
  const SortedIcon = sort.direction === 'asc'
    ? SortAscending
    : SortDescending

  const handleClick = () => {
    const direction = !sorted || sort.direction === 'desc'
      ? 'asc'
      : 'desc'
    const target = {
      value: {
        field: name,
        direction
      }
    }
    const event = spoofedEvent(target)
    onChange(event)
  }

  return (
    <th
      valign="middle"
      className={classNames.join(' ')}
      onClick={handleClick}
      {...restProps}
    >
      {children}
      {
        Boolean(sorted) && (
          <div className={classes.iconContainer}>
            <SortedIcon/>
          </div>
        )
      }
    </th>
  )
}

SortableTH.propTypes = {
  name: PropTypes.string.isRequired,
  sort: PropTypes.shape({
    field: PropTypes.string.isRequired,
    direction: PropTypes.oneOf(['asc', 'desc']).isRequired
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string
}

export default Table
