import Cup from '../components/svg/Cup'
import Heart from '../components/svg/Heart'
import Shield from '../components/svg/Shield'
import Soccer from '../components/svg/Soccer'
import Team from '../components/svg/Team'

const activityTypeIcons = {
  younited: Shield,
  training: Soccer,
  teambuilding: Team,
  socialActivity: Heart,
  friendlyGame: Cup
}

export default activityTypeIcons
