import React from 'react'
import { createPortal } from 'react-dom'
import classes from './Page.module.scss'
import PropTypes from 'prop-types'

const Page = (props) => {
  const {
    className = '',
    header,
    children
  } = props

  const rootClassNames = [classes.root, className]

  const headerPortal = Boolean(header) && createPortal(
    <div className={classes.header}>
      {header}
    </div>,
    document.querySelector('#headerPortalContainer')
  )

  return (
    <div className={rootClassNames.join(' ')}>
      {
        headerPortal
      }
      <div className={classes.body}>
        {children}
      </div>
    </div>
  )
}

Page.propTypes = {
  className: PropTypes.string,
  header: PropTypes.node
}

export default Page
