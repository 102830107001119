import React from 'react'
import classes from './Label.module.scss'
import PropTypes from 'prop-types'

const Label = (props) => {
  const {
    value,
    children,
    className = '',
    ...restProps
  } = props

  const rootClassNames = [classes.root, className]

  return (
    <label className={rootClassNames.join(' ')} {...restProps}>
      <div className={classes.label}>{value}</div>
      {children}
    </label>
  )
}

Label.propTypes = {
  value: PropTypes.string.isRequired
}

export default Label
