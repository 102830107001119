import React, { useCallback, useEffect, useState } from 'react'
import classes from './ClubPage.module.scss'
import i18next from '../../translations'
import api from '../../api'
import Button from '../../components/Button/Button'
import Surface from '../../components/Surface/Surface'
import { Helmet } from 'react-helmet'
import Popup from '../../components/Popup/Popup'
import CreateTeamForm from '../../components/CreateTeamForm/CreateTeamForm'
import NavList from '../../components/NavList/NavList'
import CreateClubAdminForm from '../../components/CreateClubAdminForm/CreateClubAdminForm'
import openFilePicker from '../../utils/openFilePicker'
import ColorPicker from '../../components/ColorPicker/ColorPicker'
import Label from '../../components/Label/Label'
import BackButton from '../../components/BackButton/BackButton'
import Page from '../Page/Page'
import HoverActions from '../../components/HoverActions/HoverActions'
import Trash from '../../components/svg/Trash'
import IconButton from '../../components/IconButton/IconButton'
import DeleteClubAdminForm from '../../components/DeleteClubAdminForm/DeleteClubAdminForm'
import RenameClubForm from '../../components/RenameClubForm/RenameClubForm'
import withError from '../../HOC/withError'
import UserInfoSidePanel from '../../components/UserInfoSidePanel/UserInfoSidePanel'
import SidePanel from '../../components/SidePanel/SidePanel'
import apiURL from '../../api/apiURL'
import useMe from '../../customHooks/useMe'

const ClubPage = (props) => {
  const {
    match,
    setError
  } = props

  const { me, isAdmin, isClubAdmin, isCoach } = useMe()

  const clubId = isAdmin
    ? match.params.clubId
    : me.club._id

  const [club, setClub] = useState(null)
  const [clubAdmins, setClubAdmins] = useState([])
  const [showCreateTeamModal, setShowCreateTeamModal] = useState(false)
  const [showCreateClubAdminModal, setShowCreateClubAdminModal] = useState(false)
  const [showDeleteClubAdminModal, setShowDeleteClubAdminModal] = useState(false)
  const [showRenameClubModal, setShowRenameClubModal] = useState(false)
  const [sidePanelProps, setSidePanelProps] = useState(null)

  const getClub = useCallback(() => {
    api.club.getClub(clubId)
      .then((club) => {
        setShowCreateTeamModal(false)
        setShowCreateClubAdminModal(false)
        setShowDeleteClubAdminModal(false)
        setShowRenameClubModal(false)
        setClub(club)
      })
      .then(() => {
        if (!isCoach) {
          const filter = {
            club: clubId,
            role: 'clubadmin'
          }
          api.user.getUsers(filter)
            .then(setClubAdmins)
        }
      })
  }, [clubId, isCoach])

  useEffect(() => {
    getClub()
  }, [getClub])

  const clearLogo = () => {
    const club = {
      logo: null
    }
    api.club.updateClub(clubId, club)
      .then(getClub)
  }

  const selectLogo = () => {
    const options = {
      attributes: {
        accept: 'image/*'
      }
    }
    openFilePicker(options)
      .then((logo) => {
        api.club.updateClubLogo(clubId, logo)
          .then(getClub)
          .catch(err => {
            console.log(err)
            setError(i18next.t('errors:file_too_big'))
          })
      })
  }

  const selectColor = (type) => ({ target }) => {
    const colorPath = `colors.${type}`
    const club = {
      [colorPath]: target.value.hex
    }
    api.club.updateClub(clubId, club)
      .then(getClub)
  }

  if (!club) {
    return null
  }

  const teamsNavList = club.teams.map((team) => ({
    content: team.name,
    to: isAdmin
      ? `/clubs/${clubId}/${team._id}`
      : isClubAdmin
        ? `/ploeg/${team._id}`
        : `/ploeg/${team._id}`
  }))

  const clubAdminsNavList = clubAdmins.map((clubAdmin) => {
    const sidePanelProps = {
      onClose: () => setSidePanelProps(null),
      component: UserInfoSidePanel,
      userId: clubAdmin._id
    }
    return {
      content: (
        <HoverActions
          content={
            <div className={classes.clubAdminHoverActionsContainer}>
              {clubAdmin.name}
            </div>
          }
          actions={
            clubAdmin._id === me._id
              ? []
              : [
                <IconButton
                  icon={Trash}
                  onClick={() => setShowDeleteClubAdminModal({ userId: clubAdmin._id, userName: clubAdmin.name })}
                />
                ]
          }
        />
      ),
      to: () => setSidePanelProps(sidePanelProps)
    }
  })

  const {
    primaryColor,
    contrastColor
  } = club.colors

  const contrastColorTransparent = `${contrastColor}80`

  return (
    <Page className={classes.root}>
      <Helmet>
        <title>{i18next.t('common:younited')}: {club.name}</title>
      </Helmet>
      {
        Boolean(sidePanelProps) && (
          <SidePanel {...sidePanelProps}/>
        )
      }
      {
        Boolean(showCreateTeamModal) && (
          <Popup
            title={i18next.t('actions:add_team')}
            onClose={() => setShowCreateTeamModal(false)}
            body={
              <CreateTeamForm
                clubId={clubId}
                clubName={club.name}
                onCancel={() => setShowCreateTeamModal(false)}
                callback={getClub}
              />
            }
          />
        )
      }
      {
        Boolean(showCreateClubAdminModal) && (
          <Popup
            title={i18next.t('actions:add_club_admin')}
            onClose={() => setShowCreateClubAdminModal(false)}
            body={
              <CreateClubAdminForm
                clubId={clubId}
                clubName={club.name}
                onCancel={() => setShowCreateClubAdminModal(false)}
                callback={getClub}
              />
            }
          />
        )
      }
      {
        Boolean(showDeleteClubAdminModal) && (
          <Popup
            title={i18next.t('actions:delete_club_admin')}
            onClose={() => setShowDeleteClubAdminModal(false)}
            body={
              <DeleteClubAdminForm
                userId={showDeleteClubAdminModal.userId}
                userName={showDeleteClubAdminModal.userName}
                clubName={club.name}
                onCancel={() => setShowDeleteClubAdminModal(false)}
                callback={getClub}
                isAdmin={isAdmin}
              />
            }
          />
        )
      }
      {
        Boolean(showRenameClubModal) && (
          <Popup
            title={i18next.t('actions:rename_club')}
            onClose={() => setShowRenameClubModal(false)}
            body={
              <RenameClubForm
                clubId={club._id}
                clubName={club.name}
                onCancel={() => setShowRenameClubModal(false)}
                callback={getClub}
              />
            }
          />
        )
      }
      <div className={classes.header}>
        {
          Boolean(isAdmin) && (
            <BackButton/>
          )
        }
        <div className={classes.clubName} style={{ color: primaryColor }}>
          <div>{club.name}</div>
          {
            Boolean(isAdmin) && (
              <Button
                onClick={() => setShowRenameClubModal(true)}
              >{i18next.t('actions:rename_club')}</Button>
            )
          }
        </div>
      </div>
      <div className={classes.body}>
        <div>
          <Surface className={classes.surface}>
            <div className={classes.title}>
              <div>{i18next.t('common:teams')}</div>
              {
                Boolean(!isCoach) && (
                  <Button theme='primary' onClick={() => setShowCreateTeamModal(true)}>{i18next.t('actions:add_team')}</Button>
                )
              }
            </div>
            <NavList items={teamsNavList}/>
          </Surface>
          {
            Boolean(isAdmin) && (
              <Surface className={classes.surface}>
                <div className={classes.title}>
                  <div>{i18next.t('common:logo')}</div>
                  <div className={classes.buttons}>
                    <Button onClick={clearLogo}>{i18next.t('actions:delete')}</Button>
                    <Button theme='primary' onClick={selectLogo}>{i18next.t('actions:upload')}</Button>
                  </div>
                </div>
                <div className={classes.surfaceBody}>
                  {
                    Boolean(club.hasLogo) && (
                      <img
                        src={apiURL(`/club/${club._id}/logo`)}
                        alt='logo'
                        width={69}
                      />
                    )
                  }
                  <div className={classes.logoSizeLimit}>{i18next.t('common:logo_size_limit')}</div>
                </div>
              </Surface>
            )
          }
        </div>
        <div>
          {
            Boolean(!isCoach) && (
              <Surface className={classes.surface}>
                <div className={classes.title}>
                  <div>{i18next.t('common:club_admins')}</div>
                  <Button theme='primary' onClick={() => setShowCreateClubAdminModal(true)}>{i18next.t('actions:add_club_admin')}</Button>
                </div>
                <NavList items={clubAdminsNavList}/>
              </Surface>
            )
          }
          {
            Boolean(isAdmin) && (
              <Surface className={classes.surface}>
                <div className={classes.title}>
                  <div>{i18next.t('common:color_palette')}</div>
                </div>
                <div className={classes.surfaceBody}>
                  <div className={classes.colorContainer}>
                    <div className={classes.colorPickers}>
                      <Label value={i18next.t('common:primary_color')}>
                        <ColorPicker
                          value={primaryColor}
                          onChange={selectColor('primaryColor')}
                        />
                      </Label>
                      <Label value={i18next.t('common:contrast_color')}>
                        <ColorPicker
                          value={contrastColor}
                          onChange={selectColor('contrastColor')}
                        />
                      </Label>
                    </div>
                    <div className={classes.colorExampleContainer}>
                      <Label value={i18next.t('common:color_combination_example')}>
                        <div style={{ backgroundColor: primaryColor }} className={classes.padding}>
                          <div style={{ color: contrastColor }} className={classes.small}>{i18next.t('common:example')}</div>
                          <div style={{ color: contrastColorTransparent }} className={classes.large}>{i18next.t('common:example')}</div>
                        </div>
                        <div style={{ color: primaryColor }}
                             className={classes.medium}>{i18next.t('common:example')}
                        </div>
                      </Label>
                    </div>
                  </div>
                </div>
              </Surface>
            )
          }
        </div>
      </div>
    </Page>
  )
}

export default withError(ClubPage)
