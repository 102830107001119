import { useEffect } from 'react'

let globalCounter = 0

const useKeyDown = (key, callback, deps = []) => {
  return useEffect(() => {
    const counter = ++globalCounter
    const handleKeyDown = (event) => {
      if (counter === globalCounter && event.key === key) {
        callback()
      }
    }

    document.addEventListener('keydown', handleKeyDown)
    return () => {
      --globalCounter
      document.removeEventListener('keydown', handleKeyDown)
    }
    // eslint-disable-next-line
  }, [callback, key, ...deps])
}

export default useKeyDown
