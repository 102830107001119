import React from 'react'

const svg = (props) => {
  return (
    <svg id="Component_30" data-name="Component 30" xmlns="http://www.w3.org/2000/svg" width="9.43" height="11.336" viewBox="0 0 9.43 11.336" fill='currentColor' {...props}>
      <path id="Union_7" data-name="Union 7" d="M4.715,7.155,0,11.336V8.352L3.021,5.668,0,2.947V0L4.715,4.181,9.43,0V2.984L6.409,5.668,9.43,8.389v2.948Z"/>
    </svg>

  )
}

export default svg
