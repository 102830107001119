import React, { useState } from 'react'
import classes from '../StatisticsPage.module.scss'
import {
  makeWidthFlexible,
  XYPlot as XYPlotDefault,
  VerticalBarSeries,
  XAxis,
  YAxis,
  Hint
} from 'react-vis'
import i18next from '../../../translations'

const XYPlot = makeWidthFlexible(XYPlotDefault)

const ActivitiesPerMonth = (props) => {
  const { data } = props

  const [hoveredCell, setHoveredCell] = useState(null)

  return (
    <div className={classes.chartContainer}>
      <XYPlot xType="ordinal" height={300} xDistance={100} stackBy='y' className={classes.chart}>
        <XAxis/>
        <YAxis tickFormat={(float) => {
          const integer = parseInt(float, 10)
          return (integer === float) ? integer : null
        }}/>
        {
          data.map(({ type, data, color }, index) => {
            const count = (month) => {
              const { y } = data.find(({ x }) => x === month)
              return y
            }
            return (
              <VerticalBarSeries
                key={`StatisticsPage__activitiesPerMonthPerType__${index}`}
                data={data}
                barWidth={0.9}
                color={color}
                colorType='literal'
                onValueMouseOver={(value) => setHoveredCell({ value, type, count: count(value.x) })}
                onValueMouseOut={() => setHoveredCell(null)}
              />
            )
          })
        }
        {
          Boolean(hoveredCell) && (
            <Hint value={hoveredCell.value} className={classes.hint}>
              <div>
                {i18next.t(`activityTypes:${hoveredCell.type}`)} ({hoveredCell.count})
              </div>
            </Hint>
          )
        }
      </XYPlot>
    </div>
  )
}

export default ActivitiesPerMonth
