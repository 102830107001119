import React from 'react'
import classes from './NavList.module.scss'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import neutralizeEvent from '../../utils/neutralizeEvent'

const NavList = (props) => {
  const {
    items
  } = props

  return (
    <div className={classes.root} onClick={neutralizeEvent}>
      {
        items.map(({ to, content }, index) => {
          const key = `NavList__items__${index}`
          const noClick = typeof to === 'undefined'
          const classNames = [classes.item]
          if (noClick) {
            classNames.push(classes.noClick)
          }

          if (typeof to === 'function' || noClick) {
            return (
              <div key={key} className={classNames.join(' ')} onClick={to}>
                {content}
              </div>
            )
          }

          return (
            <NavLink key={key} to={to} className={classes.item}>
              {content}
            </NavLink>
          )
        })
      }
    </div>
  )
}

NavList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      to: PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.func.isRequired
      ]),
      content: PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.node.isRequired,
        PropTypes.arrayOf(PropTypes.node).isRequired
      ]).isRequired
    })
  ).isRequired
}

export default NavList
