const actions = (dispatch) => {
  const setFilterState = (filterState) => {
    dispatch({
      type: 'setFilterState',
      filterState
    })
  }
  const setSearchString = (searchString) => {
    dispatch({
      type: 'setSearchString',
      searchString
    })
  }
  const setTeamsFilter = (teamsFilter, clearUserFilter = false) => {
    dispatch({
      type: 'setTeamsFilter',
      teamsFilter,
      clearUserFilter
    })
  }
  const setClubsFilter = (clubsFilter) => {
    dispatch({
      type: 'setClubsFilter',
      clubsFilter
    })
  }
  const setActivityTypesFilter = (activityTypesFilter) => {
    dispatch({
      type: 'setActivityTypesFilter',
      activityTypesFilter
    })
  }
  const setStatusFilter = (statusFilter) => {
    dispatch({
      type: 'setStatusFilter',
      statusFilter
    })
  }
  const setSeasonFilter = (seasonFilter) => {
    dispatch({
      type: 'setSeasonFilter',
      seasonFilter
    })
  }
  const setMonthsFilter = (monthsFilter) => {
    dispatch({
      type: 'setMonthsFilter',
      monthsFilter
    })
  }
  const setUserFilter = (userFilter) => {
    dispatch({
      type: 'setUserFilter',
      userFilter
    })
  }
  return {
    setFilterState,
    setSearchString,
    setTeamsFilter,
    setClubsFilter,
    setActivityTypesFilter,
    setStatusFilter,
    setSeasonFilter,
    setMonthsFilter,
    setUserFilter
  }
}

export default actions
