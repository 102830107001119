import getSupportedLanguage from '../utils/getSupportedLanguage'
import { registerLocale, setDefaultLocale } from 'react-datepicker'
import { fr, nl } from 'date-fns/locale'

const lng = getSupportedLanguage()

// react-datepicker
registerLocale('nl', nl)
registerLocale('fr', fr)
setDefaultLocale(lng)
