import React from 'react'

const svg = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="19.212" viewBox="0 0 16 19.212" {...props}>
      <path className="a"
            d="M11.831,14.007,10.2,11.937a1.684,1.684,0,0,0-2.386-.263l-.824.684a.478.478,0,0,1-.614-.035A14.463,14.463,0,0,1,3.148,6.36a.459.459,0,0,1,.3-.544l1-.333a1.7,1.7,0,0,0,1.07-2.158L4.639.87A1.307,1.307,0,0,0,2.8.15C.149,1.588-1.465,4.711,1.9,10.885s6.875,6.49,9.524,5.052a1.289,1.289,0,0,0,.4-1.929Z"
            transform="matrix(0.966, -0.259, 0.259, 0.966, 0, 3.133)"/>
    </svg>
  )
}

export default svg
