import { format, parse } from 'date-fns'
import getLocale from './getLocale'

const getMonthOptions = (options = { long: true }) => {
  const locale = getLocale()
  return ([9, 10, 11, 12, 1, 2, 3, 4, 5, 6, 7, 8])
    .map((value) => {
      const date = parse(`01/${value}/2020`, 'dd/MM/yyyy', new Date())
      return {
        id: String(value),
        value: options.long
          ? format(date, 'MMMM', { locale })
          : format(date, 'MMM', { locale })
      }
    })
}

export default getMonthOptions
