import React, { useEffect, useState } from 'react'
import api from '../api'
import getCurrentSeason from '../utils/getCurrentSeason'

const withSeasonData = (Component) => (props) => {
  const [seasonRange, setSeasonRange] = useState(null)

  useEffect(() => {
    api.activity.getSeasonRange()
      .then(setSeasonRange)
  }, [])

  const currentSeason = getCurrentSeason()

  const getExistingSeason = (year) => {
    const first = parseInt(seasonRange.first)
    const last = parseInt(seasonRange.last)
    if (year < first) {
      return seasonRange.first
    }
    if (year > last) {
      return seasonRange.last
    }
    return String(year)
  }

  return seasonRange
    ? (
      <Component
        seasonRange={seasonRange}
        currentSeason={getExistingSeason(currentSeason)}
        {...props}
      />
      )
    : null
}

export default withSeasonData
