import React from 'react'
import classes from './Popup.module.scss'
import PropTypes from 'prop-types'
import Close from '../svg/Close'
import Overlay from '../Overlay/Overlay'
import Surface from '../Surface/Surface'
import IconButton from '../IconButton/IconButton'

const Popup = (props) => {
  const {
    title,
    body,
    onClose
  } = props

  return (
    <Overlay onClose={onClose}>
      <div className={classes.root}>
        <Surface>
          <div className={classes.header}>
            <div>{title}</div>
            <div className={classes.buttonContainer}>
                <IconButton
                  icon={Close}
                  onClick={onClose}
                />
            </div>
          </div>
          <div className={classes.body}>
            {body}
          </div>
        </Surface>
      </div>
    </Overlay>
  )
}

Popup.propTypes = {
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  body: PropTypes.node.isRequired
}

export default Popup
