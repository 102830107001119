import i18next from '../../../translations'

const getAgeGroupByBirthYear = (birthYear) => {
  const currentYear = (new Date()).getFullYear()
  const age = currentYear - birthYear
  if (!birthYear || isNaN(birthYear) || isNaN(age) || age <= 0) {
    return i18next.t('common:unknown')
  }
  if (age < 13) {
    return '0 - 12'
  }
  if (age < 18) {
    return '13 - 17'
  }
  if (age < 26) {
    return '18 - 25'
  }
  if (age < 31) {
    return '26 - 30'
  }
  if (age < 36) {
    return '31 - 35'
  }
  if (age < 41) {
    return '36 - 40'
  }
  if (age < 46) {
    return '41 - 45'
  }
  if (age < 51) {
    return '46 - 50'
  }
  if (age < 56) {
    return '51 - 55'
  }
  if (age < 61) {
    return '56 - 60'
  }
  return '60+'
}

export default getAgeGroupByBirthYear
