import React from 'react'

const svg = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16.674" height="15.937" viewBox="0 0 16.674 15.937" {...props}>
      <path className="a" d="M33.045,18.861a.424.424,0,1,0-.713.461l1.215,1.759a.424.424,0,0,0,.713-.461Z"
            transform="translate(-28.986 -17.261)"/>
      <path className="a"
            d="M53.054,14.175a.459.459,0,0,0-.5.335L52.175,16.6a.459.459,0,0,0,.335.5.434.434,0,0,0,.5-.335l.377-2.094A.4.4,0,0,0,53.054,14.175Z"
            transform="translate(-44.723 -13.706)"/>
      <path className="a"
            d="M43.012,12.262a.409.409,0,0,0-.5-.293.434.434,0,0,0-.335.5l.461,2.094a.459.459,0,0,0,.5.335.434.434,0,0,0,.335-.5Z"
            transform="translate(-36.818 -11.961)"/>
      <path className="a"
            d="M23.8,29.086l-.67-3.183a.424.424,0,0,0-.5-.335L11.192,28A5.311,5.311,0,0,0,7.716,35.62a5.431,5.431,0,0,0,10.136-3.727.859.859,0,0,1,.67-1.047l4.943-1.3a.387.387,0,0,0,.335-.462Z"
            transform="translate(-7.133 -22.712)"/>
    </svg>
  )
}

export default svg
