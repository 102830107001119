import React, { useEffect, useState } from 'react'
import classes from './ClubsPage.module.scss'
import i18next from '../../translations'
import Button from '../../components/Button/Button'
import Popup from '../../components/Popup/Popup'
import CreateClubForm from '../../components/CreateClubForm/CreateClubForm'
import api from '../../api'
import ClubCard from '../../components/ClubCard/ClubCard'
import { Helmet } from 'react-helmet'
import Page from '../Page/Page'

const ClubsPage = () => {
  const [clubs, setClubs] = useState([])
  const [showCreateClubModal, setShowCreateClubModal] = useState(false)

  const createClubFormCallback = () => {
    api.club.getClubs()
      .then((clubs) => {
        clubs.sort((a, b) => {
          if (a.name.toLowerCase() > b.name.toLowerCase()) {
            return 1
          }
          if (a.name.toLowerCase() < b.name.toLowerCase()) {
            return -1
          }
          return 0
        })
        setShowCreateClubModal(false)
        setClubs(clubs)
      })
  }

  useEffect(() => {
    createClubFormCallback()
  }, [])

  return (
    <Page className={classes.root}>
      <Helmet>
        <title>Younited: Clubs</title>
      </Helmet>
      {
        Boolean(showCreateClubModal) && (
          <Popup
            onClose={() => setShowCreateClubModal(false)}
            title={i18next.t('actions:add_club')}
            body={
              <CreateClubForm
                onCancel={() => setShowCreateClubModal(false)}
                callback={createClubFormCallback}
              />
            }
          />
        )
      }
      <div className={classes.header}>
        <Button theme='primary' onClick={() => setShowCreateClubModal(true)}>
          {i18next.t('actions:add_club')}
        </Button>
      </div>
      <div className={classes.body}>
        {
          clubs.map((club, index) => {
            return (
              <ClubCard
                key={`ClubsPage__clubs__${club._id}__${index}`}
                club={club}
              />
            )
          })
        }
      </div>
    </Page>
  )
}

export default ClubsPage
