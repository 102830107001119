const neutralizeEvent = (event) => {
  try {
    if (event) {
      event.stopPropagation()
      event.preventDefault()
      if (event.nativeEvent) {
        event.nativeEvent.stopImmediatePropagation()
        event.nativeEvent.preventDefault()
      }
    }
  } catch (error) {
    console.error('neutralizeEvent failed for event', event, ': ', error)
  }
}

export default neutralizeEvent
