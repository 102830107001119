import React from 'react'

const svg = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17.311" height="20" viewBox="0 0 17.311 20" {...props}>
      <g transform="translate(0.658 1)">
        <g transform="translate(-15.162 -10.5)">
          <path
            d="M21.5,31.5V43a2.353,2.353,0,0,0,2.345,2.347h8.676A2.353,2.353,0,0,0,34.871,43V31.5Zm3.985,11.731H24.317V33.61h1.173Zm3.284,0H27.6V33.61h1.173Zm3.284,0H30.884V33.61h1.173Z"
            transform="translate(-5.357 -16.844)"/>
          <path d="M24.589,11.143V9.5H21.07v1.643H14.5v2.347H31.156V11.143Z" transform="translate(0)"/>
        </g>
      </g>
    </svg>
  )
}

export default svg
