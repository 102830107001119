import React from 'react'

const svg = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15.968" viewBox="0 0 16 15.968" {...props}>
      <path className="a"
            d="M15.91,14.228a9.55,9.55,0,0,0-1.1-1.325,9.435,9.435,0,0,0-4.287-2.463A4.437,4.437,0,0,0,12.589,6.7V4.927a4.433,4.433,0,0,0-8.865,0V6.7h0a4.434,4.434,0,0,0,2.06,3.739A9.422,9.422,0,0,0,1.508,12.9,9.546,9.546,0,0,0,.444,14.17a1.506,1.506,0,0,0,1.218,2.386H14.655a1.5,1.5,0,0,0,1.255-2.327Z"
            transform="translate(-0.16 -0.587)"/>
    </svg>
  )
}

export default svg
