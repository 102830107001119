import React, { useRef, useState } from 'react'
import classes from './Select.module.scss'
import PropTypes from 'prop-types'
import spoofedEvent from '../../utils/spoofedEvent'
import Button from '../Button/Button'
import useClickOutside from '../../customHooks/useClickOutside'
import useKeyDown from '../../customHooks/useKeyDown'
import NavList from '../NavList/NavList'
import i18next from 'i18next'
import Input from '../Input/Input'

const Select = (props) => {
  const {
    title,
    value,
    options,
    search = false,
    onChange,
    clearable = true,
    showTitle = true,
    clearText
  } = props

  const rootRef = useRef(null)
  const [expanded, setExpanded] = useState(false)
  const [searchValue, setSearchValue] = useState('')

  useKeyDown('Escape', () => setExpanded(false))
  useClickOutside(rootRef, () => setExpanded(false))

  const selectValue = (newValue) => {
    const target = {
      value: newValue
    }
    const event = spoofedEvent(target)
    onChange(event)
    setExpanded(false)
  }

  const clear = () => {
    const target = {
      value: undefined
    }
    const event = spoofedEvent(target)
    onChange(event)
  }

  const selectedOption = options.find(({ id }) => id === value)
  const hasValue = Boolean(value && options.some(({ id }) => id === value) && selectedOption)

  const rootClassNames = [classes.root]
  if (expanded) {
    rootClassNames.push(classes.expanded)
  }
  if (hasValue) {
    rootClassNames.push(classes.hasValue)
  }

  const optionsNavList = options
    .filter(({ value }) => {
      if (!search || !searchValue) {
        return true
      }
      const regex = new RegExp(searchValue, 'i')
      return regex.test(value)
    })
    .map(({ id, value, displayValue }) => ({
      content: displayValue || value,
      to: () => selectValue(id)
    }))

  return (
    <div className={rootClassNames.join(' ')} ref={rootRef}>
      <div className={classes.value} onClick={() => setExpanded(!expanded)}>
        {
          hasValue
            ? (
              <span>
              {
                showTitle && (
                  <span>{title} -</span>
                )
              }
                {selectedOption.displayValue || selectedOption.value}
          </span>
              )
            : <span>{title}</span>
        }
      </div>
      {
        Boolean(expanded) && (
          <div className={classes.popup}>
            {
              Boolean(search) && (
                <Input
                  value={searchValue}
                  onChange={({ target }) => setSearchValue(target.value)}
                  width='100%'
                  placeholder={i18next.t('actions:search')}
                  search
                />
              )
            }
            <div className={classes.navListContainer}>
              <NavList items={optionsNavList}/>
            </div>
            {
              Boolean(clearable) && (
                <div className={classes.buttons}>
                  <Button onClick={clear} style={{ paddingRight: 0 }}>
                    {clearText || i18next.t('actions:clear')}
                  </Button>
                </div>
              )
            }
          </div>
        )
      }
    </div>
  )
}

Select.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    displayValue: PropTypes.any
  })).isRequired,
  search: PropTypes.bool,
  clearable: PropTypes.bool,
  showTitle: PropTypes.bool,
  clearText: PropTypes.string
}

export default Select
