import React from 'react'

const svg = (props) => {
  return (
    <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg" {...props}>
        <line y2="8" x2="14" y1="8" x1="2" strokeWidth="3" stroke="currentColor"/>
    </svg>
  )
}

export default svg
