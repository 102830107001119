import React, { useRef } from 'react'
import classes from './Input.module.scss'
import PropTypes from 'prop-types'
import spoofedEvent from '../../utils/spoofedEvent'
import Close from '../svg/Close'
import Search from '../svg/Search'
import IconButton from '../IconButton/IconButton'

const Input = (props) => {
  const {
    name,
    type: typeOriginal = 'text',
    value,
    onChange,
    disabled,
    autoComplete = 'new-password',
    button,
    width,
    search,
    customRequired,
    valid,
    className = '',
    ...restProps
  } = props

  const inputRef = useRef(null)

  const isNumber = Boolean(typeOriginal === 'number')
  const type = isNumber ? 'text' : typeOriginal

  const handleChange = ({ target }) => {
    const targetValue = isNumber
      ? String(target.value).replace(',', '.')
      : target.value
    const newValue = isNumber && isNaN(targetValue)
      ? value
      : targetValue
    const event = spoofedEvent({
      name: target.name,
      value: newValue
    })
    if (isNumber && typeof newValue === 'string' && newValue.endsWith('.')) {
      event.preventUpdate = true
    }
    onChange(event)
  }

  const clearValue = () => {
    const event = spoofedEvent({
      name,
      value: ''
    })
    onChange(event)
  }

  const renderCustomRequired = () => {
    if (!customRequired) {
      return null
    }
    return (
      <input
        ref={inputRef}
        className={classes.customRequiredInput}
        value={valid ? 'valid' : ''}
        tabIndex={-1}
        onFocus={() => inputRef.current.blur()}
        onChange={() => {}}
        required
      />
    )
  }

  const renderSearchIcon = () => {
    if (!search) {
      return null
    }
    return (
      <div className={classes.iconContainer}>
        <Search width={19} height={19}/>
      </div>
    )
  }

  const renderButton = () => {
    if (!button && button !== undefined) {
      return null
    }
    if (button) {
      return (
        <div className={classes.buttonContainer}>
          <IconButton
            onClick={button.onClick}
            icon={button.icon}
            tabIndex={-1}
          />

        </div>
      )
    } else if ((value || '').length) {
      return (
        <div className={classes.buttonContainer}>
          <IconButton
            icon={Close}
            onClick={clearValue}
          />
        </div>
      )
    }
  }

  const displayValue = isNumber
    ? String(value).replace('.', ',').replace('null', '')
    : value

  const rootStyle = {}
  if (width) {
    rootStyle.width = width
  }

  const renderedSearchIcon = renderSearchIcon()
  const renderedButton = renderButton()

  const rootClassNames = [classes.root]
  if (renderedSearchIcon) {
    rootClassNames.push(classes.hasSearch)
  }
  if (renderedButton) {
    rootClassNames.push(classes.hasButton)
  }

  const inputClassNames = [classes.input, className]

  return (
    <div className={rootClassNames.join(' ')} style={rootStyle}>
      {
        renderCustomRequired()
      }
      {renderedSearchIcon}
      <input
        name={name}
        type={type}
        value={displayValue || ''}
        onChange={handleChange}
        {...restProps}
        className={inputClassNames.join(' ')}
        disabled={disabled}
        autoComplete={autoComplete}
      />
      {renderedButton}
    </div>
  )
}

Input.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  button: PropTypes.shape({
    onClick: PropTypes.func.isRequired,
    icon: PropTypes.func.isRequired
  }),
  search: PropTypes.bool,
  customRequired: PropTypes.bool,
  valid: PropTypes.bool,
  className: PropTypes.string
}

export default Input
