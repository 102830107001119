import { useEffect } from 'react'

let globalCounter = 0

const useClickOutside = (ref, callback, deps = []) => {
  return useEffect(() => {
    const counter = ++globalCounter
    const handleClick = (event) => {
      if (counter === globalCounter && ref.current && !ref.current.contains(event.target)) {
        callback()
      }
    }
    document.querySelector('#root').addEventListener('click', handleClick)
    return () => {
      --globalCounter
      document.querySelector('#root').removeEventListener('click', handleClick)
    }
    // eslint-disable-next-line
  }, [ref, callback, ...deps])
}

export default useClickOutside
