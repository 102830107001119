const getAuthenticationFromSessionStorage = () => {
  try {
    return JSON.parse(sessionStorage.getItem('authentication'))
  } catch (error) {
    return null
  }
}

const defaultState = () => ({
  authentication: getAuthenticationFromSessionStorage(),
  me: null,
  headerHeight: 82
})

const reducer = (state = defaultState(), action) => {
  const newState = Object.assign({}, state)
  switch (action.type) {
    case 'SET_DEFAULT': {
      sessionStorage.clear()
      return defaultState()
    }

    case 'SET_AUTHENTICATION': {
      newState.authentication = action.authentication
      sessionStorage.setItem('authentication', JSON.stringify(action.authentication))
      return newState
    }

    case 'SET_ME': {
      newState.me = action.me
      return newState
    }

    case 'SET_HEADER_HEIGHT': {
      newState.headerHeight = action.headerHeight
      return newState
    }

    default: {
      return state
    }
  }
}

export default reducer
