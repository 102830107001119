import React from 'react'

const svg = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="15.932" height="13.837" viewBox="0 0 15.932 13.837" {...props}>
      <path className="a"
            d="M2.078,6.57H13.846a2.09,2.09,0,0,1,2.082,2.082v6.16a2.09,2.09,0,0,1-2.082,2.082H6.9L4.539,20.209a.474.474,0,0,1-.859-.274V16.894h-1.6A2.09,2.09,0,0,1,0,14.812V8.652A2.09,2.09,0,0,1,2.077,6.57Z"
            transform="translate(0.004 -6.57)"/>
    </svg>
  )
}

export default svg
