import apiURL from './apiURL'
import handleFetch from './handleFetch.js'
import getAuthorization from './getAuthorization'

export const getClub = async (clubId) => {
  const url = apiURL(`/club/${clubId}`)
  const options = {
    method: 'GET',
    headers: {
      ...getAuthorization()
    }
  }
  return handleFetch(url, options)
}

export const getClubs = async () => {
  const url = apiURL('/club')
  const options = {
    method: 'GET',
    headers: {
      ...getAuthorization()
    }
  }
  return handleFetch(url, options)
}

export const createClub = async (club) => {
  const url = apiURL('/club')
  const options = {
    method: 'POST',
    headers: {
      ...getAuthorization(),
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(club)
  }
  return handleFetch(url, options)
}

export const updateClub = async (clubId, club) => {
  const url = apiURL(`/club/${clubId}`)
  const options = {
    method: 'PATCH',
    headers: {
      ...getAuthorization(),
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(club)
  }
  return handleFetch(url, options)
}

export const updateClubLogo = async (clubId, logo) => {
  const url = apiURL(`/club/${clubId}/logo`)
  const formData = new FormData()
  formData.append('logo', logo)
  const options = {
    method: 'PATCH',
    headers: {
      ...getAuthorization()
    },
    body: formData
  }
  return handleFetch(url, options)
}

export const createTeam = async (clubId, team) => {
  const url = apiURL(`/club/${clubId}/team`)
  const options = {
    method: 'POST',
    headers: {
      ...getAuthorization(),
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(team)
  }
  return handleFetch(url, options)
}

export const getTeams = async (clubId, params = {}) => {
  const query = new URLSearchParams(params)
  const url = clubId
    ? apiURL(`/club/${clubId}/team?${query}`)
    : apiURL(`/team?${query}`)
  const options = {
    method: 'GET',
    headers: {
      ...getAuthorization()
    }
  }
  return handleFetch(url, options)
}

export const updateTeam = async (clubId, teamId, team) => {
  const url = apiURL(`/club/${clubId}/team/${teamId}`)
  const options = {
    method: 'PATCH',
    headers: {
      ...getAuthorization(),
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(team)
  }
  return handleFetch(url, options)
}

export const deleteTeam = async (clubId, teamId) => {
  const url = apiURL(`/club/${clubId}/team/${teamId}`)
  const options = {
    method: 'DELETE',
    headers: {
      ...getAuthorization(),
      'Content-Type': 'application/json'
    }
  }
  return handleFetch(url, options)
}
