import React from 'react'
import classes from '../StatisticsPage.module.scss'
import i18next from '../../../translations'

const RegularAttendees = (props) => {
  const { data } = props

  return (
    <div className={classes.table}>
      <div className={classes.row}>
          <div>{i18next.t('common:activities_count')}</div>
          <div>{i18next.t('common:attendees_count')}</div>
      </div>
      {
        data.map(({ x, y }, index) => {
          const key = `RegularAttendees__data__${index}`
          return (
            <div key={key} className={classes.row}>
              <div>{x}</div>
              <div>{y}</div>
            </div>
          )
        })
      }
    </div>
  )
}

export default RegularAttendees
