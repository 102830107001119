import React, { useState } from 'react'
import PropTypes from 'prop-types'
import i18next from '../../translations'
import api from '../../api'
import Surface from '../Surface/Surface'
import SurfaceBody from '../SurfaceBody/SurfaceBody'
import PopupButtons from '../PopupButtons/PopupButtons'
import Button from '../Button/Button'
import neutralizeEvent from '../../utils/neutralizeEvent'
import addMessage from '../../utils/addMessage'
import CheckBox from '../CheckBox/CheckBox'
import Popup from '../Popup/Popup.js'
import classes from './DeleteClubAdminForm.module.scss'

const DeleteClubAdminForm = (props) => {
  const [fullDeleteChecked, setFullDeleteChecked] = useState(false)
  const [showWarning, setShowWarning] = useState(false)

  const {
    userId,
    userName,
    clubName,
    onCancel,
    callback,
    isAdmin
  } = props

  const handleSubmit = (event) => {
    neutralizeEvent(event)
    api.user.deleteUser(userId, fullDeleteChecked)
      .then(() => {
        addMessage(
          i18next.t('messages:name_removed_as_club_admin_from_club', { name: userName, club: clubName }),
          { type: 'error' }
        )
        callback()
      })
  }

  function toggleValue (a) {
    setFullDeleteChecked(!fullDeleteChecked)
    if (!fullDeleteChecked) {
      setShowWarning(true)
    }
  }

  function cancelFullDelete () {
    setFullDeleteChecked(false)
    setShowWarning(false)
  }

  function confirmFullDelete () {
    setShowWarning(false)
  }

  return (
    <form onSubmit={handleSubmit}>
      { showWarning &&
        <Popup
          title={i18next.t('actions:delete')}
          onClose={() => setShowWarning(false)}
          body={
            <Surface>
              <SurfaceBody>
                {i18next.t('actions:delete_club_admin_force_confirmation')}
              </SurfaceBody>
              <PopupButtons>
                <Button onClick={cancelFullDelete}>{i18next.t('actions:cancel')}</Button>
                <Button onClick={confirmFullDelete} theme='primary'>{i18next.t('actions:ok')}</Button>
              </PopupButtons>
            </Surface>
          }
        />
      }
      <Surface>
        <SurfaceBody>
          {i18next.t('messages:confirm_delete_club_admin')}
          {isAdmin &&
            <label className={classes.checkbox}>
              <CheckBox
                name="toggleId"
                checked={fullDeleteChecked}
                onChange={toggleValue}
              />
              <div className={classes.checkboxText}>{i18next.t('actions:delete_club_admin_force_message')}</div>
            </label>
          }
        </SurfaceBody>
        <PopupButtons>
          <Button onClick={onCancel}>{i18next.t('actions:cancel')}</Button>
          <Button type='submit' theme='primary'>{i18next.t('actions:delete_club_admin')}</Button>
        </PopupButtons>
      </Surface>
    </form>
  )
}

DeleteClubAdminForm.propTypes = {
  userId: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  callback: PropTypes.func.isRequired
}

export default DeleteClubAdminForm
