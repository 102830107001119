import genderDefinitions from '../../../definitions/genders.json'
import i18next from '../../../translations'
import genderColorDefinitions from '../../../definitions/genderColors.json'

const getGenders = (uniqueAttendees) => {
  return Object.values(Array
    .from(uniqueAttendees)
    .reduce((genders, { gender }) => {
      const genderName = genderDefinitions[gender] || i18next.t('common:unknown')
      if (genders[genderName]) {
        genders[genderName].angle++
        genders[genderName].meta.value++
      } else {
        genders[genderName] = {
          angle: 1,
          label: genderName,
          color: genderColorDefinitions[genderName],
          meta: {
            name: genderName,
            value: 1
          }
        }
      }
      return genders
    }, {}))
    .map((slice) => {
      slice.label = `${slice.label} (${slice.angle})`
      return slice
    })
}

export default getGenders
