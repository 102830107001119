import React from 'react'
import classes from './Overlay.module.scss'
import PropTypes from 'prop-types'
import { createPortal } from 'react-dom'
import useKeyDown from '../../customHooks/useKeyDown'

const Overlay = (props) => {
  const {
    onClose,
    children
  } = props

  const hasOnClose = onClose && Object.prototype.toString.apply(onClose).slice(8, -1) === 'Function'

  useKeyDown('Escape', onClose, [hasOnClose, onClose])

  return createPortal(
    <div className={classes.root}>
      <div className={classes.container}>
        {children}
      </div>
    </div>,
    document.body
  )
}

Overlay.propTypes = {
  onClose: PropTypes.func.isRequired
}

export default Overlay
