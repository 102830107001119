import nlLocale from 'date-fns/locale/nl-BE'
import frLocale from 'date-fns/locale/fr'
import i18next from '../translations'

const getLocale = () => {
  const getCurrentLng = i18next.language || window.localStorage.i18nextLng || ''
  return getCurrentLng === 'nl' ? nlLocale : frLocale
}

export default getLocale
