import React, { useCallback, useEffect, useState } from 'react'
import classes from '../StatisticsPage.module.scss'
import {
  makeWidthFlexible,
  XYPlot as XYPlotDefault,
  VerticalBarSeries,
  XAxis,
  YAxis,
  Hint
} from 'react-vis'
import i18next from '../../../translations'
import { format, parseISO } from 'date-fns'
import activityTypeColors from '../../../definitions/activityTypeColors.json'
import Pagination from '../../../components/Pagination/Pagination'

const XYPlot = makeWidthFlexible(XYPlotDefault)
const attendeeTypeColors = {
  attendees: activityTypeColors.training,
  coaches: activityTypeColors.teambuilding,
  supporters: activityTypeColors.younited
}
const pageSize = 10

const ActivityAttendees = (props) => {
  const { data } = props

  const getPages = useCallback(() => {
    return Math.ceil(data.length / pageSize)
  }, [data.length])

  const [hoveredCell, setHoveredCell] = useState(null)
  const [pages, setPages] = useState(getPages())
  const [page, setPage] = useState(pages)

  useEffect(() => {
    const pages = getPages()
    setPages(pages)
    setPage(pages)
  }, [getPages])

  const attendeeTypes = Object.keys(attendeeTypeColors)

  return <div className={classes.chartContainer} style={{ paddingBottom: '120px', paddingLeft: '80px' }}>
    {
      pages > 1 && <Pagination pages={pages} page={page} setPage={setPage} />
    }
      <XYPlot xType="ordinal" height={300} xDistance={100} stackBy='y' className={[classes.chart, classes.visibleOverflow].join(' ')}>
        <XAxis tickLabelAngle={320} style={{ overflow: 'visible' }} />
        <YAxis tickFormat={(float) => {
          const integer = parseInt(float, 10)
          return (integer === float) ? integer : null
        }}/>
        {
          attendeeTypes.map((attendeeType) => {
            const sliceStart = (pages - page + 1) * -pageSize
            const sliceEnd = sliceStart + pageSize
            const dataSlice = sliceEnd === 0
              ? data.slice(sliceStart)
              : data.slice(sliceStart, sliceEnd)

            const attendeeTypeData = dataSlice.map(({ [attendeeType]: attendees, date, activityType }, index, dataSliceReference) => {
              const getLabel = (date, activityType, labelIndex = 0) => {
                const formattedDate = format(parseISO(date), 'dd/MM/yyyy')
                const activityName = i18next.t(`activityTypes:${activityType}`)
                return labelIndex
                  ? `${activityName} (${labelIndex}) ${formattedDate}`
                  : `${activityName} ${formattedDate}`
              }

              const previousSubSlice = dataSliceReference.slice(0, index)
              const nextSubSlice = dataSliceReference.slice(index + 1)
              let label = getLabel(date, activityType)
              const hasDuplicateLabels = [...previousSubSlice, ...nextSubSlice].some(({ date, activityType }) => {
                return label === getLabel(date, activityType)
              })

              if (hasDuplicateLabels) {
                const labelIndex = previousSubSlice.reduce(
                  (labelIndex, { date, activityType }) => {
                    if (label === getLabel(date, activityType)) {
                      labelIndex++
                    }
                    return labelIndex
                  },
                  1
                )
                label = getLabel(date, activityType, labelIndex)
              }

              return {
                x: label,
                y: attendees,
                attendeeType,
                attendees
              }
            })
            return (
              <VerticalBarSeries
                key={`StatisticsPage__ActivityAttendees__${attendeeType}`}
                data={attendeeTypeData}
                barWidth={0.9}
                color={attendeeTypeColors[attendeeType]}
                colorType='literal'
                onValueMouseOver={(value) => setHoveredCell({ value })}
                onValueMouseOut={() => setHoveredCell(null)}
              />
            )
          })
        }
        {
          Boolean(hoveredCell) && (
            <Hint value={hoveredCell.value} className={classes.hint}>
              <div>
                {i18next.t(`common:${hoveredCell.value.attendeeType}`)} ({hoveredCell.value.attendees})
              </div>
            </Hint>
          )
        }
      </XYPlot>
    </div>
}

export default ActivityAttendees
