import React from 'react'
import './config/localeConfig'
import './translations'
import 'react-datepicker/dist/react-datepicker.css'
import Router from './components/Router/Router'
import SnackBar from './components/SnackBar/SnackBar'

const App = () => {
  return (
    <div className='App'>
      <Router />
      <SnackBar />
    </div>
  )
}

export default App
