import React, { useState, useEffect } from 'react'
import Helmet from 'react-helmet'
import hexToRGB from '../../utils/hexToRGB'

const ClubColorDefinitions = (props) => {
  const {
    club
  } = props

  const [color, setColor] = useState(hexToRGB('#C71A1A').join(', '))
  const [contrastColor, setContrastColor] = useState(hexToRGB('#FAFAFA').join(', '))

  useEffect(() => {
    if (club && club.colors) {
      const colorString = hexToRGB(club.colors.primaryColor).join(', ')
      setColor(colorString)
      const contrastColorString = hexToRGB(club.colors.contrastColor).join(', ')
      setContrastColor(contrastColorString)
    }
  }, [club])

  return color && contrastColor
    ? (
      <Helmet>
        <style type="text/css">
          {`
            :root {
              --club-color: ${color};
              --club-contrast-color: ${contrastColor};
              }
          `}
        </style>
      </Helmet>
      )
    : null
}

export default ClubColorDefinitions
