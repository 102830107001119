const openFilePicker = (options = {}) => {
  const {
    attributes = {}
  } = options

  return new Promise((resolve, reject) => {
    const picker = document.createElement('input')
    try {
      picker.setAttribute('type', 'file')
      for (const key in attributes) {
        const value = attributes[key]
        picker.setAttribute(key, value)
      }
      picker.addEventListener('change', () => {
        const result = attributes.multiple
          ? picker.files
          : picker.files[0]
        picker.remove()
        resolve(result)
      })
      picker.click()
    } catch (error) {
      picker.remove()
      reject(error)
    }
  })
}

export default openFilePicker
