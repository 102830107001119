import { v4 as uuidv4 } from 'uuid'

const initialState = {
  messages: []
}

function reducer (state = initialState, action) {
  const newState = Object.assign({}, state)
  switch (action.type) {
    case 'ADD_MESSAGE': {
      const message = {
        uuid: uuidv4(),
        message: action.message,
        type: action.options.type
      }
      newState.messages = [...state.messages, message]
      return newState
    }

    case 'DELETE_MESSAGE': {
      newState.messages = newState.messages.filter(({ uuid }) => uuid !== action.uuid)
      return newState
    }

    default: return state
  }
}

export default reducer
