import getRegularAttendees from './getRegularAttendees'
import getUniqueAttendees from './getUniqueAttendees'
import getAverageEventsPerAttendee from './getAverageEventsPerAttendee'
import getAverageAttendeesPerEvent from './getAverageAttendeesPerEvent'
import getGenders from './getGenders'
import getAgeGroups from './getAgeGroups'
import getActivityTypesStatistics from './getActivityTypesStatistics'

const getParsedStatistics = (statistics) => {
  const {
    allAttendees,
    activitiesPerMonthPerType,
    activitiesPerType
  } = getActivityTypesStatistics(statistics.activityTypes)
  const activityAttendees = statistics.activityAttendees
  const totalActivities = activityAttendees.length
  const uniqueAttendees = getUniqueAttendees(allAttendees)

  const ageGroups = getAgeGroups(uniqueAttendees)
  const averageAttendeesPerEvent = getAverageAttendeesPerEvent(allAttendees, totalActivities)
  const averageEventsPerAttendee = getAverageEventsPerAttendee(allAttendees, uniqueAttendees)
  const genders = getGenders(uniqueAttendees)
  const regularAttendees = getRegularAttendees(statistics)
  const uniqueAttendeesCount = uniqueAttendees.length

  return {
    activitiesPerMonthPerType,
    activitiesPerType,
    activityAttendees,
    ageGroups,
    averageAttendeesPerEvent,
    averageEventsPerAttendee,
    genders,
    regularAttendees,
    totalActivities,
    uniqueAttendeesCount
  }
}

export default getParsedStatistics
