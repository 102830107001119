import React from 'react'
import classes from './Tabs.module.scss'
import PropTypes from 'prop-types'

const Tabs = (props) => {
  const {
    tabs
  } = props

  return (
    <div className={classes.root}>
      {
        tabs.map((tab, index) => {
          const {
            title,
            subTitle,
            onClick,
            isSelected
          } = tab
          const key = `Tabs__tab__${index}`
          const classNames = [classes.tab]
          if (isSelected) {
            classNames.push(classes.selected)
          }
          return (
            <div key={key} className={classNames.join(' ')} onClick={onClick}>
              <div className={classes.title}>{title}</div>
              {
                Boolean(subTitle) && (
                  <div className={classes.subTitle}>{subTitle}</div>
                )
              }
            </div>
          )
        })
      }
    </div>
  )
}

Tabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      subTitle: PropTypes.string,
      onClick: PropTypes.func.isRequired,
      isSelected: PropTypes.boolean
    }).isRequired
  ).isRequired
}

export default Tabs
