import apiURL from './apiURL'
import handleFetch from './handleFetch.js'

export const login = async (email, password) => {
  const url = apiURL('/auth/login')
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      email,
      password
    })
  }
  return handleFetch(url, options)
}

export const verify = async (token) => {
  const url = apiURL('/auth/verify')
  const options = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'X-from-server': 'analytics'
    }
  }
  return handleFetch(url, options)
}

export const requestPasswordReset = async (email) => {
  const url = apiURL('/auth/requestPasswordReset')
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      email
    })
  }
  return handleFetch(url, options)
}
