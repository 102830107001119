const actions = (dispatch) => {
  const setFilterState = (filterState) => {
    dispatch({
      type: 'setFilterState',
      filterState
    })
  }
  const setSeasonFilter = (seasonFilter) => {
    dispatch({
      type: 'setSeasonFilter',
      seasonFilter
    })
  }
  const setPeriodFilter = (periodFilter) => {
    dispatch({
      type: 'setPeriodFilter',
      periodFilter
    })
  }
  const setClubsFilter = (clubsFilter) => {
    dispatch({
      type: 'setClubsFilter',
      clubsFilter
    })
  }
  const setTeamsFilter = (teamsFilter) => {
    dispatch({
      type: 'setTeamsFilter',
      teamsFilter
    })
  }
  const setUserFilter = (userFilter) => {
    dispatch({
      type: 'setUserFilter',
      userFilter
    })
  }
  return {
    setFilterState,
    setSeasonFilter,
    setPeriodFilter,
    setClubsFilter,
    setTeamsFilter,
    setUserFilter
  }
}

export default actions
