import React, { useCallback, useEffect, useReducer, useState } from 'react'
import { createPortal } from 'react-dom'
import classes from './StatisticsPage.module.scss'
import i18next from '../../translations'
import { Helmet } from 'react-helmet'
import { useResizeDetector } from 'react-resize-detector'
import api from '../../api'
import filterReducer from './reducer'
import actions from './actions'
import withSeasonData from '../../HOC/withSeasonData'
import usePrevious from '../../customHooks/usePrevious'
import getQueryParams from '../../utils/getQueryParams'
import setQueryParams from '../../utils/setQueryParams'
import Page from '../Page/Page'
import Select from '../../components/Select/Select'
import SelectMultiple from '../../components/SelectMultiple/SelectMultiple'
import Button from '../../components/Button/Button'
import Surface from '../../components/Surface/Surface'
import SurfaceBody from '../../components/SurfaceBody/SurfaceBody'
import RegularAttendeesChart from './charts/RegularAttendees'
import RegularAttendeesTable from './tables/RegularAttendees'
import ActivitiesPerMonthChart from './charts/ActivitiesPerMonth'
import ActivityAttendeesChart from './charts/ActivityAttendees'
import ActivityTypesChart from './charts/ActivityTypes'
import ActivityTypesTable from './tables/ActivityTypes'
import Toggle from '../../components/Toggle/Toggle'
import PieChart from '../../components/svg/PieChart'
import TextLines from '../../components/svg/TextLines'
import GendersChart from './charts/Genders'
import GendersTable from './tables/Genders'
import AgeGroupsChart from './charts/AgeGroups'
import AgeGroupsTable from './tables/AgeGroups'
import getParsedStatistics from './parser/getParsedStatistics'
import { set, format } from 'date-fns'
import useMe from '../../customHooks/useMe'
import useIsMounted from '../../customHooks/useIsMounted'

const StatisticsPage = (props) => {
  const {
    seasonRange,
    currentSeason
  } = props

  const { me, isAdmin } = useMe()

  const getFilterStateFromParams = () => {
    const params = getQueryParams()
    const filterStateFromParams = Object.assign({}, params)
    for (const filterName in filterStateFromParams) {
      const value = filterStateFromParams[filterName]
      if (typeof value !== 'object') {
        filterStateFromParams[filterName] = String(value)
      }
    }
    return ((isAdmin && filterStateFromParams.user) || (!isAdmin && filterStateFromParams.club))
      ? {}
      : filterStateFromParams
  }
  const filterStateFromParams = getFilterStateFromParams()

  const defaultFilter = {
    seasonFilter: currentSeason,
    periodFilter: 'season',
    clubsFilter: [],
    teamsFilter: [],
    userFilter: null
  }

  const defaultFilterWithParams = {
    ...defaultFilter,
    ...filterStateFromParams
  }

  const getSavedFilter = () => {
    try {
      if (!Object.keys(filterStateFromParams).length) {
        const { role, ...sessionStorageFilter } = JSON.parse(sessionStorage.getItem('statisticsPageFilterState'))
        if (role === me.role) {
          return sessionStorageFilter
        }
      }
    } catch (error) { }
  }

  const isMounted = useIsMounted()
  const [pageWidth, setPageWidth] = useState(0)
  const [statistics, setStatistics] = useState({ activityTypes: [], activityAttendees: [] })
  const [parsedStatistics, setParsedStatistics] = useState(getParsedStatistics(statistics))
  const [clubs, setClubs] = useState([])
  const prevClubs = usePrevious(clubs)
  const [teams, setTeams] = useState([])
  const prevTeams = usePrevious(teams)
  const [users, setUsers] = useState([])
  const [regularAttendeesView, setRegularAttendeesView] = useState('chart')
  const [activityTypeView, setActivityTypeView] = useState('chart')
  const [genderView, setGenderView] = useState('chart')
  const [ageGroupsView, setAgeGroupsView] = useState('table')

  const viewTypes = [
    {
      id: 'chart',
      icon: PieChart
    },
    {
      id: 'table',
      icon: TextLines
    }
  ]

  // filters
  const [filterState, dispatchFilter] = useReducer(filterReducer, getSavedFilter() || defaultFilterWithParams)
  const prevFilterState = usePrevious(filterState)
  const {
    setFilterState,
    setSeasonFilter,
    setPeriodFilter,
    setClubsFilter,
    setTeamsFilter,
    setUserFilter
  } = actions(dispatchFilter)

  // get new statistics using the current filter
  const filterStatistics = useCallback(() => {
    const {
      seasonFilter,
      periodFilter,
      clubsFilter,
      teamsFilter,
      userFilter
    } = filterState
    if ((isAdmin && teamsFilter && !clubsFilter) || (userFilter && !teamsFilter)) {
      return
    }
    const year = Number(seasonFilter)
    let fromDate = set(new Date(), { year, month: 8, date: 1 })
    let toDate = set(new Date(), { year: year + 1, month: 8, date: 1 })
    if (periodFilter !== 'season') {
      if (Number(periodFilter) < 8) {
        fromDate = set(new Date(), { year: year + 1, month: Number(periodFilter) - 1, date: 1 })
        toDate = set(new Date(), { year: year + 1, month: Number(periodFilter), date: 1 })
      } else {
        fromDate = set(new Date(), { year, month: Number(periodFilter) - 1, date: 1 })
        toDate = set(new Date(), { year, month: Number(periodFilter), date: 1 })
      }
    }

    const filter = {
      club: clubsFilter,
      team: teamsFilter,
      user: userFilter,
      from: format(fromDate, 'MM/dd/yyyy'),
      to: format(toDate, 'MM/dd/yyyy')
    }
    for (const key in filter) {
      if (!filter[key]) {
        delete filter[key]
      }
    }
    if (filter.user) {
      delete filter.team
      delete filter.club
    }
    if (filter.team && Array.isArray(filter.team) && filter.team.length > 0) {
      delete filter.club
    }
    api.stats.getStats(filter)
      .then((statistics) => {
        if (isMounted()) {
          statistics.activityTypes = statistics.activityTypes.sort((a, b) => {
            if (a._id > b._id) {
              return 1
            }
            if (a._id < b._id) {
              return -1
            }
            return 0
          })
          setStatistics(statistics)
        }
      })
  }, [filterState, isAdmin, isMounted])

  // get clubs when role is admin, otherwise get teams
  useEffect(() => {
    if (isAdmin) {
      api.club.getClubs()
        .then(setClubs)
    } else {
      api.club.getTeams(null, { includeNonActive: true })
        .then(setTeams)
    }
  }, [isAdmin])

  // update teams when a club is chosen
  useEffect(() => {
    const getTeams = async (clubs) => {
      const clubsTeams = []
      for (const clubId of filterState.clubsFilter) {
        const clubTeams = await api.club.getTeams(clubId, { includeNonActive: true })
        clubsTeams.push(...clubTeams)
      }
      setTeams(clubsTeams)
      const filteredTeamFilter = []
      for (const filterTeam of filterState.teamsFilter) {
        if (clubsTeams.findIndex((id) => id === filterTeam)) {
          filteredTeamFilter.push(filterTeam)
        }
      }
      setTeamsFilter(filteredTeamFilter)
    }
    if (isAdmin) {
      if (filterState.clubsFilter) {
        if (filterState.clubsFilter !== (prevFilterState || {}).clubsFilter) {
          getTeams(filterState.clubsFilter)
        }
      } else {
        if (teams.length) {
          setTeams([])
        }
        if (prevClubs && filterState.teamsFilter) {
          setTeamsFilter(null)
        }
      }
    }
  }, [
    isAdmin,
    filterState.clubsFilter,
    prevFilterState,
    filterState.teamsFilter,
    prevClubs,
    setTeamsFilter,
    teams.length
  ])

  useEffect(() => {
    if (filterState.teamsFilter) {
      if (!isAdmin && filterState !== prevFilterState) {
        const filter = {
          team: filterState.teamsFilter,
          role: 'player'
        }
        api.user.getUsers(filter)
          .then(setUsers)
      }
    } else {
      if (users.length) {
        setUsers([])
      }
      if (prevTeams && filterState.userFilter) {
        setUserFilter(null)
      }
    }
  }, [
    filterState,
    prevFilterState,
    filterState.teamsFilter,
    filterState.userFilter,
    isAdmin,
    prevTeams,
    setUserFilter,
    users.length
  ])

  useEffect(() => {
    if (prevFilterState !== filterState) {
      filterStatistics()
      setQueryParams(filterState)
      sessionStorage.setItem('statisticsPageFilterState', JSON.stringify({ role: me.role, ...filterState }))
    }
  }, [
    prevFilterState,
    filterState,
    filterStatistics,
    me.role
  ])

  // parse statistics into data for the graphs
  useEffect(() => {
    if (statistics) {
      const parsedStatistics = getParsedStatistics(statistics)
      setParsedStatistics(parsedStatistics)
    }
  }, [statistics])

  const clearFilters = () => {
    setFilterState(defaultFilter)
  }

  const firstSeason = parseInt(seasonRange.first)
  const lastSeason = parseInt(seasonRange.last)
  const seasonsCount = lastSeason - firstSeason + 1

  const seasonsOptions = new Array(seasonsCount)
    .fill(null)
    .map((nullValue, index) => {
      const year = index + firstSeason
      const nextYear = year + 1
      return {
        id: String(year),
        value: `${year} - ${nextYear}`
      }
    })

  const periodOptions = [
    {
      id: 'season',
      value: i18next.t('common:season')
    },
    {
      id: '9',
      value: i18next.t('common:september')
    },
    {
      id: '10',
      value: i18next.t('common:oktober')
    },
    {
      id: '11',
      value: i18next.t('common:november')
    },
    {
      id: '12',
      value: i18next.t('common:december')
    },
    {
      id: '1',
      value: i18next.t('common:january')
    },
    {
      id: '2',
      value: i18next.t('common:february')
    },
    {
      id: '3',
      value: i18next.t('common:march')
    },
    {
      id: '4',
      value: i18next.t('common:april')
    },
    {
      id: '5',
      value: i18next.t('common:may')
    },
    {
      id: '6',
      value: i18next.t('common:june')
    },
    {
      id: '7',
      value: i18next.t('common:july')
    },
    {
      id: '8',
      value: i18next.t('common:august')
    }
  ]

  const RenderSurface = (type) => {
    const onResize = (width, height) => {
      setPageWidth(width)
    }

    useResizeDetector({
      handleWidth: true,
      onResize
    })

    switch (type) {
      case 'activitiesCount': {
        return (
          <Surface className={classes.surface}>
            <div className={classes.title}>{i18next.t('common:activities_count')}</div>
            <SurfaceBody>
              <span className={classes.number}>{parsedStatistics.totalActivities}</span>
            </SurfaceBody>
          </Surface>
        )
      }
      case 'attendeesCount': {
        return (
          <Surface className={classes.surface}>
            <div className={classes.title}>{i18next.t('common:attendees_count')}</div>
            <SurfaceBody>
              <span className={classes.number}>{parsedStatistics.uniqueAttendeesCount}</span>
            </SurfaceBody>
          </Surface>
        )
      }
      case 'activitiesAverage': {
        return (
          <Surface className={classes.surface}>
            <div className={classes.title}>
              {i18next.t('common:activities_average')}
            </div>
            <SurfaceBody>
              <span
                className={classes.number}
              >{parsedStatistics.averageEventsPerAttendee}
              </span> {i18next.t('common:per_attendee')}
            </SurfaceBody>
          </Surface>
        )
      }
      case 'attendeesAverage': {
        return (
          <Surface className={classes.surface}>
            <div className={classes.title}>{i18next.t('common:attendees_average')}</div>
            <SurfaceBody>
              <span
                className={classes.number}
              >{parsedStatistics.averageAttendeesPerEvent}
              </span> {i18next.t('common:per_activity')}
            </SurfaceBody>
          </Surface>
        )
      }
      case 'regularAttendees': {
        return (
          <Surface className={classes.surface}>
            <div className={classes.title}>
              <div>{i18next.t('common:regular_attendees')}</div>
              <div>
                <Toggle
                  value={regularAttendeesView}
                  options={viewTypes}
                  onChange={({ target }) => setRegularAttendeesView(target.value)}
                />
              </div>
            </div>
            <SurfaceBody>
              {
                regularAttendeesView === 'chart'
                  ? (
                    <RegularAttendeesChart data={parsedStatistics.regularAttendees} key={pageWidth} />)
                  : (
                    <RegularAttendeesTable data={parsedStatistics.regularAttendees} />)
              }
            </SurfaceBody>
          </Surface>
        )
      }
      case 'activityTypes': {
        return (
          <Surface className={classes.surface}>
            <div className={classes.title}>
              <div>{i18next.t('common:activity_types')}</div>
              <div>
                <Toggle
                  value={activityTypeView}
                  options={viewTypes}
                  onChange={({ target }) => setActivityTypeView(target.value)}
                />
              </div>
            </div>
            <SurfaceBody style={{ paddingBottom: 0 }}>
              {
                activityTypeView === 'chart'
                  ? (
                    <ActivityTypesChart data={parsedStatistics.activitiesPerType} key={pageWidth} />)
                  : (
                    <ActivityTypesTable data={parsedStatistics.activitiesPerType} />)
              }
            </SurfaceBody>
          </Surface>
        )
      }
      case 'activitiesPerMonth': {
        return (
          <Surface className={classes.surface}>
            <div className={classes.title}>{i18next.t('common:activities')}</div>
            <SurfaceBody>
              <ActivitiesPerMonthChart data={parsedStatistics.activitiesPerMonthPerType} key={pageWidth} />
            </SurfaceBody>
          </Surface>
        )
      }
      case 'activityAttendees': {
        return (
          <Surface className={classes.surface}>
            <div className={classes.title}>{i18next.t('common:attendees_per_activity')}</div>
            <SurfaceBody>
              <ActivityAttendeesChart data={parsedStatistics.activityAttendees} key={pageWidth} />
            </SurfaceBody>
          </Surface>
        )
      }
      case 'gender': {
        return (
          <Surface className={classes.surface}>
            <div className={classes.title}>
              <div>{i18next.t('common:gender')}</div>
              <div>
                <Toggle
                  value={genderView}
                  options={viewTypes}
                  onChange={({ target }) => setGenderView(target.value)}
                />
              </div>
            </div>
            <SurfaceBody>
              {
                genderView === 'chart'
                  ? (
                    <GendersChart data={parsedStatistics.genders} key={pageWidth} />)
                  : (
                    <GendersTable data={parsedStatistics.genders} />)
              }
            </SurfaceBody>
          </Surface>
        )
      }
      case 'ages': {
        return (
          <Surface className={classes.surface}>
            <div className={classes.title}>
              <div>{i18next.t('common:ages')}</div>
              <div>
                <Toggle
                  value={ageGroupsView}
                  options={viewTypes}
                  onChange={({ target }) => setAgeGroupsView(target.value)}
                />
              </div>
            </div>
            <SurfaceBody>
              {
                ageGroupsView === 'chart'
                  ? (
                    <AgeGroupsChart data={parsedStatistics.ageGroups} key={pageWidth} />)
                  : (
                    <AgeGroupsTable data={parsedStatistics.ageGroups} />)
              }
            </SurfaceBody>
          </Surface>
        )
      }
      default: {
        return null
      }
    }
  }

  const ActivitiesCount = () => RenderSurface('activitiesCount')
  const AttendeesCount = () => RenderSurface('attendeesCount')
  const ActivitiesAverage = () => RenderSurface('activitiesAverage')
  const AttendeesAverage = () => RenderSurface('attendeesAverage')
  const RegularAttendees = () => RenderSurface('regularAttendees')
  const ActivityTypes = () => RenderSurface('activityTypes')
  const ActivitiesPerMonth = () => RenderSurface('activitiesPerMonth')
  const ActivityAttendees = () => RenderSurface('activityAttendees')
  const Gender = () => RenderSurface('gender')
  const Ages = () => RenderSurface('ages')

  const renderSeasonFilter = () => {
    const option = seasonsOptions.find(({ id }) => id === filterState.seasonFilter)
    if (!option) {
      return null
    }
    return (
      <div>
        {i18next.t('common:season')}: {option.name}
      </div>
    )
  }

  const renderClubFilter = () => {
    if (!isAdmin) {
      return null
    }
    const option = clubs.find(({ _id }) => _id === filterState.clubsFilter)
    if (!option) {
      return null
    }
    return (
      <div>
        {i18next.t('common:club')}: {option.name}
      </div>
    )
  }

  const renderTeamFilter = () => {
    const option = teams.find(({ _id }) => _id === filterState.teamsFilter)
    if (!option) {
      return null
    }
    return (
      <div>
        {i18next.t('common:team')}: {option.name}
      </div>
    )
  }

  const renderMemberFilter = () => {
    if (isAdmin) {
      return null
    }
    const option = users.find(({ _id }) => _id === filterState.userFilter)
    if (!option) {
      return null
    }
    return (
      <div>
        {i18next.t('common:attendee')}: {option.name}
      </div>
    )
  }

  const renderPrintPortal = () => createPortal(
    <div className='print-portal'>
      <Page className={classes.root}>
        <div className={classes.body}>
          <div className='print-portal-inner'>
            <div className={classes.flex} style={{ width: '100%' }}>
              <div className={[classes.flex1, classes.flex].join(' ')}>
                {renderSeasonFilter()}
                {renderClubFilter()}
                {renderTeamFilter()}
                {renderMemberFilter()}
              </div>
              <div>
                {format(new Date(), 'dd/MM/yyyy')}
              </div>
            </div>
            <div className={classes.flex} style={{ width: '100%' }}>
              <div className={classes.flex1}>
                <ActivitiesCount />
              </div>
              {
                Boolean(!filterState.userFilter) && (
                  <>
                    <div className={classes.flex1}>
                      <AttendeesCount />
                    </div>
                    <div className={classes.flex1}>
                      <ActivitiesAverage />
                    </div>
                    <div className={classes.flex1}>
                      <AttendeesAverage />
                    </div>
                  </>
                )
              }
            </div>
            {
              Boolean(!filterState.userFilter) && (
                <RegularAttendees />
              )
            }
            <ActivityTypes />
            <ActivitiesPerMonth />
            {
              Boolean(!filterState.userFilter) && (
                <div className={classes.flex}>
                  <Gender />
                  <div className={classes.flex1}>
                    <Ages />
                  </div>
                </div>
              )
            }
          </div>
        </div>
      </Page>
    </div>,
    document.body
  )

  return (
    <>
      <Page
        className={classes.root}
        header={
          <div className={classes.header}>
            <Helmet>
              <title>{i18next.t('common:younited')}: {i18next.t('common:statistics')}</title>
              <style>
                {`
                    .print-portal {
                      visibility: hidden;
                      position: absolute;
                      right: 100%;
                      bottom: 100%;
                    }
                    @media print {
                      #root > * {
                        display: none;
                      }

                      @page { size: auto;  margin: 0mm; }

                      body {
                        margin: 0;
                        padding: 0;
                      }

                      .print-portal {
                        display: block;
                        visibility: visible;
                        position: relative;
                        right: unset;
                        bottom: unset;
                      }

                      .print-portal-inner {
                        display: block;
                        position: absolute;
                        left: 0;
                        right: 0;
                        top: 0;
                        padding: 10px;
                      }
                      .print-portal-inner > * {
                        page-break-inside: avoid;
                        margin-top: 21px;
                      }
                    }
                `}
              </style>
            </Helmet>
            <div className={classes.filters}>
              <Select
                clearable={false}
                title={i18next.t('common:season')}
                value={filterState.seasonFilter}
                options={seasonsOptions}
                onChange={({ target }) => setSeasonFilter(target.value)}
              />
              <Select
                clearable={false}
                title={i18next.t('common:period')}
                value={filterState.periodFilter}
                options={periodOptions}
                onChange={({ target }) => setPeriodFilter(target.value)}
              />
              {
                Boolean(isAdmin && clubs.length) && (
                  <SelectMultiple
                    title={i18next.t('common:club')}
                    value={filterState.clubsFilter || []}
                    options={clubs.map(({ _id, name }) => ({ id: _id, value: name }))}
                    onChange={({ target }) => setClubsFilter(target.value)}
                    clearText={i18next.t('actions:clear_filter')}
                  />
                )
              }
              {
                Boolean(teams.length) && (
                  <SelectMultiple
                    title={i18next.t('common:team')}
                    value={filterState.teamsFilter}
                    options={teams.map(({ _id, name, active }) => ({
                      id: _id,
                      value: name,
                      displayValue: <span style={{ textDecoration: active ? 'initial' : 'line-through' }}>{name}</span>
                    }))}
                    onChange={({ target }) => setTeamsFilter(target.value)}
                    clearText={i18next.t('actions:clear_filter')}
                  />
                )
              }
              {
                Boolean(!isAdmin && users.length) && (
                  <Select
                    title={i18next.t('common:attendee')}
                    value={filterState.userFilter}
                    options={users.map(({ _id, name }) => ({ id: _id, value: name }))}
                    onChange={({ target }) => setUserFilter(target.value)}
                    clearText={i18next.t('actions:clear_filter')}
                    search
                  />
                )
              }
              <Button
                size='no-padding'
                theme='role'
                onClick={clearFilters}
              >
                {i18next.t('actions:clear_all_filters')}
              </Button>
            </div>
            <Button theme='primary' onClick={() => window.print()}>{i18next.t('actions:print')}</Button>
          </div>
        }
      >
        <div className={classes.body}>
          <div className={classes.grid}>
            <div className={classes.cols4}>
              <ActivitiesCount />
              {
                Boolean(!filterState.userFilter) && (
                  <>
                    <AttendeesCount />
                    <ActivitiesAverage />
                    <AttendeesAverage />
                  </>
                )
              }
            </div>
            {
              Boolean(!filterState.userFilter) && (
                <div className={classes.flex}>
                  <div style={{ flex: 0.75 }}>
                    <RegularAttendees />
                  </div>
                </div>
              )
            }
            <div className={classes.flex}>
              <ActivityTypes />
              <div className={classes.flex1}>
                <ActivitiesPerMonth />
              </div>
            </div>
            <div className={classes.flex}>
              <div className={classes.flex1}>
                <ActivityAttendees />
              </div>
            </div>
            {
              Boolean(!filterState.userFilter) && (
                <div className={classes.cols2}>
                  <Gender />
                  <Ages />
                </div>
              )
            }
          </div>
        </div>
        {renderPrintPortal()}
      </Page>
    </>
  )
}

export default withSeasonData(StatisticsPage)
