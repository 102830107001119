const supportedLanguages = ['fr', 'nl']

const trimLanguage = (language = '') => {
  return language.split('-')[0]
}

const getSupportedLanguage = () => {
  if (navigator.languages && navigator.languages.length) {
    return navigator.languages.find((navigatorLanguage) => {
      return supportedLanguages.includes(trimLanguage(navigatorLanguage))
    }) || 'nl'
  } else {
    const navigatorLanguage = navigator.userLanguage || navigator.language || navigator.browserLanguage
    return supportedLanguages.find((supportedLanguage) => supportedLanguage === trimLanguage(navigatorLanguage)) || 'nl'
  }
}

export default getSupportedLanguage
