import React from 'react'
import PropTypes from 'prop-types'
import api from '../../api'
import Surface from '../Surface/Surface'
import SurfaceBody from '../SurfaceBody/SurfaceBody'
import PopupButtons from '../PopupButtons/PopupButtons'
import Button from '../Button/Button'
import neutralizeEvent from '../../utils/neutralizeEvent'
import i18next from '../../translations'
import addMessage from '../../utils/addMessage'

const DeleteTeamForm = (props) => {
  const {
    clubId,
    clubName,
    teamId,
    teamName,
    onCancel,
    callback
  } = props

  const handleSubmit = (event) => {
    neutralizeEvent(event)
    api.club.deleteTeam(clubId, teamId)
      .then(() => {
        addMessage(i18next.t('messages:team_removed_from_club', { team: teamName, club: clubName }), { type: 'error' })
        callback()
      })
  }

  return (
    <form onSubmit={handleSubmit}>
      <Surface>
        <SurfaceBody>
          {i18next.t('messages:delete_team_confirm')}
        </SurfaceBody>
        <PopupButtons>
          <Button onClick={onCancel}>{i18next.t('actions:cancel')}</Button>
          <Button type='submit' theme='primary'>{i18next.t('actions:delete_team')}</Button>
        </PopupButtons>
      </Surface>
    </form>
  )
}

DeleteTeamForm.propTypes = {
  clubId: PropTypes.string.isRequired,
  teamId: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  callback: PropTypes.func.isRequired
}

export default DeleteTeamForm
