import React, { useState } from 'react'
import classes from './ActivitiesTable.module.scss'
import PropTypes from 'prop-types'
import i18next from '../../translations'
import activityTypeIcons from '../../definitions/activityTypeIcons'
import Table, { SortableTH } from '../Table/Table'
import Tag from '../Tag/Tag'
import Check from '../svg/Check'
import getActivityProgress from '../../utils/getActivityProgress'
import neutralizeEvent from '../../utils/neutralizeEvent'
import Waiting from '../svg/Waiting'
import { endOfDay, isBefore, format } from 'date-fns'
import getLocale from '../../utils/getLocale'

const ActivitiesTable = (props) => {
  const {
    activities: activitiesUnsorted = [],
    selectedActivityId,
    setSelectedActivityId,
    isAdmin
  } = props

  const [sort, setSort] = useState({
    field: 'date',
    direction: 'asc'
  })

  const activities = activitiesUnsorted.sort((a, b) => {
    const aValue = a[sort.field]
    const bValue = b[sort.field]
    const directionModifiers = {
      asc: 1,
      desc: -1
    }
    const directionModifier = directionModifiers[sort.direction]
    if (aValue > bValue) {
      return 1 * directionModifier
    }
    if (aValue < bValue) {
      return -1 * directionModifier
    }
    return 0
  })

  const handleRowClick = (_id) => (event) => {
    neutralizeEvent(event)
    setSelectedActivityId(_id)
  }
  const locale = getLocale()

  return (
    <div className={classes.root}>
      <Table>
        <thead>
        <tr>
          <th>{i18next.t('common:status')}</th>
          {
            !isAdmin && (
              <th>{i18next.t('common:activity')}</th>
            )
          }
          <th>{i18next.t('common:description')}</th>
          <SortableTH
            name='date'
            sort={sort}
            onChange={({ target }) => setSort(target.value)}
          >{i18next.t('common:date')}</SortableTH>
          {
            Boolean(isAdmin) && (
              <th>Clubs</th>
            )
          }
          <th>{isAdmin ? i18next.t('common:teams') : i18next.t('common:your_teams')}</th>
          <th>{isAdmin ? i18next.t('common:attendees') : i18next.t('common:your_attendees')}</th>
        </tr>
        </thead>
        <tbody>
        {
          activities.map((activity, index) => {
            const {
              _id,
              activityType,
              date: dateRaw,
              description,
              attendingTeams = []
            } = activity

            const date = new Date(dateRaw)
            const today = endOfDay(new Date())
            const isFutureActivity = isBefore(today, date)
            const isSelected = selectedActivityId === _id
            const classNames = ['clickable']
            if (isSelected) {
              classNames.push('selected')
            }

            const key = `ActivitiesTable__activities__${_id}__${index}`
            const teamsWithAttendeesPercentage = getActivityProgress(attendingTeams)
            const dateFormated = format(date, 'EEEEEE dd MMMM yyyy', { locale })
            const clubsCount = attendingTeams.reduce((clubsSet, { team = {} }) => {
              if (team.club) {
                clubsSet.add(team.club)
              }
              return clubsSet
            }, new Set()).size
            const attendingTeamsCount = attendingTeams.length
            const attendeesCount = attendingTeams.reduce((attendeesCount, { attendees = [], coaches = [] }) => {
              return attendeesCount + attendees.length + coaches.length
            }, 0)

            const activityTypeName = i18next.t(`activityTypes:${activityType}`)
            const activityTypeIcon = activityTypeIcons[activityType]

            const status = isFutureActivity
              ? <Waiting/>
              : teamsWithAttendeesPercentage >= 100
                ? <Check width='24' height='18' className={classes.checkIcon}/>
                : <div className={classes.percentage}>{String(teamsWithAttendeesPercentage)}%</div>

            return (
              <tr key={key} className={classNames.join(' ')} onClick={handleRowClick(_id)}>
                <td>{status}</td>
                {
                  !isAdmin && (
                    <td>
                      <Tag
                        text={activityTypeName}
                        theme={activityType}
                        icon={activityTypeIcon}
                      />
                    </td>
                  )
                }
                <td>{description || '-'}</td>
                <td>{dateFormated}</td>
                {
                  Boolean(isAdmin) && (
                    <td>{clubsCount || '-'}</td>
                  )
                }
                <td>{attendingTeamsCount || '-'}</td>
                <td>{attendeesCount || '-'}</td>
              </tr>
            )
          })
        }
        </tbody>
      </Table>
    </div>
  )
}

ActivitiesTable.propTypes = {
  activities: PropTypes.array.isRequired
}

export default ActivitiesTable
