import React from 'react'
import classes from './Tag.module.scss'
import PropTypes from 'prop-types'

const Tag = (props) => {
  const {
    theme,
    icon: Icon,
    text
  } = props

  const rootClassNames = [classes.root, classes[`theme-${theme}`]]

  return (
    <div className={rootClassNames.join(' ')}>
      <div className={classes.icon}>
        <Icon/>
      </div>
      <div className={classes.text}>{text}</div>
    </div>
  )
}

Tag.propTypes = {
  theme: PropTypes.oneOf(['younited', 'training', 'teambuilding', 'socialActivity', 'friendlyGame']).isRequired,
  icon: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired
}

export default Tag
