import sanitize from 'sanitize-filename'

const downloadCSV = (csv, title = 'download') => {
  const csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
  const csvURL = (navigator.msSaveBlob)
    ? navigator.msSaveBlob(csvData, 'download.csv')
    : window.URL.createObjectURL(csvData)
  const tempLink = document.createElement('a')
  tempLink.href = csvURL
  tempLink.setAttribute('download', `${sanitize(title)}.csv`)
  tempLink.click()
}

export default downloadCSV
