import React from 'react'
import classes from './ClubCard.module.scss'
import NavList from '../NavList/NavList'
import Surface from '../Surface/Surface'
import { NavLink } from 'react-router-dom'
import apiURL from '../../api/apiURL'

const ClubCard = (props) => {
  const {
    club
  } = props

  const clubColor = club.colors
    ? club.colors.primaryColor
    : '#000000'

  const clubNavList = [{
    content: <div className={classes.clubNavItem} style={{ color: clubColor }}>
      {club.name}
    </div>,
    to: `/clubs/${club._id}`
  }]

  const teamsNavList = club.teams.map((team) => ({
    content: team.name,
    to: `/clubs/${club._id}/${team._id}`
  }))

  return (
    <Surface>
      <div className={classes.root}>
        <div className={classes.left}>
          <div className={classes.logo}>
            {
              Boolean(club.hasLogo) && (
                <NavLink to={`/clubs/${club._id}`}>
                  <img
                    src={apiURL(`/club/${club._id}/logo`)}
                    alt='logo'
                    width={69}
                  />
                </NavLink>
              )
            }
          </div>
        </div>
        <div className={classes.right}>
          <NavList items={clubNavList}/>
          <NavList items={teamsNavList}/>
        </div>
      </div>
    </Surface>
  )
}

export default ClubCard
