import React from 'react'

const svg = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="17.817" viewBox="0 0 18 17.817" {...props}>
      <g transform="translate(-3.125 -3.601)">
        <path className="a" d="M50.449,3.6v8.822h8.914a8.767,8.767,0,0,0-2.64-6.211A9.2,9.2,0,0,0,50.449,3.6Z"
              transform="translate(-38.238)"/>
        <path className="a"
              d="M31.685,54.988H22.54l-6.27,5.225a8.333,8.333,0,0,0,2.756,1.916,8.876,8.876,0,0,0,3.751.811,8.673,8.673,0,0,0,6.154-2.494,8.637,8.637,0,0,0,2.755-5.456Z"
              transform="translate(-10.621 -41.521)"/>
        <path className="a"
              d="M11.159,12.957V3.9A8.762,8.762,0,0,0,5.648,6.631a8.508,8.508,0,0,0-2.523,6.094,8.02,8.02,0,0,0,.588,3.134,8.475,8.475,0,0,0,1.231,2.264Z"
              transform="translate(0 -0.244)"/>
      </g>
    </svg>
  )
}

export default svg
