import React, { useEffect, useState } from 'react'
import i18next from '../../translations'
import Input from '../Input/Input'
import api from '../../api'
import Label from '../Label/Label'
import Button from '../Button/Button'
import Surface from '../Surface/Surface'
import PopupButtons from '../PopupButtons/PopupButtons'
import SurfaceBody from '../SurfaceBody/SurfaceBody'
import SearchSelect from '../SearchSelect/SearchSelect'
import neutralizeEvent from '../../utils/neutralizeEvent'
import addMessage from '../../utils/addMessage'
import withError from '../../HOC/withError'

const CreateCoachForm = (props) => {
  const {
    clubId,
    teamId,
    teamName,
    coaches: currentCoaches,
    callback,
    setError
  } = props

  const [createNew, setCreateNew] = useState(true)
  const [name, setName] = useState('')
  const [birthYear, setBirthYear] = useState('')
  const [email, setEmail] = useState('')
  const [users, setUsers] = useState([])
  const [coach, setCoach] = useState(null)

  useEffect(() => {
    if (createNew) {
      setUsers([])
    } else {
      const filter = {
        club: clubId,
        role: 'coach,clubadmin'
      }
      api.user.getUsers(filter)
        .then((coaches) => {
          return coaches.filter((coach) => {
            return !currentCoaches.some((currentCoach) => {
              return currentCoach._id === coach._id
            })
          })
        })
        .then(setUsers)
    }
  }, [clubId, createNew, currentCoaches])

  const handleSubmit = (event) => {
    neutralizeEvent(event)
    if (createNew) {
      const user = {
        name,
        birthYear,
        email,
        role: 'coach',
        club: clubId,
        teams: [teamId]
      }
      api.user.createUser(user)
        .then(() => {
          addMessage(i18next.t('messages:name_added_as_coach_to_team', { name, team: teamName }), { type: 'success' })
          callback()
        })
        .catch(({ message }) => {
          setError(i18next.t(`errors:${message}`))
        })
    } else {
      api.user.addUserToTeam(coach, teamId)
        .then(() => {
          const newCoach = users.find(({ _id }) => _id === coach)
          addMessage(i18next.t('messages:name_added_as_coach_to_team', { name: newCoach.name, team: teamName }), { type: 'success' })
          callback()
        })
    }
  }

  return createNew
    ? (
      <div>
        <form onSubmit={handleSubmit}>
          <Surface>
            <SurfaceBody>
              <Label value={i18next.t('common:name')}>
                <Input
                  value={name}
                  onChange={({ target }) => setName(target.value)}
                  width='100%'
                  required
                />
              </Label>
              <Label value={i18next.t('common:birthYear')}>
                <Input
                  type='number'
                  value={birthYear}
                  onChange={({ target }) => setBirthYear(target.value)}
                  width='100%'
                  min={1900}
                  required
                />
              </Label>
              <Label value={i18next.t('common:email')}>
                <Input
                  type='email'
                  value={email}
                  onChange={({ target }) => setEmail(target.value)}
                  width='100%'
                />
              </Label>
              <PopupButtons padded={false}>
                <Button type='submit' theme='primary'>{i18next.t('actions:invite_and_add')}</Button>
              </PopupButtons>
            </SurfaceBody>
            <SurfaceBody>
              <PopupButtons padded={false} align='center'>
                <Button onClick={() => setCreateNew(false)}>
                  {i18next.t('actions:add_existing_coach')}
                </Button>
              </PopupButtons>
            </SurfaceBody>
          </Surface>
        </form>
      </div>
      )
    : (
      <div>
        <form onSubmit={handleSubmit}>
          <Surface>
            <SurfaceBody>
              <Label value={i18next.t('actions:search')}>
                <SearchSelect
                  value={coach}
                  options={users.map(({ _id, name }) => ({ id: _id, value: name }))}
                  onChange={({ target }) => setCoach(target.value)}
                />
              </Label>
              <PopupButtons padded={false}>
                <Button type='submit' theme='primary'>{i18next.t('actions:add')}</Button>
              </PopupButtons>
            </SurfaceBody>
            <SurfaceBody>
              <PopupButtons padded={false} align='center'>
                <Button onClick={() => setCreateNew(true)}>
                  {i18next.t('actions:invite_new_coach')}
                </Button>
              </PopupButtons>
            </SurfaceBody>
          </Surface>
        </form>
      </div>
      )
}

export default withError(CreateCoachForm)
