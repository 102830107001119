import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useMemo } from 'react'
import { setMe as setMeAction } from '../redux/appState/actions'

const useMe = () => {
  const me = useSelector(({ appState }) => appState.me)
  const dispatch = useDispatch()
  const setMe = useCallback((me) => {
    dispatch(setMeAction(me))
  }, [dispatch])
  const isAdmin = useMemo(() => {
    return me?.role === 'admin'
  }, [me?.role])

  const isClubAdmin = useMemo(() => {
    return me?.role === 'clubadmin'
  }, [me?.role])

  const isCoach = useMemo(() => {
    return me?.role === 'coach'
  }, [me?.role])

  return {
    me,
    setMe,
    isAdmin,
    isClubAdmin,
    isCoach
  }
}

export default useMe
