import React from 'react'

const svg = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="19.173" height="16" viewBox="0 0 19.173 16" {...props}>
      <path className="a"
            d="M13.825,16a.837.837,0,0,1-.787-.888l.283-5.144a1.38,1.38,0,0,0,.262-.1,1.291,1.291,0,0,0,.686-.767,1.307,1.307,0,0,0-.061-1.029L12.876,5.286a2.972,2.972,0,0,0-.141-.263,2.969,2.969,0,0,1,5.044.524L19.11,8.332a.756.756,0,0,1-.345.969.814.814,0,0,1-.3.06.7.7,0,0,1-.645-.4l-1.089-2.3.464,8.452a.823.823,0,0,1-.787.888h-.04a.84.84,0,0,1-.847-.787l-.283-5.144h-.242l-.283,5.144a.835.835,0,0,1-.848.787ZM2.789,15.978A.837.837,0,0,1,2,15.09l.444-8.453-1.089,2.3a.708.708,0,0,1-.948.343.707.707,0,0,1-.343-.948L1.438,5.547a2.969,2.969,0,0,1,5.043-.524,1.762,1.762,0,0,0-.14.263L5.009,8.07A1.3,1.3,0,0,0,4.948,9.1a1.353,1.353,0,0,0,.685.767,1.021,1.021,0,0,0,.263.1l.282,5.124a.825.825,0,0,1-.787.888h-.04a.843.843,0,0,1-.848-.787l-.282-5.145H3.939l-.263,5.145a.835.835,0,0,1-.848.787Zm5.506,0a.835.835,0,0,1-.787-.887l.444-8.453-1.089,2.3a.713.713,0,1,1-1.291-.605L6.9,5.548a2.972,2.972,0,0,1,5.366,0L13.6,8.332a.726.726,0,0,1-.306.948.819.819,0,0,1-.3.061.7.7,0,0,1-.645-.4l-1.089-2.3L11.7,15.09a.822.822,0,0,1-.786.887h-.041a.841.841,0,0,1-.847-.786l-.283-5.145H9.464l-.283,5.145a.833.833,0,0,1-.846.787ZM13.361,1.735A1.734,1.734,0,1,1,15.1,3.471,1.735,1.735,0,0,1,13.361,1.735Zm-5.508,0A1.735,1.735,0,1,1,9.588,3.471,1.735,1.735,0,0,1,7.853,1.735Zm-5.507,0A1.735,1.735,0,1,1,4.081,3.471,1.735,1.735,0,0,1,2.346,1.735Z"
            transform="translate(0)"/>
    </svg>
  )
}

export default svg
