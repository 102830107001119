import React, { useState, useEffect, useReducer, useCallback } from 'react'
import classes from './ActivitiesPage.module.scss'
import i18next from '../../translations'
import actions from './actions'
import filterReducer from './reducer'
import api from '../../api'
import { Helmet } from 'react-helmet'
import Button from '../../components/Button/Button'
import Select from '../../components/Select/Select'
import SelectMultiple from '../../components/SelectMultiple/SelectMultiple'
import Input from '../../components/Input/Input'
import InputDelay from '../../components/InputDelay/InputDelay'
import SelectRange from '../../components/SelectRange/SelectRange'
import Page from '../Page/Page'
import ActivitiesTable from '../../components/ActivitiesTable/ActivitiesTable'
import Popup from '../../components/Popup/Popup'
import CreateActivityForm from '../../components/CreateActivityForm/CreateActivityForm'
import ActivityInfoSidePanel from '../../components/ActivityInfoSidePanel/ActivityInfoSidePanel'
import SidePanel from '../../components/SidePanel/SidePanel'
import setQueryParams from '../../utils/setQueryParams'
import getQueryParams from '../../utils/getQueryParams'
import withSeasonData from '../../HOC/withSeasonData'
import getMonthOptions from '../../utils/getMonthOptions'
import addMessage from '../../utils/addMessage'
import { parse, setMonth, setYear, isBefore, isEqual, format } from 'date-fns'
import useMe from '../../customHooks/useMe'

const ActivitiesPage = (props) => {
  const {
    seasonRange,
    currentSeason
  } = props

  const { me, isAdmin, isClubAdmin } = useMe()

  const params = getQueryParams()
  const filterStateFromParams = Object.assign({}, params)
  for (const filterName in filterStateFromParams) {
    const value = filterStateFromParams[filterName]
    if (typeof value !== 'object') {
      filterStateFromParams[filterName] = String(value)
    }
  }

  const defaultFilter = {
    searchString: '',
    clubsFilter: [],
    teamsFilter: [],
    activityTypesFilter: [],
    statusFilter: undefined,
    seasonFilter: currentSeason,
    monthsFilter: undefined,
    userFilter: undefined
  }

  const defaultFilterWithParams = {
    ...defaultFilter,
    ...filterStateFromParams
  }

  let savedFilter
  try {
    if (!Object.keys(filterStateFromParams).length) {
      savedFilter = JSON.parse(sessionStorage.getItem('activitiesPageFilterState'))
    }
  } catch (error) {}

  const [activities, setActivities] = useState([])
  const [clubs, setClubs] = useState([])
  const [teams, setTeams] = useState([])
  const [users, setUsers] = useState([])
  const [activityTypes, setActivityTypes] = useState([])
  const [showCreateActivityModal, setShowCreateActivityModal] = useState(false)
  const [selectedActivityId, setSelectedActivityId] = useState(null)
  const [sidePanelProps, setSidePanelProps] = useState(null)

  // filters
  const [filterState, dispatchFilter] = useReducer(filterReducer, savedFilter || defaultFilterWithParams)
  const {
    setFilterState,
    setSearchString,
    setTeamsFilter,
    setClubsFilter,
    setActivityTypesFilter,
    setStatusFilter,
    setSeasonFilter,
    setMonthsFilter,
    setUserFilter
  } = actions(dispatchFilter)

  useEffect(() => {
    if (isAdmin) {
      api.club.getClubs()
        .then(setClubs)
    } else {
      api.club.getTeams(null, { includeNonActive: true })
        .then(setTeams)
    }
    api.activity.getActivityTypes()
      .then(setActivityTypes)
  }, [isAdmin])

  useEffect(() => {
    if (isAdmin || !filterState.teamsFilter.length) {
      setUsers([])
      return
    }
    const filter = {
      team: filterState.teamsFilter.join(','),
      role: 'player'
    }
    api.user.getUsers(filter)
      .then(setUsers)
  }, [filterState.teamsFilter, isAdmin])

  const createActivityFormCallback = (activity) => {
    filterActivities()
    setShowCreateActivityModal(false)
    setSelectedActivityId(activity._id)
    addMessage(i18next.t('common:x_added', { x: activity.description }), { type: 'success' })
  }

  const filterActivities = useCallback(() => {
    const year = Number(filterState.seasonFilter)
    let fromDate = parse(`01/09/${year}`, 'dd/MM/yyyy', new Date())
    let toDate = parse(`01/09/${year + 1}`, 'dd/MM/yyyy', new Date())
    if (filterState.monthsFilter) {
      const fromMonth = Number(filterState.monthsFilter.from)
      const toMonth = Number(filterState.monthsFilter.to)
      fromDate = setMonth(fromDate, fromMonth - 1)
      fromDate = setYear(fromDate, fromMonth < 9 ? year + 1 : year)
      toDate = setMonth(toDate, toMonth)
      toDate = setYear(toDate, year)
      if (isBefore(toDate, fromDate) || isEqual(toDate, fromDate)) {
        toDate = setYear(toDate, year + 1)
      }
    }
    const filter = {
      searchString: filterState.searchString,
      clubs: isAdmin
        ? filterState.clubsFilter.join(',')
        : undefined,
      teams: isAdmin
        ? undefined
        : isClubAdmin
          ? filterState.teamsFilter.join(',')
          : filterState.teamsFilter.join(',') || me.teams.map(({ _id }) => _id).join(','),
      user: filterState.userFilter,
      activityTypes: isAdmin
        ? 'younited'
        : filterState.activityTypesFilter.join(','),
      status: filterState.statusFilter,
      from: format(fromDate, 'MM/dd/yyyy'),
      to: format(toDate, 'MM/dd/yyyy')
    }
    for (const key in filter) {
      if (!filter[key]) {
        delete filter[key]
      }
    }

    api.activity.getActivities(filter)
      .then(setActivities)
  }, [
    filterState.activityTypesFilter,
    filterState.clubsFilter,
    filterState.monthsFilter,
    filterState.searchString,
    filterState.seasonFilter,
    filterState.statusFilter,
    filterState.teamsFilter,
    filterState.userFilter,
    isAdmin,
    isClubAdmin,
    me.teams
  ])

  useEffect(() => {
    filterActivities()
    setQueryParams(filterState)
    sessionStorage.setItem('activitiesPageFilterState', JSON.stringify(filterState))
  }, [filterActivities, filterState])

  useEffect(() => {
    if (selectedActivityId) {
      setSidePanelProps({
        onClose: () => setSelectedActivityId(null),
        component: ActivityInfoSidePanel,
        activityId: selectedActivityId,
        filterActivitiesCallback: filterActivities,
        setSelectedActivityId: setSelectedActivityId
      })
    } else {
      setSidePanelProps(null)
    }
  }, [filterActivities, selectedActivityId])

  const clearFilters = () => {
    setFilterState(defaultFilter)
  }

  const clubsOptions = clubs.map((club) => {
    return {
      id: club._id,
      value: club.name
    }
  })

  const teamsOptions = teams.map((team) => {
    return {
      id: team._id,
      value: team.name,
      displayValue: <div style={{ textDecoration: team.active ? 'initial' : 'line-through' }}>{team.name}</div>
    }
  })

  const usersOptions = users.map((user) => {
    return {
      id: user._id,
      value: user.name,
      displayValue: <div style={{ textDecoration: !user.deleted ? 'initial' : 'line-through' }}>{user.name}</div>
    }
  })

  const activityTypesOptions = activityTypes.map((activityType) => {
    return {
      id: activityType.name,
      value: i18next.t(`activityTypes:${activityType.name}`)
    }
  })

  const statusOptions = [
    {
      id: 'incomplete',
      value: i18next.t('common:partially_filled_out')
    },
    {
      id: 'complete',
      value: i18next.t('common:completely_filled_out')
    }
  ]

  const firstSeason = parseInt(seasonRange.first)
  const lastSeason = parseInt(seasonRange.last)
  const seasonsCount = lastSeason - firstSeason + 1

  const seasonsOptions = new Array(seasonsCount)
    .fill(null)
    .map((nullValue, index) => {
      const year = index + firstSeason
      const nextYear = year + 1
      return {
        id: String(year),
        value: `${year} - ${nextYear}`
      }
    })

  const monthsOptions = getMonthOptions()

  const handleChangeTeamsFilter = ({ target }) => {
    const user = users.find((user) => String(user._id) === filterState.userFilter)
    const clearUserFilter = Boolean(user && !target.value.includes(user.teams[0]))
    setTeamsFilter(target.value, clearUserFilter)
  }

  return (
    <Page
      className={classes.root}
      header={
        <div className={classes.header}>
          <Helmet>
            <title>Younited: Activiteiten</title>
          </Helmet>
          {
            Boolean(sidePanelProps) && (
              <SidePanel {...sidePanelProps}/>
            )
          }
          {
            Boolean(showCreateActivityModal) && (
              <Popup
                title={i18next.t('actions:add_activity')}
                onClose={() => setShowCreateActivityModal(false)}
                body={
                  <CreateActivityForm
                    callback={createActivityFormCallback}
                    onCancel={() => setShowCreateActivityModal(false)}
                  />
                }
              />
            )
          }
          <div className={classes.filters}>
            <InputDelay
              component={Input}
              placeholder={i18next.t('actions:search')}
              value={filterState.searchString}
              onChange={({ target }) => setSearchString(target.value)}
              search
            />
            {
              isAdmin
                ? (
                  <SelectMultiple
                    title={i18next.t('common:club')}
                    value={filterState.clubsFilter}
                    options={clubsOptions}
                    onChange={({ target }) => setClubsFilter(target.value)}
                    clearText={i18next.t('actions:clear_filter')}
                  />
                  )
                : (
                  <>
                    <SelectMultiple
                      title={i18next.t('common:team')}
                      value={filterState.teamsFilter}
                      options={teamsOptions}
                      onChange={handleChangeTeamsFilter}
                      clearText={i18next.t('actions:clear_filter')}
                    />
                    {
                      Boolean(usersOptions.length) && (
                        <Select
                          title={i18next.t('common:attendee')}
                          value={filterState.userFilter}
                          options={usersOptions}
                          onChange={({ target }) => setUserFilter(target.value)}
                          clearText={i18next.t('actions:clear_filter')}
                          search
                        />
                      )
                    }
                  </>
                  )
            }
            {
              !isAdmin && (
                <SelectMultiple
                  title={i18next.t('common:activity')}
                  value={filterState.activityTypesFilter}
                  options={activityTypesOptions}
                  onChange={({ target }) => setActivityTypesFilter(target.value)}
                  clearText={i18next.t('actions:clear_filter')}
                />
              )
            }
            <Select
              title={i18next.t('common:status')}
              value={filterState.statusFilter}
              options={statusOptions}
              onChange={({ target }) => setStatusFilter(target.value)}
              clearText={i18next.t('actions:clear_filter')}
            />
            <Select
              clearable={false}
              title={i18next.t('common:season')}
              value={filterState.seasonFilter}
              options={seasonsOptions}
              onChange={({ target }) => setSeasonFilter(target.value)}
            />
            <SelectRange
              title={i18next.t('common:month')}
              value={filterState.monthsFilter}
              options={monthsOptions}
              onChange={({ target }) => setMonthsFilter(target.value)}
              clearText={i18next.t('actions:clear_filter')}
            />
            <Button
              size='no-padding'
              theme='role'
              onClick={clearFilters}
            >
              {i18next.t('actions:clear_all_filters')}
            </Button>
          </div>
          {
            Boolean(isAdmin) && (
              <Button
                theme='primary'
                onClick={() => setShowCreateActivityModal(true)}
              >
                {i18next.t('actions:add_activity')}
              </Button>
            )
          }
        </div>
      }
    >
      <ActivitiesTable
        isAdmin={isAdmin}
        activities={activities}
        selectedActivityId={selectedActivityId}
        setSelectedActivityId={setSelectedActivityId}
      />
    </Page>
  )
}

export default withSeasonData(ActivitiesPage)
