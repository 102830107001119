import React from 'react'

const svg = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="12.444" viewBox="0 0 16 12.444" {...props}>
      <g transform="translate(0 0)">
        <path className="a" d="M12.5,20.832h16v1.933h-16Z" transform="translate(-12.5 -20.832)"/>
        <path className="a" d="M12.5,45.832h16v1.933h-16Z" transform="translate(-12.5 -40.576)"/>
        <path className="a" d="M12.5,70.832h9.667v1.933H12.5Z" transform="translate(-12.5 -60.321)"/>
      </g>
    </svg>
  )
}

export default svg
