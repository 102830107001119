import React, { useState } from 'react'
import classes from './PasswordResetPage.module.scss'
import i18next from '../../translations'
import { Helmet } from 'react-helmet'
import api from '../../api'
import Surface from '../../components/Surface/Surface'
import SurfaceBody from '../../components/SurfaceBody/SurfaceBody'
import Label from '../../components/Label/Label'
import Input from '../../components/Input/Input'
import Button from '../../components/Button/Button'
import getQueryParams from '../../utils/getQueryParams'
import neutralizeEvent from '../../utils/neutralizeEvent'
import withError from '../../HOC/withError'

const PasswordResetPage = (props) => {
  const {
    setError
  } = props

  const params = getQueryParams()

  const [email, setEmail] = useState(params.email || '')
  const [success, setSuccess] = useState(false)

  const handleSubmit = (event) => {
    neutralizeEvent(event)
    api.auth.requestPasswordReset(email)
      .then(() => {
        setSuccess(true)
      })
      .catch(() => {
        setError(i18next.t('errors:invalid_email'))
      })
  }

  return (
    <div className={classes.root}>
      <Helmet>
        <title>{i18next.t('common:younited')}: {i18next.t('common:request_new_password')}</title>
      </Helmet>
      {
        success
          ? (
            <Surface>
              <SurfaceBody>
                <div className={classes.title}>{i18next.t('common:request_new_password')}</div>
              </SurfaceBody>
              <SurfaceBody>
                {i18next.t('messages:request_new_password_success')}
              </SurfaceBody>
            </Surface>
            )
          : (
            <form onSubmit={handleSubmit}>
              <Surface>
                <SurfaceBody>
                  <div className={classes.title}>{i18next.t('common:request_new_password')}</div>
                </SurfaceBody>
                <SurfaceBody>
                  <Label value={i18next.t('common:email')}>
                    <Input
                      type='email'
                      value={email}
                      onChange={({ target }) => setEmail(target.value)}
                      width='100%'
                      required
                    />
                  </Label>
                </SurfaceBody>
                <SurfaceBody className={classes.buttonsContainer}>
                  <Button theme='primary' type='submit'>
                    {i18next.t('common:request_new_password')}
                  </Button>
                </SurfaceBody>
              </Surface>
            </form>
            )
      }

      <div className={classes.logoContainer}>
        <img src="/assets/younited-analytics-logo.svg" alt=""/>
      </div>
    </div>
  )
}

export default withError(PasswordResetPage)
