import React from 'react'

const svg = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="13.659" viewBox="0 0 16 13.659" {...props}>
      <path className="a"
            d="M10.161,16.163a3.971,3.971,0,0,0,0,5.606L17,28.657l6.845-6.88a3.97,3.97,0,0,0,0-5.606,3.922,3.922,0,0,0-5.578,0l-1.26,1.266-1.268-1.274a3.922,3.922,0,0,0-5.578,0Z"
            transform="translate(-9.002 -14.997)"/>
    </svg>
  )
}

export default svg
