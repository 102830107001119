import React, { useState } from 'react'
import i18next from '../../translations'
import Input from '../Input/Input'
import api from '../../api'
import Label from '../Label/Label'
import Button from '../Button/Button'
import SurfaceBody from '../SurfaceBody/SurfaceBody'
import PopupButtons from '../PopupButtons/PopupButtons'
import neutralizeEvent from '../../utils/neutralizeEvent'

const RenameClubForm = (props) => {
  const {
    clubId,
    clubName,
    onCancel,
    callback
  } = props

  const [name, setName] = useState(clubName)

  const handleSubmit = (event) => {
    neutralizeEvent(event)
    const club = {
      name
    }
    api.club.updateClub(clubId, club)
      .then(callback)
  }

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <SurfaceBody>
          <Label value={i18next.t('common:new_club_name')}>
            <Input
              value={name}
              onChange={({ target }) => setName(target.value)}
              width='100%'
              required
            />
          </Label>
        </SurfaceBody>
        <PopupButtons>
          <Button onClick={onCancel}>{i18next.t('actions:cancel')}</Button>
          <Button type='submit' theme='primary'>{i18next.t('actions:save')}</Button>
        </PopupButtons>
      </form>
    </div>
  )
}

export default RenameClubForm
