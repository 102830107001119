import React, { useState } from 'react'
import classes from './ColorPicker.module.scss'
import PropTypes from 'prop-types'
import { PhotoshopPicker } from 'react-color'
import spoofedEvent from '../../utils/spoofedEvent'

const ColorPicker = (props) => {
  const {
    value = '#FFFFFF',
    onChange
  } = props

  const [currentColor, setCurrentColor] = useState(value)
  const [showPicker, setShowPicker] = useState(false)

  const handleChange = () => {
    const target = {
      value: currentColor
    }
    const event = spoofedEvent(target)
    onChange(event)
    setShowPicker(false)
  }

  return (
    <div className={classes.root}>
      {
        Boolean(showPicker) && (
          <div className={classes.picker}>
            <PhotoshopPicker
              color={currentColor}
              onChange={setCurrentColor}
              onAccept={handleChange}
              onCancel={() => setShowPicker(false)}
            />
          </div>
        )
      }
      <div
        className={classes.preview}
        onClick={() => setShowPicker(true)}
        style={{ backgroundColor: value }}
      />
    </div>
  )
}

ColorPicker.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired
}

export default ColorPicker
