import React, { useRef, useState } from 'react'
import classes from './SearchSelect.module.scss'
import PropTypes from 'prop-types'
import Input from '../Input/Input'
import NavList from '../NavList/NavList'
import useClickOutside from '../../customHooks/useClickOutside'
import spoofedEvent from '../../utils/spoofedEvent'
import PopupButtons from '../PopupButtons/PopupButtons'

const SearchSelect = (props) => {
  const {
    value,
    options = [],
    onChange,
    ...restProps
  } = props

  const [searchValue, setSearchValue] = useState('')
  const [hasFocus, setHasFocus] = useState(false)
  const rootRef = useRef(null)

  const search = ({ target }) => {
    setSearchValue(target.value)
    if (value) {
      selectOption(null)
    }
  }

  const selectOption = (id) => {
    setHasFocus(false)
    const target = {
      value: id
    }
    const event = spoofedEvent(target)
    onChange(event)
  }

  const optionsNavList = options
    .filter(({ value }) => {
      return !searchValue || (new RegExp(searchValue, 'i')).test(value)
    })
    .map((option) => ({
      content: option.displayValue || option.value,
      to: () => selectOption(option.id)
    }))

  useClickOutside(rootRef, () => setHasFocus(false))

  const handleKeyDown = (event) => {
    event.nativeEvent.stopImmediatePropagation()
    if (event.key === 'Escape' || event.key === 'Tab') {
      setHasFocus(false)
    } else {
      setHasFocus(true)
    }
  }

  const rootClassNames = [classes.root]
  if (hasFocus) {
    rootClassNames.push(classes.hasFocus)
  }

  const valueName = ((value && options.find(({ id }) => id === value)) || {}).value

  return (
    <div className={rootClassNames.join(' ')} ref={rootRef}>
      <Input
        value={valueName || searchValue}
        onChange={search}
        width='100%'
        onFocus={() => setHasFocus(true)}
        onKeyDown={handleKeyDown}
        search={!value}
        disabled={!!value}
        valid={!!valueName}
        customRequired
        {...restProps}
      />
      <div className={classes.suggestions}>
        {
          optionsNavList.length
            ? <NavList items={optionsNavList}/>
            : (
              <PopupButtons padded align='center'>
                geen resultaten
              </PopupButtons>
              )
        }
      </div>
    </div>
  )
}

SearchSelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      displayValue: PropTypes.any
    }).isRequired
  ).isRequired,
  onChange: PropTypes.func.isRequired
}

export default SearchSelect
