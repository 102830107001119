const hexToRGB = (hex) => {
  const hexRed = hex.substring(1, 3)
  const hexGreen = hex.substring(3, 5)
  const hexBlue = hex.substring(5, 7)
  const red = parseInt(hexRed, 16)
  const green = parseInt(hexGreen, 16)
  const blue = parseInt(hexBlue, 16)
  return [red, green, blue]
}

export default hexToRGB
