import React, { useState } from 'react'
import i18next from '../../translations'
import Input from '../Input/Input'
import api from '../../api'
import Label from '../Label/Label'
import Button from '../Button/Button'
import PopupButtons from '../PopupButtons/PopupButtons'
import Surface from '../Surface/Surface'
import SurfaceBody from '../SurfaceBody/SurfaceBody'
import neutralizeEvent from '../../utils/neutralizeEvent'
import addMessage from '../../utils/addMessage'

const CreateClubForm = (props) => {
  const {
    onCancel,
    callback
  } = props

  const [name, setName] = useState('')

  const handleSubmit = (event) => {
    neutralizeEvent(event)
    const club = {
      name,
      colors: {
        primaryColor: '#C71A1A',
        contrastColor: '#FAFAFA'
      }
    }
    api.club.createClub(club)
      .then(() => {
        addMessage(i18next.t('common:x_added', { x: name }), { type: 'success' })
        callback()
      })
  }

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <Surface>
          <SurfaceBody>
            <Label value={i18next.t('common:club_name')}>
              <Input
                value={name}
                onChange={({ target }) => setName(target.value)}
                width='100%'
                required
              />
            </Label>
          </SurfaceBody>
          <PopupButtons>
            <Button onClick={onCancel}>{i18next.t('actions:cancel')}</Button>
            <Button type='submit' theme='primary'>{i18next.t('actions:save')}</Button>
          </PopupButtons>
        </Surface>
      </form>
    </div>
  )
}

export default CreateClubForm
