import React from 'react'

const svg = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="11.911" height="11.336" viewBox="0 0 11.911 11.336" {...props}>
      <path d="M38.858,28.366V25.383l-6.214,5.509-.169.162h0l0,0,6.385,5.662V33.772l-3.021-2.721Z"
            transform="translate(-26.947 -25.383)"/>
      <path d="M38.858,28.366V25.383l-6.214,5.509-.169.162h0l0,0,6.385,5.662V33.772l-3.021-2.721Z"
            transform="translate(-32.473 -25.383)"/>
    </svg>
  )
}

export default svg
