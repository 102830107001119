import React from 'react'
import classes from './CheckBox.module.scss'
import PropTypes from 'prop-types'
import Check from '../svg/Check'
import Dash from '../svg/Dash'

const CheckBox = (props) => {
  const {
    name,
    checked,
    onChange,
    disabled
  } = props

  const rootClassNames = [classes.root]
  if (checked) {
    rootClassNames.push(classes.checked)
  }
  if (disabled) {
    rootClassNames.push(classes.disabled)
  }

  return (
    <label className={rootClassNames.join(' ')}>
      <input
        type="checkbox"
        name={name}
        checked={checked && checked !== 'partial'}
        onChange={onChange}
        disabled={disabled}
      />
      {
        Boolean(checked) && Boolean(checked === 'partial')
          ? <Dash/>
          : (
            <Check width='16' height='12'/>
            )
      }
    </label>
  )
}

CheckBox.propTypes = {
  name: PropTypes.string,
  checked: PropTypes.oneOfType([
    PropTypes.bool.isRequired,
    PropTypes.oneOf(['partial']).isRequired
  ]).isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool
}

export default CheckBox
