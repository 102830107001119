import React from 'react'
import classes from './BackButton.module.scss'
import i18next from '../../translations'
import { useHistory } from 'react-router-dom'
import Button from '../Button/Button'
import Back from '../svg/Back'

const BackButton = () => {
  const history = useHistory()

  return (
    <div className={classes.root}>
      <Button className={classes.navLink} onClick={history.goBack}>
        <Back />
        <div className={classes.text}>{i18next.t('actions:back')}</div>
      </Button>
    </div>
  )
}

export default BackButton
