import React from 'react'
import classes from './Button.module.scss'
import PropTypes from 'prop-types'

const Button = (props) => {
  const {
    children,
    theme,
    size,
    onClick = () => {},
    type = 'button',
    disabled,
    ...restProps
  } = props

  const classNames = [classes.root]
  if (theme) {
    classNames.push(classes[`theme-${theme}`])
  }
  if (size) {
    classNames.push(classes[`size-${size}`])
  }
  if (disabled) {
    classNames.push(classes.disabled)
  }

  return (
    <div className={classNames.join(' ')}>
      <button onClick={onClick} type={type} disabled={disabled} {...restProps}>
        {children}
      </button>
    </div>
  )
}

Button.propTypes = {
  type: PropTypes.oneOf(['button', 'submit']),
  onClick: PropTypes.func,
  theme: PropTypes.oneOf(['primary', 'role']),
  size: PropTypes.oneOf(['no-padding', 'small']),
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool
}

export default Button
