import React from 'react'

const svg = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="14" viewBox="0 0 16 14" {...props}>
      <g transform="translate(0 0)">
        <path className="a" d="M41.249,7.917A1.668,1.668,0,0,0,42.916,6.25H39.582A1.668,1.668,0,0,0,41.249,7.917Z"
              transform="translate(-33.249 -6.25)"/>
        <path className="a"
              d="M15.9,9.687l-3.23-3.1-.25-.24a.352.352,0,0,0-.233-.093H10.332a2.333,2.333,0,0,1-4.666,0H3.811a.352.352,0,0,0-.233.093l-.25.24L.1,9.687a.334.334,0,0,0,0,.476l2.037,1.99a.333.333,0,0,0,.466,0L4,10.917v9a.334.334,0,0,0,.333.333h7.334A.334.334,0,0,0,12,19.917v-9l1.4,1.237h0a.333.333,0,0,0,.466,0l2.037-1.99a.334.334,0,0,0,0-.476Z"
              transform="translate(0.002 -6.25)"/>
      </g>
    </svg>
  )
}

export default svg
