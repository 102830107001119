import React from 'react'

const svg = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="18.286" viewBox="0 0 16 18.286" {...props}>
      <path className="a"
            d="M15.571,5.714H.429A.43.43,0,0,1,0,5.286V4A1.715,1.715,0,0,1,1.714,2.286H3.429V.429A.43.43,0,0,1,3.857,0H5.286a.43.43,0,0,1,.429.429V2.286h4.571V.429A.43.43,0,0,1,10.714,0h1.429a.43.43,0,0,1,.429.429V2.286h1.714A1.715,1.715,0,0,1,16,4V5.286A.43.43,0,0,1,15.571,5.714ZM.429,6.857H15.571A.43.43,0,0,1,16,7.286v9.286a1.715,1.715,0,0,1-1.714,1.714H1.714A1.715,1.715,0,0,1,0,16.571V7.286A.43.43,0,0,1,.429,6.857Z"
            transform="translate(0)"/>
    </svg>
  )
}

export default svg
