import React from 'react'

const svg = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="10.537" viewBox="0 0 16 10.537" {...props}>
      <path className="a"
            d="M9.933,23,17,28.689,24.067,23ZM9,23.744v9.793H25V23.744L17.366,29.89a.585.585,0,0,1-.732,0Z"
            transform="translate(-9 -23)"/>
    </svg>
  )
}

export default svg
