import React from 'react'

const svg = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16.785 12.868" width="21" height="16" {...props}>
      <path className="check-a" d="M0,18.755,1.865,16.33l4.849,4.1,7.833-8.765,2.238,2.052L6.9,24.536Z"
            transform="translate(0 -11.668)"/>
    </svg>
  )
}

export default svg
