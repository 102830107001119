import React, { useState } from 'react'
import i18next from '../../translations'
import Input from '../Input/Input'
import api from '../../api'
import Label from '../Label/Label'
import Button from '../Button/Button'
import SurfaceBody from '../SurfaceBody/SurfaceBody'
import PopupButtons from '../PopupButtons/PopupButtons'
import neutralizeEvent from '../../utils/neutralizeEvent'
import addMessage from '../../utils/addMessage'

const CreateTeamForm = (props) => {
  const {
    clubId,
    clubName,
    onCancel,
    callback
  } = props

  const [name, setName] = useState('')

  const handleSubmit = (event) => {
    neutralizeEvent(event)
    const team = {
      name
    }
    api.club.createTeam(clubId, team)
      .then(() => {
        addMessage(i18next.t('messages:team_created_in_club', { team: name, club: clubName }), { type: 'success' })
        callback()
      })
  }

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <SurfaceBody>
          <Label value={i18next.t('common:team_name')}>
            <Input
              value={name}
              onChange={({ target }) => setName(target.value)}
              width='100%'
              required
            />
          </Label>
        </SurfaceBody>
        <PopupButtons>
          <Button onClick={onCancel}>{i18next.t('actions:cancel')}</Button>
          <Button type='submit' theme='primary'>{i18next.t('actions:save')}</Button>
        </PopupButtons>
      </form>
    </div>
  )
}

export default CreateTeamForm
