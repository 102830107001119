import React from 'react'

const svg = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="10.353" viewBox="0 0 16 10.353" {...props}>
      <g transform="translate(0 0)">
        <path
          d="M22.582,27.082c-4.705,0-8,5.177-8,5.177s2.4,5.177,8,5.177c5.647,0,8-5.177,8-5.177S27.335,27.082,22.582,27.082Zm0,8.47a3.294,3.294,0,1,1,3.294-3.294A3.273,3.273,0,0,1,22.582,35.552Z"
          transform="translate(-14.582 -27.082)"/>
        <path d="M46.573,45.162a1.412,1.412,0,1,1-1.412-1.412,1.411,1.411,0,0,1,1.412,1.412"
              transform="translate(-37.162 -39.985)"/>
      </g>
    </svg>
  )
}

export default svg
