import React from 'react'

const svg = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21.722" height="21.722" viewBox="0 0 21.722 21.722" {...props}>
      <defs>
        <style>
          {`
            .cls-1 {
              fill: #eba22c;
            }

export default svg
          `}

export default svg
        </style>
      </defs>
      <path id="np_time_2630927_000000" className="cls-1"
            d="M13.361,2.5A10.861,10.861,0,1,0,24.222,13.361,10.871,10.871,0,0,0,13.361,2.5Zm4.276,13.857a1.044,1.044,0,0,1-.869.457,1.213,1.213,0,0,1-.617-.183l-3.406-2.4a1.106,1.106,0,0,1-.457-.869V7.621a1.074,1.074,0,0,1,2.149,0v5.19l2.949,2.081a1.034,1.034,0,0,1,.252,1.464Z"
            transform="translate(-2.5 -2.5)"/>
    </svg>

  )
}

export default svg
