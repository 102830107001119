import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import LoginPage from '../../pages/LoginPage/LoginPage'
import PasswordResetPage from '../../pages/PasswordResetPage/PasswordResetPage'
import { setDefault } from '../../redux/appState/actions'
import api from '../../api'
import { isBefore } from 'date-fns'
import PrivateRouter from '../PrivateRouter/PrivateRouter'
import Version from '../Version/Version'
import useMe from '../../customHooks/useMe'

const Router = (props) => {
  const {
    authentication,
    logOut
  } = props

  const { me, setMe } = useMe()

  const [checkedAuthentication, setCheckedAuthentication] = useState(false)
  const [authenticationInterval, setAuthenticationInterval] = useState(null)

  // read the JSON payload of a token
  const decodeToken = (token) => {
    const payload = atob(token.split('.')[1])
    return JSON.parse(payload)
  }

  // check if the token is expired
  const checkAuthenticationExpiryTime = () => {
    const decodedToken = decodeToken(authentication.token)
    const expiryDate = new Date(decodedToken.exp * 1000)
    const now = new Date()
    return isBefore(now, expiryDate)
  }

  // verify the authentication
  useEffect(() => {
    if (authentication) {
      const checkAuthentication = async () => {
        try {
          const verified = await api.auth.verify(authentication.token)
          const authenticationNotExpired = checkAuthenticationExpiryTime()
          if (verified && authenticationNotExpired) {
            api.user.me()
              .then((me) => {
                setMe(me)
                setCheckedAuthentication(true)
              })
          } else {
            setCheckedAuthentication(true)
          }
        } catch (error) {
          logOut()
        }
      }
      checkAuthentication().then(() => {
        console.log('authentication OK')
      }).catch(() => {
        console.warn('authentication NOK')
      })
    }
  }, [authentication, setMe])

  // logout if the authentication is expired
  useEffect(() => {
    if (checkedAuthentication) {
      const interval = window.setInterval(() => {
        if (authentication) {
          const verificationIsValid = checkAuthenticationExpiryTime()
          if (!verificationIsValid) {
            logOut()
          }
        } else {
          if (authenticationInterval) {
            clearInterval(authenticationInterval)
            setAuthenticationInterval(null)
          }
        }
      }, 1000)
      setAuthenticationInterval(interval)
    }
  }, [authentication, checkedAuthentication])

  if (authentication && !checkedAuthentication) {
    return <div>Loading...</div>
  }

  return (
    <BrowserRouter>
      <Switch>
        {
          me
            ? (
              <Switch>
                <PrivateRouter />
                <Redirect to='/' />
              </Switch>)
            : (
              <>
                <Switch>
                  <Route exact path='/login' component={LoginPage} />
                  <Route exact path='/password-reset' component={PasswordResetPage} />
                  <Redirect from='/' to='/login' />
                </Switch>
                <div style={{ position: 'absolute', right: 0, top: 0, padding: 12 }}>
                  <Version />
                </div>
              </>)
        }
      </Switch>
    </BrowserRouter>
  )
}

const mapStateToProps = ({ appState }) => ({
  authentication: appState.authentication
})

const mapDispatchToProps = (dispatch) => ({
  logOut: () => dispatch(setDefault())
})

export default connect(mapStateToProps, mapDispatchToProps)(Router)
