import React, { useEffect, useState } from 'react'
import classes from './UserInfoSidePanel.module.scss'
import PropTypes from 'prop-types'
import api from '../../api'
import roleTranslations from '../../definitions/roleTranslations'
import Gender from '../svg/Gender'
import Shoe from '../svg/Shoe'
import Phone from '../svg/Phone'
import Email from '../svg/Email'
import Calendar from '../svg/Calendar'
import Clothing from '../svg/Clothing'
import PopupButtons from '../PopupButtons/PopupButtons'
import Button from '../Button/Button'
import i18next from 'i18next'
import { NavLink } from 'react-router-dom'
import Coach from '../svg/Coach'
import Person from '../svg/Person'
import useIsMounted from '../../customHooks/useIsMounted'
import useMe from '../../customHooks/useMe'

const Info = (props) => {
  const {
    icon: Icon,
    value
  } = props

  return (
    <div className={classes.info}>
      <div className={classes.icon}>
        {
          Icon && (
            <Icon/>
          )
        }
      </div>
      <div className={classes.value}>{value || '?'}</div>
    </div>
  )
}

const UserInfoSidePanel = (props) => {
  const { userId } = props

  // const history = useHistory()
  const [user, setUser] = useState(null)
  const { isAdmin } = useMe()
  const isMounted = useIsMounted()

  useEffect(() => {
    api.user.getUser(userId)
      .then((user) => {
        if (isMounted()) {
          setUser(user)
        }
      })
  }, [isMounted, userId])

  const role = Boolean(user) && roleTranslations[user.role]

  const userActivitiesLink = user && user.role === 'player' && (() => {
    const query = new URLSearchParams({
      teamsFilter: JSON.stringify(user.teams.map(({ _id }) => _id)),
      userFilter: user._id
    })
    return `/activiteiten?${query}`
  })()

  const userStatisticsLink = user && user.role === 'player' && (() => {
    const query = new URLSearchParams({
      teamFilter: user.teams[0]._id,
      userFilter: user._id
    })
    return `/statistieken?${query}`
  })()

  return (
    <>
      <div className='header'>
        <div className='back'/>
        {
          Boolean(user) && (
            <div className={classes.titleContainer}>
              <div className='title'>
                <div className={classes.roleIconContainer}>
                  {
                    user.role === 'player'
                      ? <Person width={20} height={24} fill='currentColor'/>
                      : <Coach width={20} height={24} fill='currentColor'/>
                  }
                </div>
                <div className={classes.userName}>{user.name}</div>
              </div>
              <div className='subtitle'>
                {role}
              </div>
            </div>
          )
        }
      </div>
      {
        Boolean(user) && (
          <div className='body'>
            <div className={classes.body}>
              <Info icon={Gender} value={user.gender}/>
              <Info icon={Calendar} value={user.birthYear}/>
              <Info icon={Clothing} value={user.shirtSize}/>
              <Info icon={Shoe} value={user.shoeSize}/>
              <Info icon={Phone} value={user.phoneNumber}/>
              <Info icon={Email} value={user.email}/>
            </div>
            {
              Boolean(!isAdmin && user.role === 'player') && (
                <PopupButtons>
                  <NavLink to={userActivitiesLink}>
                    <Button theme='primary'>{i18next.t('header:activiteiten')}</Button>
                  </NavLink>
                  <NavLink to={userStatisticsLink}>
                    <Button theme='primary'>{i18next.t('header:statistieken')}</Button>
                  </NavLink>
                </PopupButtons>
              )
            }
          </div>
        )
      }
    </>
  )
}

UserInfoSidePanel.propTypes = {
  userId: PropTypes.string.isRequired
}

export default UserInfoSidePanel
