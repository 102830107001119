import React, { useEffect, useState } from 'react'
import classes from './SnackBar.module.scss'
import { connect } from 'react-redux'
import { deleteMessage } from '../../redux/snackBarState/actions'

const Message = (props) => {
  const {
    uuid,
    message,
    type = 'normal',
    deleteMessage
  } = props

  const [markedForRemoval, setMarkedForRemoval] = useState(false)

  useEffect(() => {
    setTimeout(() => setMarkedForRemoval(true), 5000)
  }, [uuid])

  useEffect(() => {
    if (markedForRemoval) {
      setTimeout(deleteMessage, 400)
    }
  }, [uuid, markedForRemoval])

  const classNames = [classes.message, classes[`type-${type}`]]
  if (markedForRemoval) {
    classNames.push(classes.removed)
  }
  return (
    <div className={classNames.join(' ')} onClick={() => setMarkedForRemoval(true)}>
      {message}
    </div>
  )
}

const SnackBar = (props) => {
  const {
    messages = [],
    deleteMessage
  } = props

  return (
    <div className={classes.root}>
      <div className={classes.messages}>
        {
          messages.map(({ uuid, message, type }, index) => {
            const key = `SnackBar__messages__${uuid}__${index}`
            return (
              <Message
                key={key}
                uuid={uuid}
                message={message}
                type={type}
                deleteMessage={() => deleteMessage(uuid)}
              />
            )
          })
        }
      </div>
    </div>
  )
}

const mapStateToProps = ({ snackBarState }) => ({
  messages: snackBarState.messages
})

const mapDispatchToProps = (dispatch) => ({
  deleteMessage: (uuid) => dispatch(deleteMessage(uuid))
})

export default connect(mapStateToProps, mapDispatchToProps)(SnackBar)
