import React from 'react'
import classes from './HoverActions.module.scss'
import PropTypes from 'prop-types'
import neutralizeEvent from '../../utils/neutralizeEvent'

const HoverActions = (props) => {
  const {
    content = null,
    actions = []
  } = props

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        {content}
      </div>
      <div className={classes.actions} onClick={neutralizeEvent}>
        {
          actions.map((action, actionIndex) => {
            const key = `HoverActions__actions__${actionIndex}`
            return (
              <div key={key}>
                {action}
              </div>
            )
          })
        }
      </div>
    </div>
  )
}

HoverActions.propTypes = {
  content: PropTypes.node.isRequired,
  actions: PropTypes.arrayOf(
    PropTypes.node.isRequired
  )
}

export default HoverActions
