import * as activity from './activity'
import * as auth from './auth'
import * as club from './club'
import * as stats from './stats'
import * as user from './user'

const defaultExport = {
  activity,
  auth,
  club,
  stats,
  user
}

export default defaultExport
