import React from 'react'
import classes from './Header.module.scss'
import { NavLink } from 'react-router-dom'
import Button from '../Button/Button'
import { connect } from 'react-redux'
import { setDefault, setHeaderHeight } from '../../redux/appState/actions'
import i18next from '../../translations'
import { useLocation } from 'react-router'
import Version from '../Version/Version'
import { useResizeDetector } from 'react-resize-detector'

const Header = (props) => {
  const {
    me,
    setDefault,
    setHeaderHeight
  } = props

  const onResize = (width, height) => {
    setHeaderHeight(height)
  }

  const location = useLocation()
  useResizeDetector({
    handleHeight: true,
    onResize
  })

  const paths = {
    activities: {
      path: 'activiteiten',
      activePaths: ['activiteiten'],
      displayName: i18next.t('common:activities')
    },
    clubs: {
      path: 'clubs',
      activePaths: ['clubs', 'club', 'ploeg'],
      displayName: i18next.t('common:clubs')
    },
    club: {
      path: 'club',
      activePaths: ['clubs', 'club', 'ploeg'],
      displayName: i18next.t('common:club')
    },
    team: {
      path: 'ploeg',
      activePaths: ['clubs', 'club', 'ploeg'],
      displayName: i18next.t('common:team')
    },
    statistics: {
      path: 'statistieken',
      activePaths: ['statistieken'],
      displayName: i18next.t('common:statistics')
    }
  }

  const isActive = (activePaths = []) => {
    return activePaths.some((path) => {
      const pathname = `/${path}`
      return location.pathname === pathname || location.pathname.startsWith(`${pathname}/`)
    })
  }

  const renderTabs = () => {
    const {
      activities,
      clubs,
      club,
      team,
      statistics
    } = paths
    const roleTabs = {
      admin: [activities, clubs, statistics],
      clubadmin: [activities, club, statistics],
      coach: [activities, team, statistics]
    }
    return roleTabs[me.role].map(({ path, displayName, activePaths }, index) => {
      return (
        <NavLink
          key={`Header__renderTabs__tabName__${path}__${index}`}
          to={`/${path}`}
          isActive={() => isActive(activePaths)}
          activeClassName={classes.active}
        >
          {displayName}
        </NavLink>
      )
    })
  }

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <div className={classes.left}>
          {renderTabs()}
        </div>
        <div className={classes.right}>
          <Button className={classes.logOutButton} size='small' theme='role' onClick={setDefault}>
            {me.name} {i18next.t('actions:logout')}
          </Button>
          <Version/>
        </div>
      </div>
      <div id='headerPortalContainer' className={classes.headerPortalContainer}/>
    </div>
  )
}

const mapDispatchToProps = (dispatch) => ({
  setDefault: () => dispatch(setDefault()),
  setHeaderHeight: (headerHeight) => dispatch(setHeaderHeight(headerHeight))
})

export default connect(null, mapDispatchToProps)(Header)
