import React from 'react'
import classes from './SurfaceBody.module.scss'

const SurfaceBody = (props) => {
  const {
    children,
    className = '',
    ...restProps
  } = props

  const classNames = [classes.root, className]

  return (
    <div className={classNames.join(' ')} {...restProps}>
      {children}
    </div>
  )
}

export default SurfaceBody
