import apiURL from './apiURL'
import handleFetch from './handleFetch.js'
import getAuthorization from './getAuthorization'

export const me = async () => {
  const url = apiURL('/user/me')
  const options = {
    method: 'GET',
    headers: {
      ...getAuthorization()
    }
  }
  return handleFetch(url, options)
}

export const createUser = async (user) => {
  const url = apiURL('/user')
  const options = {
    method: 'POST',
    headers: {
      ...getAuthorization(),
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(user)
  }
  return handleFetch(url, options)
}

export const deleteUser = async (userId, forceDelete = false) => {
  const url = apiURL(`/user/${userId}?force=${forceDelete}`)
  const options = {
    method: 'DELETE',
    headers: {
      ...getAuthorization()
    }
  }
  return handleFetch(url, options)
}

export const getUsers = async (filter = {}) => {
  const query = new URLSearchParams(filter)
  const url = apiURL(`/user?${String(query)}`)
  const options = {
    method: 'GET',
    headers: {
      ...getAuthorization()
    }
  }
  return handleFetch(url, options)
}

export const getUser = async (coachId) => {
  const url = apiURL(`/user/${coachId}`)
  const options = {
    method: 'GET',
    headers: {
      ...getAuthorization()
    }
  }
  return handleFetch(url, options)
}

export const addUserToTeam = async (userId, teamId) => {
  const url = apiURL(`/user/${userId}/team/${teamId}`)
  const options = {
    method: 'POST',
    headers: {
      ...getAuthorization()
    }
  }
  return handleFetch(url, options)
}

export const deleteUserFromTeam = async (userId, teamId) => {
  const url = apiURL(`/user/${userId}/team/${teamId}`)
  const options = {
    method: 'DELETE',
    headers: {
      ...getAuthorization()
    }
  }
  return handleFetch(url, options)
}

export const upgradeCoachToClubAdmin = async (userId) => {
  const url = apiURL(`/user/${userId}/upgradeCoachToClubAdmin`)
  const options = {
    method: 'PATCH',
    headers: {
      ...getAuthorization()
    }
  }
  return handleFetch(url, options)
}
