import React, { useRef, useState } from 'react'
import classes from './SelectRange.module.scss'
import PropTypes from 'prop-types'
import i18next from '../../translations'
import CheckBox from '../CheckBox/CheckBox'
import spoofedEvent from '../../utils/spoofedEvent'
import Button from '../Button/Button'
import useClickOutside from '../../customHooks/useClickOutside'
import useKeyDown from '../../customHooks/useKeyDown'

const SelectRange = (props) => {
  const {
    title,
    value = {
      from: null,
      to: null
    },
    options,
    onChange,
    clearText
  } = props

  const [expanded, setExpanded] = useState(false)
  const rootRef = useRef(null)

  useKeyDown('Escape', () => setExpanded(false))
  useClickOutside(rootRef, () => setExpanded(false))

  const hasValue = Boolean(value.from && value.to)
  const fromIndex = options.findIndex(({ id }) => id === value.from)
  const toIndex = options.findIndex(({ id }) => id === value.to)

  const toggleValue = ({ target }) => {
    const index = options.findIndex(({ id }) => id === target.name)
    let newValue

    if (!hasValue) {
      newValue = {
        from: target.name,
        to: target.name
      }
    } else {
      if (fromIndex === index && toIndex === index) {
        newValue = undefined
      } else if (fromIndex === index && toIndex !== index) {
        newValue = {
          from: value.to,
          to: value.to
        }
      } else if (fromIndex !== index && toIndex === index) {
        newValue = {
          from: value.from,
          to: value.from
        }
      } else if (fromIndex > index) {
        newValue = {
          from: target.name,
          to: value.to
        }
      } else if (toIndex < index) {
        newValue = {
          from: value.from,
          to: target.name
        }
      }
    }
    {
      const target = {
        value: newValue
      }
      const event = spoofedEvent(target)
      onChange(event)
    }
  }

  const selectAll = () => {
    const newValue = {
      from: options[0].id,
      to: options[options.length - 1].id
    }
    const target = {
      value: newValue
    }
    const event = spoofedEvent(target)
    onChange(event)
  }

  const clearAll = () => {
    const target = {
      value: undefined
    }
    const event = spoofedEvent(target)
    onChange(event)
  }

  const rootClassNames = [classes.root]
  if (expanded) {
    rootClassNames.push(classes.expanded)
  }
  if (hasValue) {
    rootClassNames.push(classes.hasValue)
  }

  const selectedFrom = hasValue && options.find(({ id }) => id === value.from)
  const selectedTo = hasValue && options.find(({ id }) => id === value.to)

  return (
    <div className={rootClassNames.join(' ')} ref={rootRef}>
      <div className={classes.value} onClick={() => setExpanded(!expanded)}>
        {
          hasValue
            ? (
                value.from === value.to
                  ? <span>{title} - {selectedFrom.displayValue || selectedFrom.value}</span>
                  : (
                  <span>
                    {title} -
                    {i18next.t('common:x_to_y', {
                      x: selectedFrom.displayValue || selectedFrom.value,
                      y: selectedTo.displayValue || selectedTo.value
                    })}
                  </span>
                    )
              )
            : <span>{title}</span>
        }
      </div>
      {
        Boolean(expanded) && (
          <div className={classes.popup}>
            {
              options.map((option, index) => {
                const isChecked = hasValue && (fromIndex <= index && toIndex >= index)
                const isDisabled = hasValue && (fromIndex < index && toIndex > index)

                return (
                  <label className={classes.option} key={`Select__options__${option.id}__${index}`}>
                    <CheckBox
                      name={option.id}
                      checked={isChecked}
                      disabled={isDisabled}
                      onChange={toggleValue}
                    />
                    <div className={classes.text}>{option.displayValue || option.value}</div>
                  </label>
                )
              })
            }
            <div className={classes.buttons}>
              <Button onClick={selectAll} style={{ paddingLeft: 0 }}>
                {i18next.t('actions:select_all')}
              </Button>
              <Button onClick={clearAll} style={{ paddingRight: 0 }}>
                {clearText || i18next.t('actions:clear')}
              </Button>
            </div>
          </div>
        )
      }
    </div>
  )
}

SelectRange.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.shape({
    from: PropTypes.string,
    to: PropTypes.string
  }),
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    displayValue: PropTypes.any
  })).isRequired,
  clearText: PropTypes.string
}

export default SelectRange
