import React, { useState, useEffect, useRef } from 'react'

const InputDelay = (props) => {
  const {
    component: Component,
    value,
    onChange,
    ...restProps
  } = props

  const [localValue, setLocalValue] = useState(value)
  const timeoutRef = useRef(null)

  useEffect(() => {
    if (timeoutRef.current === null) {
      setLocalValue(value)
    }
  }, [value])

  function handleChange (event) {
    setLocalValue(event.target.value)
    clearTimeout(timeoutRef.current)
    timeoutRef.current = null
    if (!event.preventUpdate) {
      if (Object.prototype.toString.apply(event.persist).slice(8, -1) === 'Function') {
        event.persist()
      }
      timeoutRef.current = setTimeout(() => {
        onChange(event)
        timeoutRef.current = null
      }, 700)
    }
  }

  return (
    <Component
      value={localValue}
      onChange={handleChange}
      {...restProps}
    />
  )
}

export default InputDelay
