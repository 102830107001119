import actions from './actions'
import activityTypes from './activityTypes'
import common from './common'
import errors from './errors'
import messages from './messages'

const translations = {
  actions,
  activityTypes,
  common,
  errors,
  messages
}

export default translations
