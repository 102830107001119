import React, { useEffect, useState } from 'react'
import classes from './EmailTeamsForm.module.scss'
import i18next from '../../translations'
import api from '../../api'
import Surface from '../Surface/Surface'
import SurfaceBody from '../SurfaceBody/SurfaceBody'
import PopupButtons from '../PopupButtons/PopupButtons'
import Button from '../Button/Button'
import SelectList from '../SelectList/SelectList'
import neutralizeEvent from '../../utils/neutralizeEvent'
import addMessage from '../../utils/addMessage'
import { formatDistanceToNow } from 'date-fns'
import getLocale from '../../utils/getLocale'

const EmailTeamsForm = (props) => {
  const {
    activityId,
    onCancel,
    callback
  } = props

  const [teamOptions, setTeamOptions] = useState([])
  const [selectedTeamIds, setSelectedTeamIds] = useState([])

  useEffect(() => {
    const locale = getLocale()
    api.club.getClubs()
      .then((clubs) => {
        api.activity.getActivity(activityId)
          .then((activity) => {
            const teamOptions = activity.attendingTeams
              .filter(({ attendees }) => !attendees.length)
              .map(({ team, lastReminded: lastRemindedTimeStamp }) => {
                const lastReminded = lastRemindedTimeStamp
                  ? formatDistanceToNow(new Date(lastRemindedTimeStamp), { addSuffix: true, locale })
                  : '-'
                const club = clubs.find((club) => club._id === team.club)
                return {
                  id: team._id,
                  value: {
                    [i18next.t('common:club')]: club.name,
                    [i18next.t('common:team')]: team.name,
                    [i18next.t('common:last_reminder')]: lastReminded
                  }
                }
              })
            setTeamOptions(teamOptions)
          })
      })
  }, [activityId])

  const updateValue = ({ target }) => {
    setSelectedTeamIds(target.value)
  }

  const handleSubmit = (event) => {
    neutralizeEvent(event)
    api.activity.sendReminders(activityId, selectedTeamIds)
      .then(() => {
        if (selectedTeamIds.length === 1) {
          const team = teamOptions.find(({ id }) => id === selectedTeamIds[0])
          addMessage(i18next.t('common:mails_sent_to_x', { x: team.value.Ploeg }), { type: 'success' })
        } else {
          addMessage(i18next.t('common:mails_sent_to_amount_teams', { amount: selectedTeamIds.length }), { type: 'success' })
        }
        callback()
      })
  }

  return (
    <form onSubmit={handleSubmit}>
      <Surface>
        <SurfaceBody>
          <div className={classes.selectListContainer}>
            <SelectList
              value={selectedTeamIds}
              options={teamOptions}
              onChange={updateValue}
            />
          </div>
        </SurfaceBody>
        <PopupButtons>
          <div className={classes.invitedTeamsCount}>
            {selectedTeamIds.length}/{teamOptions.length} {i18next.t('common:teams_selected')}
          </div>
          <Button onClick={onCancel}>{i18next.t('actions:cancel')}</Button>
          <Button type='submit' theme='primary' disabled={!selectedTeamIds.length}>{i18next.t('actions:send_emails')}</Button>
        </PopupButtons>
      </Surface>
    </form>
  )
}

export default EmailTeamsForm
