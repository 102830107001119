import React, { useEffect, useState } from 'react'
import classes from './UpdateActivityTeamsForm.module.scss'
import i18next from '../../translations'
import Input from '../Input/Input'
import api from '../../api'
import Surface from '../Surface/Surface'
import SurfaceBody from '../SurfaceBody/SurfaceBody'
import PopupButtons from '../PopupButtons/PopupButtons'
import Button from '../Button/Button'
import SelectList from '../SelectList/SelectList'
import neutralizeEvent from '../../utils/neutralizeEvent'

const UpdateActivityTeamsForm = (props) => {
  const {
    activityId,
    onCancel,
    callback
  } = props

  const [searchString, setSearchString] = useState('')
  const [teamOptions, setTeamOptions] = useState([])
  const [invitedTeamIds, setInvitedTeamIds] = useState([])

  useEffect(() => {
    api.activity.getActivity(activityId)
      .then((activity) => {
        const invitedTeamIds = activity.attendingTeams.map(({ team }) => team._id)
        setInvitedTeamIds(invitedTeamIds)
      })
    api.club.getTeams()
      .then((teams) => {
        const filter = {
          role: ['coach', 'clubadmin'],
          team: teams.map(({ _id }) => _id)
        }
        api.user.getUsers(filter)
          .then((teamCoaches) => {
            const teamsWithCoaches = teams.filter((team) => {
              return teamCoaches.some((teamCoach) => {
                return teamCoach.teams.some((teamCoachTeam) => {
                  return String(teamCoachTeam) === String(team._id)
                })
              })
            })
            const teamOptions = teamsWithCoaches.map((team) => ({
              id: team._id,
              value: {
                [i18next.t('common:club')]: team.club.name,
                [i18next.t('common:team')]: team.name
              }
            }))
            setTeamOptions(teamOptions)
          })
      })
  }, [activityId])

  const filteredTeamOptions = teamOptions.filter(({ value }) => {
    if (!(searchString && searchString.length)) {
      return true
    }
    const regex = new RegExp(searchString, 'i')
    return regex.test(value.Club) || regex.test(value.Ploeg)
  })

  const filteredInvitedTeamIds = invitedTeamIds.filter((invitedTeamId) => {
    if (!(searchString && searchString.length)) {
      return true
    }
    return filteredTeamOptions.some(({ id }) => id === invitedTeamId)
  })

  const updateValue = ({ target }) => {
    const hiddenInvitedTeamIds = invitedTeamIds.filter((invitedTeamId) => {
      return !filteredTeamOptions.some(({ id }) => id === invitedTeamId)
    })
    const newValue = [
      ...hiddenInvitedTeamIds,
      ...target.value
    ]
    setInvitedTeamIds(newValue)
  }

  const handleSubmit = (event) => {
    neutralizeEvent(event)
    api.activity.updateTeams(activityId, invitedTeamIds)
      .then(callback)
  }

  return (
    <form onSubmit={handleSubmit}>
      <Surface>
        <SurfaceBody>
          <Input
            value={searchString}
            onChange={({ target }) => setSearchString(target.value)}
            width='224px'
            placeholder={i18next.t('actions:search')}
            search
          />
          <div className={classes.selectListContainer}>
            <SelectList
              value={filteredInvitedTeamIds}
              options={filteredTeamOptions}
              onChange={updateValue}
            />
          </div>
        </SurfaceBody>
        <PopupButtons>
          <div className={classes.invitedTeamsCount}>
            {invitedTeamIds.length}/{teamOptions.length} {i18next.t('common:teams_selected')}
          </div>
          <Button onClick={onCancel}>{i18next.t('actions:cancel')}</Button>
          <Button type='submit' theme='primary' disabled={!invitedTeamIds.length}>{i18next.t('actions:save')}</Button>
        </PopupButtons>
      </Surface>
    </form>
  )
}

export default UpdateActivityTeamsForm
