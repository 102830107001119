import getAgeGroupByBirthYear from './getAgeGroupByBirthYear'
import i18next from '../../../translations'

const getAgeGroups = (uniqueAttendees) => {
  return Object.values(
    Array.from(uniqueAttendees)
      .reduce((ageGroups, { birthYear }) => {
        const ageGroup = getAgeGroupByBirthYear(birthYear)
        if (ageGroups[ageGroup]) {
          ageGroups[ageGroup].y++
          ageGroups[ageGroup].meta.value++
        } else {
          ageGroups[ageGroup] = {
            y: 1,
            x: ageGroup,
            meta: {
              name: ageGroup,
              value: 1
            }
          }
        }
        return ageGroups
      }, {}))
    .sort((a, b) => {
      if (b.meta.name === i18next.t('common:unknown') || a.meta.name > b.meta.name) {
        return 1
      }
      if (a.meta.name < b.meta.name) {
        return -1
      }
      return 0
    })
}

export default getAgeGroups
