export default function reducer (state, action) {
  const newState = Object.assign({}, state)
  switch (action.type) {
    case 'setFilterState': {
      return action.filterState
    }
    case 'setSearchString': {
      newState.searchString = action.searchString
      return newState
    }
    case 'setClubsFilter': {
      newState.clubsFilter = action.clubsFilter
      return newState
    }
    case 'setTeamsFilter': {
      newState.teamsFilter = action.teamsFilter
      if (action.clearUserFilter) {
        newState.userFilter = undefined
      }
      return newState
    }
    case 'setActivityTypesFilter': {
      newState.activityTypesFilter = action.activityTypesFilter
      return newState
    }
    case 'setStatusFilter': {
      newState.statusFilter = action.statusFilter
      return newState
    }
    case 'setSeasonFilter': {
      newState.seasonFilter = action.seasonFilter
      return newState
    }
    case 'setMonthsFilter': {
      newState.monthsFilter = action.monthsFilter
      return newState
    }
    case 'setUserFilter': {
      newState.userFilter = action.userFilter
      return newState
    }
    default: {
      return newState
    }
  }
}
