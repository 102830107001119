const getRegularAttendees = (statistics) => {
  const { activitiesCount, attendeeIds } = statistics.activityTypes.reduce((regularAttendees, { activities, attendees }) => {
    regularAttendees.activitiesCount += activities.reduce((activitiesCount, { count }) => activitiesCount + count, 0)
    regularAttendees.attendeeIds.push(...attendees.map(({ _id }) => _id))
    return regularAttendees
  }, { activitiesCount: 0, attendeeIds: [] })
  const attendeesUniqueCount = attendeeIds.reduce((attendeesUniqueCount, attendeeId) => {
    attendeesUniqueCount[attendeeId] = (attendeesUniqueCount[attendeeId] || 0) + 1
    return attendeesUniqueCount
  }, {})
  return Object.values(attendeesUniqueCount)
    .reduce((regularAttendeesCount, attendedActivities) => {
      if (attendedActivities) {
        const percentageAttended = Math.floor((attendedActivities - 0.000000001) / activitiesCount * 10)
        regularAttendeesCount[percentageAttended]++
      }
      return regularAttendeesCount
    }, new Array(10).fill(0))
    .map((attendeesCount, index) => {
      return {
        x: `${(index * 10) + 1} - ${(index + 1) * 10}%`,
        y: attendeesCount
      }
    })
}

export default getRegularAttendees
