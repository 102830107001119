import React from 'react'

const svg = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16.409" viewBox="0 0 16 16.409" {...props}>
      <path className="a"
            d="M15.482,3.3l3.957,5.434L7.779,17.126H4.508A2.438,2.438,0,0,1,6.2,14.7a4.6,4.6,0,0,0,2.322-1.688l1.213,1.161a.4.4,0,0,0,.633,0,.45.45,0,0,0,0-.686L9.1,12.166a3.8,3.8,0,0,0,.475-1.056l1.53,1.478a.449.449,0,0,0,.686,0,.552.552,0,0,0,0-.686L9.837,10A7.025,7.025,0,0,0,10,8.526l1.688,1.635a.481.481,0,0,0,.633,0,.45.45,0,0,0,0-.686L10.049,7.154A11.459,11.459,0,0,1,10,5.782L15.483,3.3Zm4.537,6.226a1.857,1.857,0,0,1,.475.9c.106.475-.422,1.266-.422,1.266L9.1,19.606l-3.482.106a2.143,2.143,0,0,1-1-1.583l3.324-.053.264-.053L20.019,9.529Z"
            transform="translate(-4.508 -3.302)"/>
    </svg>
  )
}

export default svg
