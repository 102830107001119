import i18next from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import nl from './languages/nl_be/index.js'
import fr from './languages/fr_be/index.js'
import getSupportedLanguage from '../utils/getSupportedLanguage'

localStorage.removeItem('i18nextLng')
const lng = getSupportedLanguage()

i18next
  .use(LanguageDetector)
  .init({
    fallbackLng: 'nl',
    defaultNS: 'common',
    debug: process.env.NODE_ENV === 'development',
    resources: {
      nl: nl,
      fr: fr
    },
    lng,
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    }
  }, function (error, t) {
    if (error) {
      console.warn('i18next error', error)
    }
  })
  .then(() => {
    console.log('i18next initialized')
  })

export default i18next
