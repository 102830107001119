import React from 'react'
import classes from '../StatisticsPage.module.scss'

const AgeGroups = (props) => {
  const { data } = props

  return (
    <div className={classes.table}>
      {
        data.map(({ meta }, index) => {
          const key = `AgeGroups__data__${index}`
          return (
            <div key={key} className={classes.row}>
              <div>
                {meta.name}
              </div>
              <div>{meta.value}</div>
            </div>
          )
        })
      }
    </div>
  )
}

export default AgeGroups
