import React, { useCallback, useEffect, useState } from 'react'
import classes from './ActivityInfoSidePanel.module.scss'
import PropTypes from 'prop-types'
import i18next from '../../translations'
import api from '../../api'
import Button from '../Button/Button'
import Popup from '../Popup/Popup'
import UpdateActivityTeamsForm from '../UpdateActivityTeamsForm/UpdateActivityTeamsForm'
import Trash from '../svg/Trash'
import IconButton from '../IconButton/IconButton'
import DeleteActivityForm from '../DeleteActivityForm/DeleteActivityForm'
import getActivityProgress from '../../utils/getActivityProgress'
import NavList from '../NavList/NavList'
import Danger from '../svg/Danger'
import Check from '../svg/Check'
import EmailTeamsForm from '../EmailTeamsForm/EmailTeamsForm'
import Person from '../svg/Person'
import Coach from '../svg/Coach'
import Tabs from '../Tabs/Tabs'
import UserInfoSidePanel from '../UserInfoSidePanel/UserInfoSidePanel'
import activityTypeColors from '../../definitions/activityTypeColors'
import activityTypeIcons from '../../definitions/activityTypeIcons'
import addMessage from '../../utils/addMessage'
import Talk from '../svg/Talk'
import { endOfDay, isBefore, format } from 'date-fns'
import getLocale from '../../utils/getLocale'
import useMe from '../../customHooks/useMe'

const ActivityInfoSidePanel = (props) => {
  const {
    setNextSidePanel,
    activityId,
    filterActivitiesCallback,
    setSelectedActivityId
  } = props

  const { isAdmin, isClubAdmin, isCoach } = useMe()
  const [activity, setActivity] = useState(null)
  const [selectedTeamId, setSelectedTeamId] = useState(null)
  const [showUpdateActivityTeamsModal, setShowUpdateActivityTeamsModal] = useState(false)
  const [showEmailTeamsModal, setShowEmailTeamsModal] = useState(false)
  const [showDeleteActivityModal, setShowDeleteActivityModal] = useState(false)

  const getActivity = useCallback(() => {
    api.activity.getActivity(activityId)
      .then(setActivity)
    filterActivitiesCallback()
    setShowUpdateActivityTeamsModal(false)
    setShowEmailTeamsModal(false)
  }, [activityId, filterActivitiesCallback])

  const deleteActivityCallback = () => {
    addMessage(i18next.t('common:x_deleted', { x: activity.description }), { type: 'error' })
    filterActivitiesCallback()
    setShowUpdateActivityTeamsModal(false)
    setSelectedActivityId(null)
  }

  useEffect(() => {
    getActivity()
  }, [activityId, getActivity])

  useEffect(() => {
    if (isClubAdmin || isCoach) {
      if (activity && activity.attendingTeams.length) {
        const firstAttendingTeam = activity.attendingTeams[0]
        setSelectedTeamId(firstAttendingTeam._id)
      }
    }
  }, [activity, isClubAdmin, isCoach])

  const locale = getLocale()
  const date = Boolean(activity) && new Date(activity.date)
  const today = endOfDay(new Date())

  const isFutureActivity = Boolean(activity) && isBefore(today, date)
  const canSendEmails = Boolean(activity && activity.attendingTeams.some(({ attendees }) => !attendees.length) && !isFutureActivity)

  const activityTypeName = Boolean(activity) && i18next.t(`activityTypes:${activity.activityType}`)
  const description = Boolean(activity) && activity.description
  const dateFormated = Boolean(activity) && format(date, 'EEEEEE dd MMMM yyyy', { locale })
  const teamsWithAttendeesPercentage = Boolean(activity) && getActivityProgress(activity.attendingTeams)
  const attendingTeamsCount = activity ? activity.attendingTeams.length : 0
  const attendeesCount = activity
    ? activity.attendingTeams.reduce((attendeesCount, { attendees = [], coaches = [] }) => {
      return attendeesCount + attendees.length + coaches.length
    }, 0)
    : 0
  const teamsNavList = activity && isAdmin
    ? activity.attendingTeams.map((attendingTeam, index) => {
      const key = `ActivityInfoSidePanel__teamsNavList__${attendingTeam._id}__${index}`
      const attendeesCount = attendingTeam.attendees.length
      return {
        content: (
          <div key={key} className={classes.attendingTeam}>
            <div className={classes.icon}>
              {
                isFutureActivity
                  ? null
                  : (
                      attendeesCount
                        ? <Check width={21} height={16} className={classes.green}/>
                        : <Danger className={classes.red}/>
                    )
              }
            </div>
            <div className={classes.name}>{attendingTeam.team.name}</div>
            {
              Boolean(!isFutureActivity) && (
                <div className={classes.count}>{attendeesCount || '?'}</div>
              )
            }
          </div>
        ),
        to: `/clubs/${attendingTeam.team.club}/${attendingTeam.team._id}`
      }
    })
    : []
  const selectedTeam = Boolean(activity) && (
    activity.attendingTeams.find(({ _id }) => _id === selectedTeamId)
  )
  const attendingTeamsTabs = activity && (isClubAdmin || isCoach) && selectedTeam
    ? activity.attendingTeams.map((attendingTeam) => {
      return {
        title: attendingTeam.team.name,
        subTitle: String(attendingTeam.attendees.length + attendingTeam.coaches.length),
        onClick: () => setSelectedTeamId(attendingTeam._id),
        isSelected: attendingTeam._id === selectedTeamId
      }
    })
    : []
  const attendeesNavList = activity && (isClubAdmin || isCoach) && selectedTeam
    ? [
        ...selectedTeam.coaches,
        ...selectedTeam.attendees
      ].map((attendee, index) => {
        const key = `ActivityInfoSidePanel__attendeesNavList__${attendee._id}__${index}`
        return {
          content: (
          <div key={key} className={classes.attendee}>
            <div className={classes.icon}>
              {
                attendee.role === 'player'
                  ? <Person/>
                  : <Coach/>
              }
            </div>
            <div className={classes.name}>
              {attendee.name}
            </div>
          </div>
          ),
          to: () => {
            setNextSidePanel({
              onClose: () => setNextSidePanel(null),
              component: UserInfoSidePanel,
              userId: attendee._id
            })
          }
        }
      })
    : []

  const canDelete = Boolean(
    isAdmin &&
    activity &&
    activity.activityType === 'younited' &&
    !activity.attendingTeams.some(({ attendees }) => attendees.length)
  )

  const renderBody = () => {
    switch (true) {
      case isAdmin: {
        return (
          <>
            <div className={classes.section}>
              <div className={classes.buttonContainer}>
                <Button theme='primary' onClick={() => setShowUpdateActivityTeamsModal(true)}>
                  {i18next.t('actions:manage_invitations')}
                </Button>
              </div>
              <div className={classes.infoContainer}>
                <div className={classes.info}>
                  <div className={classes.text}>Uitgenodigde ploegen</div>
                  <div className={classes.value}>{attendingTeamsCount}</div>
                </div>
                <div className={classes.info}>
                  <div className={classes.text}>Deelnemers</div>
                  <div className={classes.value}>{attendeesCount}</div>
                </div>
              </div>
            </div>
            <div className={classes.navListContainer}>
              <NavList items={teamsNavList}/>
            </div>
            {
              Boolean(!isFutureActivity) && (
                <div className={classes.section}>
                  <div className={classes.buttonContainer}>
                    <Button theme='primary' onClick={() => setShowEmailTeamsModal(true)} disabled={!canSendEmails}>
                      {i18next.t('actions:send_emails')}
                    </Button>
                  </div>
                </div>
              )
            }
          </>
        )
      }
      case isClubAdmin:
      case isCoach: {
        return (
          <>
            <div className={classes.section}>
              <div className={classes.infoContainer}>
                <div className={classes.info}>
                  <div className={classes.text}>{i18next.t('common:your_invited_teams')}</div>
                  <div className={classes.value}>{attendingTeamsCount}</div>
                </div>
                <div className={classes.info}>
                  <div className={classes.text}>{i18next.t('common:your_attendees')}</div>
                  <div className={classes.value}>{attendeesCount}</div>
                </div>
              </div>
            </div>
            {
              Boolean(attendingTeamsCount > 1) && (
                <Tabs tabs={attendingTeamsTabs}/>
              )
            }
            <div className={classes.navListContainer}>
              <NavList items={attendeesNavList}/>
            </div>
            {
              Boolean(activity.comment) && (
                <div className={classes.section}>
                  <div className={classes.commentContainer}>
                    <Talk className={classes.icon}/>
                    <div className={classes.comment}>
                      {activity.comment}
                    </div>
                  </div>
                </div>
              )
            }
          </>
        )
      }
      default: return null
    }
  }

  const renderActivityTypeIcon = () => {
    if (!activity) {
      return null
    }
    const Icon = activityTypeIcons[activity.activityType]
    return Icon ? <Icon width={20} height={24} fill='white'/> : null
  }

  return (
    <>
      {
        Boolean(showUpdateActivityTeamsModal) && (
          <Popup
            title={i18next.t('actions:manage_invitations')}
            onClose={() => setShowUpdateActivityTeamsModal(false)}
            body={
              <UpdateActivityTeamsForm
                activityId={activityId}
                callback={getActivity}
                onCancel={() => setShowUpdateActivityTeamsModal(false)}
              />
            }
          />
        )
      }
      {
        Boolean(showEmailTeamsModal) && (
          <Popup
            title={i18next.t('actions:send_emails')}
            onClose={() => setShowEmailTeamsModal(false)}
            body={
              <EmailTeamsForm
                activityId={activityId}
                callback={getActivity}
                onCancel={() => setShowEmailTeamsModal(false)}
              />
            }
          />
        )
      }
      {
        Boolean(showDeleteActivityModal) && (
          <Popup
            title={i18next.t('actions:delete_activity')}
            onClose={() => setShowDeleteActivityModal(false)}
            body={
              <DeleteActivityForm
                activityId={activityId}
                callback={deleteActivityCallback}
                onCancel={() => setShowDeleteActivityModal(false)}
              />
            }
          />
        )
      }
      <div className={['header', classes.header].join(' ')} style={activity && { backgroundColor: activityTypeColors[activity.activityType] }}>
        <div className='back'/>
        {
          Boolean(activity) && (
            <div className={classes.titleContainer}>
              <div>
                <div className='title'>
                  <div className={classes.activityTypeIcon}>
                    {renderActivityTypeIcon()}
                  </div>
                  <div>{activityTypeName}</div>
                </div>
                <div className='subtitle'>
                  {[description, dateFormated].filter((value) => Boolean(value && value.length)).join(' - ')}
                </div>
              </div>
              <div>
                {
                  canDelete
                    ? (
                      <IconButton
                        icon={Trash}
                        theme='role'
                        onClick={() => setShowDeleteActivityModal(true)}
                      />
                      )
                    : (
                      <div className={classes.progress}>
                        <div className={classes.percentage}>{teamsWithAttendeesPercentage}%</div>
                        <div className={classes.text}>INGEVULD</div>
                      </div>
                      )
                }
              </div>
            </div>
          )
        }
      </div>
      {
        Boolean(activity) && (
          <div className='body'>
            <div className={classes.body}>
              {renderBody()}
            </div>
          </div>
        )
      }
    </>
  )
}

ActivityInfoSidePanel.propTypes = {
  activityId: PropTypes.string.isRequired,
  filterActivitiesCallback: PropTypes.func.isRequired,
  setSelectedActivityId: PropTypes.func.isRequired
}

export default ActivityInfoSidePanel
