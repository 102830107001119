const getActivityProgress = (teamsArray) => {
  return parseInt((teamsArray.reduce((teamsWithAttendeesCount, { attendees = [] }) => {
    if (attendees.length) {
      return teamsWithAttendeesCount + 1
    }
    return teamsWithAttendeesCount
  }, 0) / (teamsArray.length || 1)) * 100, 10)
}

export default getActivityProgress
