const parseResponse = async (response) => {
  const contentType = response.headers.get('content-type')
  if (contentType === null) {
    return null
  }
  if (contentType.startsWith('application/json;')) {
    return await response.json()
  }
  if (contentType.startsWith('text/plain;')) {
    return await response.text()
  }
  throw new Error(`Unsupported response content-type: ${contentType}`)
}

const parseErrorResponse = async (response) => {
  const contentType = response.headers.get('content-type') || ''
  if (contentType.startsWith('application/json;')) {
    return await response.json()
  }
  if (contentType.startsWith('text/plain;')) {
    return await response.text()
  }
  return response.statusText
}

async function handleFetch (url, options) {
  const response = await fetch(url, options)
  if (response.ok) {
    return parseResponse(response)
  } else {
    let parsedErrorResponse
    try {
      parsedErrorResponse = await parseErrorResponse(response)
    } catch (error) {
      throw response
    }
    if (parsedErrorResponse) {
      throw parsedErrorResponse
    }
    throw response
  }
}

export default handleFetch
