export const addMessage = (message, options = {}) => {
  return {
    type: 'ADD_MESSAGE',
    message,
    options
  }
}

export const deleteMessage = (uuid) => {
  return {
    type: 'DELETE_MESSAGE',
    uuid
  }
}
