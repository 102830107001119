export const setDefault = () => ({
  type: 'SET_DEFAULT'
})

export const setAuthentication = (authentication) => ({
  type: 'SET_AUTHENTICATION',
  authentication
})

export const setMe = (me) => ({
  type: 'SET_ME',
  me
})

export const setHeaderHeight = (headerHeight) => ({
  type: 'SET_HEADER_HEIGHT',
  headerHeight
})
