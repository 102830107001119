import React from 'react'

const svg = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18.998" height="19" viewBox="0 0 18.998 19" {...props}>
      <path className="a"
            d="M19,16.851l-5.06-5.059a7.6,7.6,0,1,0-2.149,2.149L16.853,19ZM2.157,7.6A5.447,5.447,0,1,1,7.6,13.048,5.446,5.446,0,0,1,2.157,7.6Z"
            transform="translate(-0.004 0)"/>
    </svg>
  )
}

export default svg
