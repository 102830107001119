import React from 'react'

const svg = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="4.408" height="23.978" viewBox="0 0 4.408 23.978" {...props}>
      <g className="a" transform="translate(-46.875 -33)">
        <path className="b" d="M49.079,65.158a2.2,2.2,0,1,0-1.559-.645,2.2,2.2,0,0,0,1.559.645Z"
              transform="translate(0 -8.18)"/>
        <path className="b" d="M46.875,33h4.408V48.427H46.875Z"/>
      </g>
    </svg>
  )
}

export default svg
