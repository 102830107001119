import React from 'react'
import {
  makeWidthFlexible,
  XYPlot as XYPlotDefault,
  VerticalBarSeries,
  XAxis,
  YAxis
} from 'react-vis'
import classes from '../StatisticsPage.module.scss'

const XYPlot = makeWidthFlexible(XYPlotDefault)

const AgeGroups = (props) => {
  const { data } = props

  return (
    <div className={classes.chartContainer}>
      <XYPlot xType="ordinal" height={300} xDistance={100} className={classes.chart}>
        <XAxis/>
        <YAxis tickFormat={(float) => {
          const integer = parseInt(float, 10)
          return (integer === float) ? integer : null
        }}/>
        <VerticalBarSeries
          data={data}
          barWidth={0.9}
        />
      </XYPlot>
    </div>
  )
}

export default AgeGroups
