import React from 'react'
import classes from './Toggle.module.scss'
import PropTypes from 'prop-types'
import spoofedEvent from '../../utils/spoofedEvent'

const Toggle = (props) => {
  const {
    value,
    options,
    onChange
  } = props

  const handleChange = (value) => {
    const target = { value }
    const event = spoofedEvent(target)
    onChange(event)
  }

  return (
    <div className={classes.root}>
      {
        options.map(({ id, icon: Icon }, index) => {
          const key = `Toggle__options__${id}__${index}`
          const isSelected = id === value
          const classNames = [classes.option]
          if (isSelected) {
            classNames.push(classes.selected)
          }
          return (
            <Icon
              key={key}
              className={classNames.join(' ')}
              onClick={() => handleChange(id)}
            />
          )
        })
      }
    </div>
  )
}

Toggle.propTypes = {
  value: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      icon: PropTypes.func.isRequired
    }).isRequired
  ).isRequired,
  onChange: PropTypes.func.isRequired
}

export default Toggle
