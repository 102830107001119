const getQueryParams = () => {
  const query = new URLSearchParams(window.location.search)
  const params = {}
  for (const [key, value] of query) {
    try {
      params[key] = JSON.parse(value)
    } catch (error) {
      params[key] = value
    }
  }
  return params
}

export default getQueryParams
