const getUniqueAttendees = (attendees) => {
  return attendees.reduce((uniqueAttendees, attendee) => {
    const isAccountedFor = uniqueAttendees.some(({ _id }) => attendee._id === _id)
    if (!isAccountedFor) {
      uniqueAttendees.push(attendee)
    }
    return uniqueAttendees
  }, [])
}

export default getUniqueAttendees
