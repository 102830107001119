import React from 'react'

const svg = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="15.274" height="16" viewBox="0 0 15.274 16" {...props}>
      <path className="a"
            d="M4.438,10.129l2.449.56,1.12-1.516h0a.152.152,0,0,1,.269.056,2.879,2.879,0,0,0,.542,1.115l.374.475a.279.279,0,0,1-.023.37l-1.2,1.2a.95.95,0,0,1-.937.307l-3.058-.7a.962.962,0,0,1-.7-1.242.988.988,0,0,1,1.162-.624ZM15.8,1.042a1.778,1.778,0,1,0,1.1.787,1.777,1.777,0,0,0-1.1-.787Zm2.41,8.035a.789.789,0,0,0,.291-.554.816.816,0,0,0-.725-.849l-2.387-.312-.661-1.633a2.058,2.058,0,0,0-.743-.924A16.341,16.341,0,0,0,9.728,2.719l-.242-.078a.782.782,0,0,0-.793.187L6.639,4.856a.816.816,0,0,0-.067,1.105.791.791,0,0,0,1.153.044L9.451,4.3l1.411.737L9.417,7.244a2.238,2.238,0,0,0,.173,2.53l2.079,2.65-1.461,3.169a.989.989,0,0,0,.228,1.17.972.972,0,0,0,.425.208.935.935,0,0,0,.111.018.966.966,0,0,0,.98-.555l1.706-3.741a.946.946,0,0,0-.1-.98L12.237,9.236,13.457,7.1l.615,1.26h0a.788.788,0,0,0,.564.49l.075.013,2.893.376a.77.77,0,0,0,.6-.163Zm-2.269,4.408a1.778,1.778,0,1,0,1.108.788,1.778,1.778,0,0,0-1.108-.788Z"
            transform="translate(-3.226 -0.996)"/>
    </svg>
  )
}

export default svg
