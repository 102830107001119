import React from 'react'
import {
  DiscreteColorLegend,
  makeWidthFlexible,
  RadialChart as RadialChartDefault
} from 'react-vis'
import classes from '../StatisticsPage.module.scss'

const RadialChart = makeWidthFlexible(RadialChartDefault)

const Genders = (props) => {
  const { data } = props

  return (
    <div className={classes.chartContainer}>
      <DiscreteColorLegend
        className={classes.legend}
        items={data.map(({ label, color }) => ({ color, title: label }))}
      />
      <RadialChart
        className={classes.chart}
        data={data}
        height={300}
        width={250}
        colorType='literal'
      />
    </div>
  )
}

export default Genders
