import React, { useState } from 'react'
import PropTypes from 'prop-types'
import api from '../../api'
import Surface from '../Surface/Surface'
import SurfaceBody from '../SurfaceBody/SurfaceBody'
import Label from '../Label/Label'
import Input from '../Input/Input'
import DatePicker from '../DatePicker/DatePicker'
import PopupButtons from '../PopupButtons/PopupButtons'
import Button from '../Button/Button'
import neutralizeEvent from '../../utils/neutralizeEvent'
import i18next from '../../translations'

const CreateActivityForm = (props) => {
  const {
    onCancel,
    callback
  } = props

  const [date, setDate] = useState(new Date())
  const [description, setDescription] = useState('')

  const handleSubmit = (event) => {
    neutralizeEvent(event)
    const activity = {
      activityType: 'younited',
      date,
      description
    }
    api.activity.createActivity(activity)
      .then(callback)
  }

  return (
    <form onSubmit={handleSubmit}>
      <Surface>
        <SurfaceBody>
          <Label value={i18next.t('common:start_date')}/>
          <DatePicker
            value={date}
            onChange={({ target }) => setDate(target.value)}
            required
          />
          <Label value={i18next.t('common:description')}>
            <Input
              value={description}
              onChange={({ target }) => setDescription(target.value)}
              width='100%'
              maxLength={50}
              required
            />
          </Label>
        </SurfaceBody>
        <PopupButtons>
          <Button onClick={onCancel}>{i18next.t('actions:cancel')}</Button>
          <Button type='submit' theme='primary'>{i18next.t('actions:save')}</Button>
        </PopupButtons>
      </Surface>
    </form>
  )
}

CreateActivityForm.propTypes = {
  onCancel: PropTypes.func.isRequired,
  callback: PropTypes.func.isRequired
}

export default CreateActivityForm
