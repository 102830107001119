import React from 'react'

const svg = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15.483" viewBox="0 0 16 15.483" {...props}>
      <path className="a"
            d="M12.062,1.613a.662.662,0,1,0,0,1.325h1.677L12.39,4.287a4.732,4.732,0,0,0-5.252-.292,4.729,4.729,0,1,0-3.072,8.752v1.041H2.746a.662.662,0,0,0,0,1.325H4.066v1.321a.662.662,0,0,0,1.325,0V15.113H6.711a.662.662,0,0,0,0-1.325H5.391V12.747a4.681,4.681,0,0,0,1.747-.612,4.73,4.73,0,0,0,6.188-6.913l1.349-1.349V5.55A.662.662,0,0,0,16,5.55V2.274a.663.663,0,0,0-.664-.661ZM4.73,4.662a3.393,3.393,0,0,1,1.292.254,4.726,4.726,0,0,0,0,6.3A3.4,3.4,0,1,1,4.73,4.662Zm4.818,0a3.4,3.4,0,1,1-1.293,6.554,4.728,4.728,0,0,0,0-6.3,3.4,3.4,0,0,1,1.294-.254Zm-2.41,1a3.4,3.4,0,0,1,0,4.808,3.4,3.4,0,0,1,0-4.808Z"
            transform="translate(0 -1.613)"/>
    </svg>
  )
}

export default svg
