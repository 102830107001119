import React from 'react'
import PropTypes from 'prop-types'
import api from '../../api'
import Surface from '../Surface/Surface'
import SurfaceBody from '../SurfaceBody/SurfaceBody'
import PopupButtons from '../PopupButtons/PopupButtons'
import Button from '../Button/Button'
import neutralizeEvent from '../../utils/neutralizeEvent'
import i18next from '../../translations'

const DeleteActivityForm = (props) => {
  const {
    activityId,
    onCancel,
    callback
  } = props

  const handleSubmit = (event) => {
    neutralizeEvent(event)
    api.activity.deleteActivity(activityId)
      .then(callback)
  }

  return (
    <form onSubmit={handleSubmit}>
      <Surface>
        <SurfaceBody>
          {i18next.t('messages:delete_activity_confirm')}
        </SurfaceBody>
        <PopupButtons>
          <Button onClick={onCancel}>{i18next.t('actions:cancel')}</Button>
          <Button type='submit' theme='primary'>{i18next.t('actions:delete_activity')}</Button>
        </PopupButtons>
      </Surface>
    </form>
  )
}

DeleteActivityForm.propTypes = {
  activityId: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  callback: PropTypes.func.isRequired
}

export default DeleteActivityForm
