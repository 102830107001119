import React, { useEffect, useState } from 'react'
import i18next from '../../translations'
import Input from '../Input/Input'
import api from '../../api'
import Label from '../Label/Label'
import Surface from '../Surface/Surface'
import SurfaceBody from '../SurfaceBody/SurfaceBody'
import PopupButtons from '../PopupButtons/PopupButtons'
import Button from '../Button/Button'
import neutralizeEvent from '../../utils/neutralizeEvent'
import addMessage from '../../utils/addMessage'
import SearchSelect from '../SearchSelect/SearchSelect'
import withError from '../../HOC/withError'

const CreateClubAdminForm = (props) => {
  const {
    clubId,
    clubName,
    onCancel,
    callback,
    setError
  } = props

  const [createNew, setCreateNew] = useState(true)
  const [name, setName] = useState('')
  const [birthYear, setBirthYear] = useState('')
  const [email, setEmail] = useState('')
  const [users, setUsers] = useState([])
  const [userId, setUserId] = useState(null)

  useEffect(() => {
    if (createNew) {
      setUsers([])
      setUserId(null)
    } else {
      const filter = {
        club: clubId,
        role: 'coach'
      }
      api.user.getUsers(filter)
        .then(setUsers)
    }
  }, [clubId, createNew])

  const handleSubmit = (event) => {
    neutralizeEvent(event)
    if (createNew) {
      const user = {
        name,
        birthYear,
        email,
        role: 'clubadmin',
        club: clubId
      }
      api.user.createUser(user)
        .then(() => {
          addMessage(i18next.t('messages:name_added_as_club_admin_to_club', {
            name,
            club: clubName
          }), { type: 'success' })
          callback()
        })
        .catch(({ message }) => {
          setError(i18next.t(`errors:${message}`))
        })
    } else {
      api.user.upgradeCoachToClubAdmin(userId)
        .then(() => {
          const user = users.find(({ _id }) => _id === userId)
          addMessage(i18next.t('messages:name_added_as_club_admin_to_club', {
            name: user.name,
            club: clubName
          }), { type: 'success' })
          callback()
        })
    }
  }

  return createNew
    ? (
      <div>
        <form onSubmit={handleSubmit}>
          <Surface>
            <SurfaceBody>
              <Label value={i18next.t('common:name')}>
                <Input
                  value={name}
                  onChange={({ target }) => setName(target.value)}
                  width='100%'
                  required
                />
              </Label>
              <Label value={i18next.t('common:birthYear')}>
                <Input
                  type='number'
                  value={birthYear}
                  onChange={({ target }) => setBirthYear(target.value)}
                  width='100%'
                  min={1900}
                  required
                />
              </Label>
              <Label value={i18next.t('common:email')}>
                <Input
                  type='email'
                  value={email}
                  onChange={({ target }) => setEmail(target.value)}
                  width='100%'
                  required
                />
              </Label>
              <PopupButtons>
                <Button onClick={onCancel}>{i18next.t('actions:cancel')}</Button>
                <Button type='submit' theme='primary'>{i18next.t('actions:invite_and_add')}</Button>
              </PopupButtons>
            </SurfaceBody>
            <SurfaceBody>
              <PopupButtons padded={false} align='center'>
                <Button onClick={() => setCreateNew(false)}>
                  {i18next.t('actions:upgrade_coach_to_club_admin')}
                </Button>
              </PopupButtons>
            </SurfaceBody>
          </Surface>
        </form>
      </div>
      )
    : (
      <div>
        <form onSubmit={handleSubmit}>
          <Surface>
            <SurfaceBody>
              <Label value={i18next.t('actions:search')}>
                <SearchSelect
                  value={userId}
                  options={users.map(({ _id, name }) => ({ id: _id, value: name }))}
                  onChange={({ target }) => setUserId(target.value)}
                />
              </Label>
              <PopupButtons padded={false}>
                <Button type='submit' theme='primary'>{i18next.t('actions:upgrade_coach_to_club_admin')}</Button>
              </PopupButtons>
            </SurfaceBody>
            <SurfaceBody>
              <PopupButtons padded={false} align='center'>
                <Button onClick={() => setCreateNew(true)}>
                  {i18next.t('actions:invite_new_club_admin')}
                </Button>
              </PopupButtons>
            </SurfaceBody>
          </Surface>
        </form>
      </div>
      )
}

export default withError(CreateClubAdminForm)
