import store from '../redux/store'
const getAuthorization = () => {
  const {
    token
  } = store.getState().appState.authentication

  return {
    Authorization: `Bearer ${token}`
  }
}

export default getAuthorization
