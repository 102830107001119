import activityTypeColors from '../../../definitions/activityTypeColors.json'
import i18next from '../../../translations'
import getMonthOptions from '../../../utils/getMonthOptions'

const monthOptions = getMonthOptions({ long: false })

const getActivityTypesStatistics = (activityTypes) => {
  return activityTypes.reduce((parsedData, activityType) => {
    const {
      _id,
      activities,
      attendees
    } = activityType

    // allAttendees array
    parsedData.allAttendees.push(...attendees)

    // activitiesPerMonthPerType array
    parsedData.activitiesPerMonthPerType.push({
      type: _id,
      color: activityTypeColors[_id],
      data: monthOptions.map((monthOption) => {
        const activitiesCount = (
          activities.find(({ month }) => String(month) === monthOption.id) || {}
        ).count || 0
        return {
          x: monthOption.value,
          y: activitiesCount
        }
      })
    })

    // activitiesPerType array
    const angle = activities.reduce((total, { count }) => total + count, 0)
    const color = activityTypeColors[_id]
    const activityName = i18next.t(`activityTypes:${_id}`)
    const label = `${activityName} (${angle})`
    parsedData.activitiesPerType.push({
      angle,
      color,
      label,
      meta: {
        name: activityName,
        value: angle,
        id: _id
      }
    })

    return parsedData
  }, {
    allAttendees: [],
    activitiesPerMonthPerType: [],
    activitiesPerType: []
  })
}

export default getActivityTypesStatistics
