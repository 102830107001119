import React, { useState } from 'react'
import classes from './LoginPage.module.scss'
import { connect } from 'react-redux'
import api from '../../api'
import Input from '../../components/Input/Input'
import Button from '../../components/Button/Button'
import { setAuthentication } from '../../redux/appState/actions'
import { Helmet } from 'react-helmet'
import neutralizeEvent from '../../utils/neutralizeEvent'
import i18next from '../../translations'
import withError from '../../HOC/withError'
import Surface from '../../components/Surface/Surface'
import SurfaceBody from '../../components/SurfaceBody/SurfaceBody'
import Label from '../../components/Label/Label'
import Eye from '../../components/svg/Eye'
import { NavLink } from 'react-router-dom'

const LoginPage = (props) => {
  const {
    setError
  } = props

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)

  const handleSubmit = (event) => {
    neutralizeEvent(event)
    api.auth.login(email, password)
      .then((authentication) => {
        props.setAuthentication(authentication)
      })
      .catch(({ message }) => {
        setError(i18next.t(`errors:${message}`))
      })
  }

  const getPasswordResetLink = () => {
    const baseLink = '/password-reset'
    if (!email) {
      return baseLink
    }
    const query = new URLSearchParams({ email })
    return `${baseLink}?${query}`
  }

  return (
    <div className={classes.root}>
      <Helmet>
        <title>{i18next.t('common:younited')}: {i18next.t('actions:login')}</title>
      </Helmet>
      <form onSubmit={handleSubmit}>
        <Surface>
          <SurfaceBody>
            <div className={classes.title}>{i18next.t('actions:login')}</div>
          </SurfaceBody>
          <SurfaceBody>
            <Label value={i18next.t('common:email')}>
              <Input
                type='email'
                value={email}
                onChange={({ target }) => setEmail(target.value)}
                width='100%'
                required
              />
            </Label>
            <Label value={i18next.t('common:password')}>
              <Input
                type={showPassword ? 'text' : 'password'}
                value={password}
                onChange={({ target }) => setPassword(target.value)}
                button={{
                  onClick: () => setShowPassword(!showPassword),
                  icon: Eye
                }}
                width='100%'
                required
              />
            </Label>
          </SurfaceBody>
          <SurfaceBody className={classes.buttonsContainer}>
            <Button>
              <NavLink to={getPasswordResetLink()}>
                  {i18next.t('common:forgot_password')}
              </NavLink>
            </Button>
            <Button theme='primary' type='submit'>
              {i18next.t('actions:login')}
            </Button>
          </SurfaceBody>
        </Surface>
      </form>

      <div className={classes.logoContainer}>
        <img src="/assets/younited-analytics-logo.svg" alt=""/>
      </div>
    </div>
  )
}

const mapStateToProps = ({ appState }) => ({
  authentication: appState.authentication
})

const mapDispatchToProps = (dispatch) => ({
  setAuthentication: (authentication) => dispatch(setAuthentication(authentication))
})

export default withError(connect(mapStateToProps, mapDispatchToProps)(LoginPage))
