import { setMonth, startOfMonth, isBefore, getYear } from 'date-fns'

const getCurrentSeason = () => {
  const now = new Date()
  const seasonBreak = startOfMonth(setMonth(now, 8))
  return isBefore(now, seasonBreak)
    ? getYear(now) - 1
    : getYear(now)
}

export default getCurrentSeason
