import apiURL from './apiURL'
import handleFetch from './handleFetch.js'
import getAuthorization from './getAuthorization'

export const getActivityTypes = async () => {
  const url = apiURL('/activity/types')
  const options = {
    method: 'GET',
    headers: {
      ...getAuthorization()
    }
  }
  return handleFetch(url, options)
}

export const getActivities = async (filter = {}) => {
  const query = String(new URLSearchParams(filter))
  const url = apiURL(`/activity?${query}`)
  const options = {
    method: 'GET',
    headers: {
      ...getAuthorization()
    }
  }
  return handleFetch(url, options)
}

export const getSeasonRange = async () => {
  const url = apiURL('/activity/seasonRange')
  const options = {
    method: 'GET',
    headers: {
      ...getAuthorization()
    }
  }
  return handleFetch(url, options)
}

export const getSeasonAttendees = async (teamId, season) => {
  const url = apiURL(`/activity/team/${teamId}/season/${season}/attendees`)
  const options = {
    method: 'GET',
    headers: {
      ...getAuthorization()
    }
  }
  return handleFetch(url, options)
}

export const getActivity = async (activityId) => {
  const url = apiURL(`/activity/${activityId}`)
  const options = {
    method: 'GET',
    headers: {
      ...getAuthorization()
    }
  }
  return handleFetch(url, options)
}

export const createActivity = async (activity) => {
  const url = apiURL('/activity')
  const options = {
    method: 'POST',
    headers: {
      ...getAuthorization(),
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(activity)
  }
  return handleFetch(url, options)
}

export const deleteActivity = async (activityId) => {
  const url = apiURL(`/activity/${activityId}`)
  const options = {
    method: 'DELETE',
    headers: {
      ...getAuthorization()
    }
  }
  return handleFetch(url, options)
}

export const updateTeams = async (activityId, teamIds) => {
  const url = apiURL(`/activity/${activityId}/teams`)
  const options = {
    method: 'PUT',
    headers: {
      ...getAuthorization(),
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(teamIds)
  }
  return handleFetch(url, options)
}

export const sendReminders = async (activityId, teamIds) => {
  const url = apiURL(`/activity/${activityId}/sendReminders`)
  const options = {
    method: 'POST',
    headers: {
      ...getAuthorization(),
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(teamIds)
  }
  return handleFetch(url, options)
}
