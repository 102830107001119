import React from 'react'
import classes from './Surface.module.scss'

const Surface = (props) => {
  const {
    children,
    className = '',
    ...restProps
  } = props

  const childrenArray = Array.isArray(children)
    ? children
    : [children]

  const rootClassNames = [classes.root, className]

  return (
    <div className={rootClassNames.join(' ')} {...restProps}>
      {
        childrenArray.map((child, index) => {
          return (
            <div key={`Surface__children__${index}`} className={classes.row}>
              {child}
            </div>
          )
        })
      }
    </div>
  )
}

export default Surface
