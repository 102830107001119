import React from 'react'
import classes from './IconButton.module.scss'
import PropTypes from 'prop-types'

const IconButton = (props) => {
  const {
    icon: Icon,
    theme = 'default',
    onClick,
    ...restProps
  } = props

  const rootClassNames = [classes.root, classes[`theme-${theme}`]]

  return (
    <div className={rootClassNames.join(' ')} onClick={onClick}>
      <Icon {...restProps}/>
    </div>
  )
}

IconButton.propTypes = {
  icon: PropTypes.func.isRequired,
  theme: PropTypes.oneOf(['default', 'role']),
  onClick: PropTypes.func.isRequired
}

export default IconButton
