import React from 'react'

const svg = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="13.16" height="16" viewBox="0 0 13.16 16" {...props}>
      <path className="a"
            d="M19.512,5a.537.537,0,0,0-.15.039L13.807,7.346a.535.535,0,0,0-.306.339,11.944,11.944,0,0,0,.239,7.639,9.5,9.5,0,0,0,5.644,5.639.529.529,0,0,0,.367,0A9.5,9.5,0,0,0,25.4,15.323a11.944,11.944,0,0,0,.239-7.639.535.535,0,0,0-.306-.339C19.691,5,19.6,4.987,19.512,5Z"
            transform="translate(-12.988 -4.995)"/>
    </svg>
  )
}

export default svg
