import React, { useRef, useState } from 'react'
import classes from './SelectMultiple.module.scss'
import PropTypes from 'prop-types'
import i18next from '../../translations'
import CheckBox from '../CheckBox/CheckBox'
import spoofedEvent from '../../utils/spoofedEvent'
import Button from '../Button/Button'
import useClickOutside from '../../customHooks/useClickOutside'
import useKeyDown from '../../customHooks/useKeyDown'

const SelectMultiple = (props) => {
  const {
    title,
    value = [],
    options,
    // search, // TODO
    onChange,
    clearText
  } = props

  const [expanded, setExpanded] = useState(false)
  const rootRef = useRef(null)

  useKeyDown('Escape', () => setExpanded(false))
  useClickOutside(rootRef, () => setExpanded(false))

  const toggleValue = ({ target }) => {
    const isChecked = value.some((value) => value === target.name)
    const newValue = isChecked
      ? value.filter((value) => value !== target.name)
      : [...value, target.name]
    {
      const target = {
        value: newValue
      }
      const event = spoofedEvent(target)
      onChange(event)
    }
  }

  const selectAll = () => {
    const newValue = options.map(({ id }) => id)
    const target = {
      value: newValue
    }
    const event = spoofedEvent(target)
    onChange(event)
  }

  const clearAll = () => {
    const newValue = []
    const target = {
      value: newValue
    }
    const event = spoofedEvent(target)
    onChange(event)
  }

  const hasValue = Boolean(value && value.length && value.every((value) => {
    return options.some((option) => option.id === value)
  }))

  const rootClassNames = [classes.root]
  if (expanded) {
    rootClassNames.push(classes.expanded)
  }
  if (hasValue) {
    rootClassNames.push(classes.hasValue)
  }

  const firstSelectedOption = hasValue && options.find(({ id }) => id === value[0])

  return (
    <div className={rootClassNames.join(' ')} ref={rootRef}>
      <div className={classes.value} onClick={() => setExpanded(!expanded)}>
        {
          hasValue
            ? (
                value.length === 1
                  ? <span><span>{title} - </span>{firstSelectedOption.displayValue || firstSelectedOption.value}</span>
                  : <span>{title} - {value.length}</span>
              )
            : <span>{title}</span>
        }
      </div>
      {
        Boolean(expanded) && (
          <div className={classes.popup}>
            <div className={classes.optionContainer}>
            {
              options.map((option, index) => {
                const isChecked = value.some((value) => value === option.id)
                return (
                  <label className={classes.option} key={`Select__options__${option.id}__${index}`}>
                    <CheckBox
                      name={option.id}
                      checked={isChecked}
                      onChange={toggleValue}
                    />
                    <div className={classes.text}>{option.displayValue || option.value}</div>
                  </label>
                )
              })
            }
            </div>
            <div className={classes.buttons}>
              <Button onClick={selectAll} style={{ paddingLeft: 0 }}>
                {i18next.t('actions:select_all')}
              </Button>
              <Button onClick={clearAll} style={{ paddingRight: 0 }}>
                {clearText || i18next.t('actions:clear')}
              </Button>
            </div>
          </div>
        )
      }
    </div>
  )
}

SelectMultiple.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    displayValue: PropTypes.any
  })).isRequired,
  search: PropTypes.bool,
  clearText: PropTypes.string
}

export default SelectMultiple
