import React from 'react'
import classes from './DatePicker.module.scss'
import PropTypes from 'prop-types'
import ReactDatePicker from 'react-datepicker'
import spoofedEvent from '../../utils/spoofedEvent'
import neutralizeEvent from '../../utils/neutralizeEvent'

const DatePicker = (props) => {
  const {
    value,
    onChange,
    ...restProps
  } = props

  const handleChange = (value) => {
    const event = spoofedEvent({ value })
    onChange(event)
  }

  return (
    <div className={classes.root} onClick={neutralizeEvent}>
      <ReactDatePicker
        selected={value}
        onChange={handleChange}
        fixedHeight
        inline
        {...restProps}
      />
    </div>
  )
}

DatePicker.propTypes = {
  onChange: PropTypes.func.isRequired
}

export default DatePicker
