import React, { useState } from 'react'
import i18next from '../translations'
import Popup from '../components/Popup/Popup'
import Button from '../components/Button/Button'

const withError = (Component) => (props) => {
  const [error, setError] = useState(null)

  const clearError = () => setError(null)

  return (
    <>
      {
        Boolean(error) && (
          <Popup
            onClose={clearError}
            title={i18next.t('common:error')}
            body={
              <div style={{ padding: '12px' }}>
                {error}
              </div>
            }
            buttons={[
              <Button onClick={clearError} theme='primary'>
                Sluiten
              </Button>
            ]}
          />
        )
      }
      <Component
        setError={setError}
        {...props}
      />
    </>
  )
}

export default withError
