import React, { useEffect, useRef, useState } from 'react'
import classes from './SidePanel.module.scss'
import PropTypes from 'prop-types'
import { v4 as uuidv4 } from 'uuid'
import { createPortal } from 'react-dom'
import Close from '../svg/Close'
import IconButton from '../IconButton/IconButton'
import useKeyDown from '../../customHooks/useKeyDown'
import Button from '../Button/Button'
import Back from '../svg/Back'
import useClickOutside from '../../customHooks/useClickOutside'

const SidePanel = (props) => {
  const {
    onClose,
    component: Component,
    isNext,
    ...restProps
  } = props

  const [initialized, setInitialized] = useState(false)
  const [nextSidePanel, setNextSidePanel] = useState(null)
  const hasNextSidePanel = Boolean(nextSidePanel)

  const rootRef = useRef(null)

  useEffect(() => {
    setInitialized(true)
  }, [])

  useKeyDown('Escape', onClose)
  useClickOutside(rootRef, onClose)

  const handleClose = () => {
    setInitialized(false)
    setTimeout(onClose, 300)
  }

  const rootClassNames = [classes.root]
  if (initialized) {
    rootClassNames.push(classes.initialized)
  }
  if (hasNextSidePanel) {
    rootClassNames.push(classes.hidden)
  }

  const ClosePortal = () => {
    const closeButtonContainer = rootRef.current && rootRef.current.querySelector('.header .back')
    return Boolean(closeButtonContainer) && (
      createPortal(
        isNext
          ? (
            <Button onClick={onClose} className={classes.backButton}>
              <Back/> BACK
            </Button>
            )
          : (
            <IconButton
              icon={Close}
              onClick={handleClose}
              theme='role'
              className={classes.closeButtonIcon}
            />
            ),
        closeButtonContainer
      )
    )
  }

  return createPortal(
    <div className={rootClassNames.join(' ')} ref={rootRef}>
      {
        Boolean(nextSidePanel) && (
          <SidePanel isNext {...nextSidePanel}/>
        )
      }
      <ClosePortal/>
      <div className={classes.container}>
        <Component
          setNextSidePanel={setNextSidePanel}
          {...restProps}
        />
      </div>
    </div>,
    document.body
  )
}

const SidePanelWrapper = (props) => {
  const [UUID, setUUID] = useState(uuidv4())

  useEffect(() => {
    setUUID(uuidv4())
  }, [props.onClose])

  return <SidePanel {...props} key={UUID}/>
}

SidePanelWrapper.propTypes = {
  component: PropTypes.any.isRequired,
  onClose: PropTypes.func.isRequired,
  isNext: PropTypes.bool
}

export default SidePanelWrapper
